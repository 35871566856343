<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="timeout"
  >
    <div class="text-h6">
      {{ text }}
      <v-btn
        fabs
        outlined
        small
        class="ml-4"
        @click="snackbar = false"
        style="float:right"
        icon
      >
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import {
  mapMutations,
  mapState
} from 'vuex'

export default {
  data: () => ({}),
  computed: {
    ...mapState('notification', ['color', 'text', 'timeout']),
    snackbar: {
      get () {
        return this.$store.state.notification.snackbar
      },
      set (val) {
        if (!val) {
          this.hideNotification()
        }
      }
    }
  },
  methods: {
    ...mapMutations('notification', ['hideNotification'])
  }
}
</script>
