<template>
    <div>
        <v-list style="overflow-y: scroll;height:100px;">
            <div
                v-for="(alertGroup, alertGroupIndex) in groupped"
                :key="alertGroupIndex"
            >
                <v-list-item-title>{{ alertGroup.caption }}</v-list-item-title>
                <div
                    v-for="(alert, alertIndex) in alertGroup.alerts"
                    :key="alertGroupIndex + alertIndex"
                >
                    <PatientAlertType
                        :alert-types="alertTypes"
                        :alert="alert"
                        :show-created-by="showCreatedBy"
                        :show-removed-by="showRemovedBy"
                        :clickable="clickable"
                        :removeable="removeable"
                    ></PatientAlertType>
                    <v-divider
                        v-if="alertIndex + 1 < alertGroup.length"
                        inset
                    ></v-divider>
                </div>
                <v-divider></v-divider>
            </div>
        </v-list>
    </div>
</template>
<script>
import { defineComponent,computed } from '@vue/composition-api'
import PatientAlertType from '@components/patient/PatientAlertType.vue'

export default defineComponent({
    name: 'PatientAlertsList',
    components:{
        PatientAlertType
    },
    props: {
        alerts: {
            type: Array,
            default: []
        },
        alertTypes: {
            type: Object,
            default: {}
        },
        showCreatedBy: {
            type: Boolean,
            default: true
        },
        showRemovedBy: {
            type: Boolean,
            default: true
        },
        clickable: {
            type: Boolean,
            default: false
        },
        removeable: {
            type: Boolean,
            default: true
        }
    },

    setup(props) {

        const processGroupped = () =>{
            const groupped = []
            props.alerts.map(alert => {
                const group = groupped.find(group => group.caption === alert.categoryName)
                if (!group) {
                    groupped.push({ caption: alert.categoryName, alerts: [alert] })
                } else {
                    group.alerts.push(alert)
                }
            })
            groupped.map(group => {
                group.alerts.sort((a, b) => {
                    return a.removedDate && !b.removedDate ? 1 : -1
                })
            })
            return groupped
        }
        const groupped = computed(()=>{
            return processGroupped()
        })

        return {
            groupped
        }



    },
})
</script>
