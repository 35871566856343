<template>
  <overlay
    v-model="model"
    :component-name="'AppointmentReportPreview'"
    :component-props="{fetchRoute: reportRoute, row: row}"
    :title="'Appointment Report'"
    :actions.sync="actionsComputed"
    />
</template>

<script>
import Vue from 'vue'
import AppointmentReportPreview from '@components/appointment/AppointmentReportPreview.vue'
import Overlay from '@components/common/Overlay.vue'
Vue.component('AppointmentReportPreview', AppointmentReportPreview)

export default {
  name: 'AppointmentReport',
  components: { Overlay },
  props: {
    value: Boolean,
    reportRoute: String,
    actions: [Array, String],
    row: Object
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    actionsComputed: {
      get () {
        return this.actions
      },
      set (value) {
        this.$emit('update:actions', value)
      }
    }
  }
}
</script>

<style scoped>

</style>