<template>
  <practitioner-layout v-if="render">
    <router-view></router-view>
    <v-container fluid>
      <grid
        fixedHeader
        :data="items"
        :loading="loading"
        @rowAdd="rowAdd"
        @refresh="getData"
        @rowClick="rowClicked"
        :headers-preset-type="headersPresetType"
        :headers-preset="headersPreset"
        filter-by-column-name="currentHospitalLocationPointOfCare"
        :filter-name="filterName"
      ></grid>
    </v-container>
  </practitioner-layout>

</template>

<script>
import Grid from '@components/common/Grid.vue'
import PractitionerLayout from '@router/layouts/practitioner.vue'
import episodesHeadersPreset from '@src/data/dataset/gridHeaders/patientEpisodes';

export default {
  name: 'PractitionerEpisodesGrid',
  components: { Grid, PractitionerLayout },
  data () {
    return {
      filterName:this.$_t('Patient location'),
      rightDrawerModel:false,
      rightDrawer : {
        visible: false,
        data:[],
        loading:true,
        patientId:null,
        patientEpisodeId:null,
        patientLocationId:null
      },
      patientAppointments:[],
      dialog: false,
      reportRoute: '',
      activeRow: '',
      items: [],
      searchResult: {},
      loading: false,
      headersPresetType:'episodes',
      render:true,
      headersPreset: episodesHeadersPreset,


    }
  },
  computed:{
    refreshed() {
      return this.$store.getters['app/getRefreshed']
    },

    currentUserId (){
        return this.$store.getters['app/currentUserId']
    }

  },
  methods: {
    getData () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/episodes`, {
        // filter: { patientId: {  x: '1234', type: 'eq'}}
      }, response => {
        this.loading = false
        this.items = this.mapDatasetResponse(response)
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },

    rowAdd () {},
    rowClicked (row) {
        window.localStorage.setItem(this.currentUserId+'_patientEpisodeId', row.patientEpisodeId);
        if(row.patientLocationId) {
          window.localStorage.setItem(this.currentUserId+'_patientLocationId', row.patientLocationId);
        }
        this.$router.push({ name: 'episode-details', params: { id: row.patientId } })
    }
  },
  watch:{
    refreshed(val){
       if(val) {
        this.render = false
        this.$nextTick(()=>{
          this.render = true
          this.$store.commit('app/setRefreshed',false)
        })
      }
    }

  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>

</style>
