<template>
  <v-dialog
    v-model="model"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-toolbar
        flat
        dark
        color="primary"
        style="position: relative"
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <div v-if="Array.isArray(actionsComputed)">
            <div
              v-for="(action, index) in actionsComputed"
              :key="index"
              class="pt-4"
            >
              <v-btn @click="actionClicked(action)" outlined>
                {{ $_t(action) }}
              </v-btn>
            </div>
          </div>
          <div v-else-if="actionsComputed === 'loading'" class="pt-4 pr-4">
            <v-progress-circular indeterminate  />
          </div>
          <v-btn
            icon
            dark
            @click="model = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>

      </v-toolbar>
      <component
        ref="contentComponent"
        :is="componentName"
        v-bind="componentProps"
        :actions.sync="actionsComputed"
      ></component>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    value: Boolean,
    componentName: String,
    componentProps: Object,
    title: String,
    actions: [Array, String]
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    actionsComputed: {
      get () {
        return this.actions
      },
      set (value) {
        this.$emit('update:actions', value)
      }
    }
  },
  methods: {
    actionClicked (action) {
      this.$refs.contentComponent[action]()
    }
  }
}
</script>

<style scoped>

</style>
