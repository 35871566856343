export default {
  en: {
    insertFile: 'Insert File',
    insertNewFile: 'Insert New File',
    add: 'Add',
    delete: 'Delete',
    edit: 'Edit',
    deleteDialog: {
      message: 'Are you sure you want to delete the file?',
      cancel: 'cancel'
    },
    table: {
      thumb: 'Thumb',
      name: 'Name',
      size: 'Size',
      tags: 'tags',
      action: {
        action: 'Action',
        deleteTooltip: 'Delete'
      }
    },
    size: {
      kb: 'KB',
      mb: 'MB'
    },
    maxFileSizeAlert: 'Max file Size is',
    maxFileCountAlert: 'Max file Count is',
    fileName: 'File Name',
    fileDescription: 'File Description',
    fileTags: 'File Tags'
  },
  fa: {
    insertFile: 'افزودن فایل',
    insertNewFile: 'افزودن فایل جدید',
    add: 'افزودن',
    delete: 'حذف',
    edit: 'ویرایش',
    deleteDialog: {
      message: 'آیا برای حذف فایل اطمینان دارید؟',
      cancel: 'لغو'
    },
    table: {
      thumb: 'پیش نمایش',
      name: 'نام',
      size: 'حجم',
      action: {
        action: 'عملیات',
        deleteTooltip: 'حذف'
      }
    },
    size: {
      kb: 'کیلو بایت',
      mb: 'مگابایت'
    },
    maxSizeAlert: 'حداکثر حجم فایل انتحابی ',
    maxFileCountAlert: 'حداکثر تعداد فایل انتخابی',
    fileName: 'نام فایل',
    fileDescription: 'توضیحات فایل',
    fileTags: 'برچسب فایل'
  },
  fr: {
    insertFile: 'Insérer un fichier',
    insertNewFile: 'Insérer un nouveau fichier',
    add: 'Ajouter',
    delete: 'Supprimer',
    edit: 'Éditer',
    deleteDialog: {
      message: 'Voulez-vous vraiment supprimer le fichier?',
      cancel: 'Annuler'
    },
    table: {
      thumb: 'la vignette',
      name: 'Nom',
      size: 'Taille',
      action: {
        action: 'Action',
        deleteTooltip: 'Supprimer'
      }
    },
    size: {
      kb: 'KB',
      mb: 'MB'
    },
    maxSizeAlert: 'La taille maximale du fichier est',
    maxFileCountAlert: 'Le nombre maximal de fichiers est',
    fileName: 'Nom de fichier',
    fileDescription: 'description du fichier',
    fileTags: 'Balises de fichier'
  },
  ch: {
    insertFile: '插入档案',
    insertNewFile: '插入新文件',
    add: '加',
    delete: '删除',
    edit: '编辑',
    deleteDialog: {
      message: '您确定要删除文件吗?',
      cancel: '取消'
    },
    table: {
      thumb: '缩图',
      name: '名称',
      size: '尺寸',
      action: {
        action: '行动',
        deleteTooltip: '删除'
      }
    },
    size: {
      kb: 'KB',
      mb: 'MB'
    },
    maxSizeAlert: '档案大小上限为',
    maxFileCountAlert: '现“最大文件数”为',
    fileName: '文档名称',
    fileDescription: '文件描述',
    fileTags: '文件标签'
  },
  ar: {
    insertFile: 'إدراج ملف',
    insertNewFile: 'إدراج ملف جديد',
    add: 'أضف',
    delete: 'حذف',
    edit: 'تعديل',
    deleteDialog: {
      message: 'هل أنت متأكد أنك تريد حذف الملف؟',
      cancel: 'إلغاء'
    },
    table: {
      thumb: 'ظفري',
      name: 'اسم',
      size: 'بحجم',
      action: {
        action: 'عمل',
        deleteTooltip: 'حذف'
      }
    },
    size: {
      kb: 'کیلو بایت',
      mb: 'ميغا بايت'
    },
    maxSizeAlert: 'الحجم الأقصى للملف هو',
    maxFileCountAlert: 'الحد الأقصى لعدد الملفات هو',
    fileName: 'اسم الملف',
    fileDescription: 'وصف الملف',
    fileTags: 'علامات الملف'
  }
};
