<template>
  <v-main>
    <v-app-bar app color="white">
      <v-spacer></v-spacer>
      <v-btn color="primary" class="" @click="logOut()">Logout</v-btn>
    </v-app-bar>
    <v-container class="ma-0 px-0" fluid>
      <slot />
    </v-container>
  </v-main>
</template>

<script>
import auth from '@src/helpers/auth';

export default {
  name: 'SimpleLayout',
  methods: {
    logOut() {
      auth.logout();
    }
  }
};
</script>

<style scoped>
</style>
