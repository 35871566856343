<template>
<div>
    <v-dialog
        v-model="dialog"
        transition="slide-x-reverse-transition"
        scrollable
        max-width="60%"
        content-class="my-custom-dialog"
        open-delay="500"
      >
        <v-card tile style="display: inline-table; height: 100%">
          <v-toolbar
            flat
            dark
            color="white"
          >
          <v-spacer></v-spacer>
            <v-btn
              outlined
              icon
              fab
              small
              dark
              @click="dialog = false"
              color="black"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div>
            <slot></slot>
          </div>
        </v-card>
      </v-dialog>
</div>

</template>
<script>
export default {
  name:'RouteDialog',

  data () {
    return {
      dialog:true
    }
  },
  watch:{
    dialog(val){
      if(!val){
        this.$router.back()
      }
    }
  },
  mounted(){
    this.$root.$on('close-dialog',()=>{
      this.dialog = false
    })
  }
}
</script>
<style>
.my-custom-dialog {
   position: absolute;
    top: 0;
    right: 0;
    margin:0 !important;
    height:100vh;

}
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
}
.v-toolbar {
  flex:none !important;
}
</style>
