<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="py-0 my-0"
      >
        <v-select
          v-model="schedulingType"
          :items="items"
          item-value="value"
          item-text="label"
        />
      </v-col>
    </v-row>
    <v-row v-if="schedulingType === 'CUSTOM'">
      <v-col
        cols="12"
        sm="6"
        xs="12"
        class="py-0 my-0"
      >
        <v-date-picker v-model="picker" @change="makeCustomDate"></v-date-picker>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        xs="12"
        class="py-0 my-0"
      >
        <v-btn
          v-for="i in 6"
          :key="i"
          class="ma-3"
          style="width: 100%"
          :value="btnWeeks"
          @click="setDate(i, 'weeks')"
        >

          {{ i }} {{translateButtons('Week',i)}}

          <v-icon right v-if="Number(btnWeeks) === i">
            mdi-check-bold
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        xs="12"
        class="py-0 my-0"
      >
        <v-btn
          v-for="i in [2,3,4,5,6,9,12]"
          :key="i"
          class="ma-3"
          style="width: 100%"
          :value="btnMonths"
          @click="setDate(i, 'months')"
        >
          {{ i }} {{translateButtons('Month',i)}}
          <v-icon right v-if="Number(btnMonths) === i">
            mdi-check-bold
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import moment from 'moment'
import useDateHelpers from '../../helpers/useDateHelpers';


export default defineComponent({
  name: 'Scheduling',
  props: {
    value: String
  },
  setup (props, { emit }) {
    const _vm = getCurrentInstance()
    const { useSqlDate } = useDateHelpers()

    const btnWeeks = ref(localStorage.getItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks')) || null
    const btnMonths = ref(localStorage.getItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths')) || null
    const items = ref([
      { value: 'ASAP', label: _vm.proxy.$_t('ASAP') },
      { value: 'CUSTOM', label: _vm.proxy.$_t('Custom date') }
    ])
    const picker = computed({
      get: () => {
        return props.value ? useSqlDate(props.value) : null
      },
      set (value) {
        emit('input', value)
      }
    })
    const schedulingType = ref()
    const menu = ref(false)

    const makeCustomDate = ()=>{
        btnWeeks.value = null
        btnMonths.value = null
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks', null)
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths', null)
    }
    const translateButtons = (item,count) => {
      let translate = item
      if(count >1) {
        translate = `${item}s`
      }
      return _vm.proxy.$_t(translate)
    }
    watch(menu, val => {
      val && setTimeout(() => (picker.value.activePicker = 'YEAR'))
    })

    const setDate = (amount, interval) => {
      const today = new moment()
      today.add(amount, interval)
      picker.value = useSqlDate(today)
      if(interval.includes('month')) {
        btnWeeks.value = null
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks', null)
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths', amount)
        btnMonths.value = amount
      }
      if(interval.includes('week')) {
        btnMonths.value = null
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths', null)
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks', amount)
        btnWeeks.value = amount
      }

    }
    if (!props.value || moment(props.value).isSame(moment(Date.now()), 'day')) {
      schedulingType.value = 'ASAP'
    } else {
      schedulingType.value = 'CUSTOM'
    }

    return {
      items,
      menu,
      picker,
      schedulingType,
      setDate,
      btnWeeks,
      btnMonths,
      translateButtons,
      makeCustomDate
    }
  }
})
</script>

<style scoped>

</style>
