<template>
  <v-container>
    <v-select
      v-model="model"
      :items="categories"
      item-value="id"
      item-text="name"
      :loading="loading"
    />
  </v-container>
</template>

<script>
export default {
  name: 'AppointmentCategoryDropdown',
  props: {
    value: {
      type: String
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      categories: [],
      loading: false
    }
  },
  mounted () {
    this.getCategories()
  },
  methods: {
    getCategories () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/appointment-categories`, undefined, response => {
        this.loading = false
        this.categories = response.data
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      }, {
        prefixRoutesWithOrganizationId: true
      })
    }
  }
}
</script>

<style scoped>

</style>
