<template>
    <v-row justify="center">
    <v-dialog
      :value="state.open"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $_t('Confirm') }}
        </v-card-title>
        <v-card-text>{{state.message}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="show(false)"
          >
            {{ $_t('Cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="action"
          >
           {{ $_t('Proceed') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { defineComponent,reactive } from '@vue/composition-api'

export default defineComponent({
    name:'ConfirmDialog',
    props:{
        confirmDialogOptions:Object,
        message:String
    },
    setup(props,{emit}) {

        const state = reactive(props.confirmDialogOptions)
        const show = (param)=>{
            emit('show',param)
        }

        const action = ()=>{
            emit('action')
            emit('show',false)
        }

        return {
            show,
            action,
            state
        }

    },
})
</script>
