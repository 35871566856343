<template>
  <practitioner-layout v-if="render">
    <router-view></router-view>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="filter.appointmentStatuses"
            :items="filterData.appointmentStatuses"
            prepend-icon="mdi-filter-menu"
            dense
            chips
            :label="$_t('Appointment statuses')"
            item-text="nameWeb"
            item-value="nameWeb"
            multiple
          >
            <template v-slot:append-outer>
              <v-btn @click="getData(true)" outlined color="primary" :disabled="loading">{{$_t('Filter')}}</v-btn>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <grid
        :headers-preset-type="headersPresetType"
        :headers-preset="headersPreset"
        :data="items"
        :loading="loading"
        @rowAdd="rowAdd"
        @refresh="getData"
        @rowClick="rowClicked"
        :disable-pagination="false"
        :hide-default-footer="loading"
        @grid-options-changed="gridOptionsChanged"
        :server-items-length="rowsNumber"
        @grid-search="gridSearch"
        :disable-sort="true"
        :hide-filters="true"
        :search-value.sync="filter.searchValue"
      ></grid>
    </v-container>
  </practitioner-layout>
</template>

<script>
import Grid from '@components/common/Grid.vue'
import PractitionerLayout from '@router/layouts/practitioner.vue'
import appointmentsPracticesHeaders from '@src/data/dataset/gridHeaders/appointmentPractices'

export default {
  name: 'AppointmentGrid',
  components: {
    Grid,
    PractitionerLayout
  },
  data () {
    return {
      items: [],
      loading: false,
      headersPresetType:'appointmentPractices',
      headersPreset:appointmentsPracticesHeaders,
      render:true,
      gridOptions: {},
      rowsNumber: 0,
      gridSearchPhrase: '',
      restRequest: null,
      filterData: {
        appointmentStatuses: []
      },
      filter: {
        appointmentStatuses: [],
        searchValue: ''
      }
    }
  },
  methods: {
    getAppointmentStatuses() {
      this.$_rest.get(`/practitioners/${this.$store.getters['app/currentUserId']}/appointment-statuses`, {
        isActive: 1
      }, response => {
        const filteredStatus = response.data.filter(status=> status.nameWeb)
        filteredStatus.forEach((status) => {
          if (!this.filterData.appointmentStatuses.includes(status.nameWeb)) {
            this.filterData.appointmentStatuses.push(status.nameWeb)
          }
        })
      }, error => {
        console.error(error)
        this.$_notify.error('Failed to load appointment statuses')
      }, {
        prefixRoutesWithOrganizationId: false
      });
    },
    getData (resetPage = false) {
      if (resetPage) {
        this.gridOptions.page = 1
      }
      // api currently does not support and + or filters, therefore either full text search or filter by appointment status works
      // clear search value when appointment statuses are greater than 0
      if (this.filter.appointmentStatuses.length > 0) {
        this.filter.searchValue = ''
      }
      this.items = []
      this.loading = true
      if (this.restRequest) {
        this.restRequest.abort();
        this.restRequest = null
      }
      this.restRequest = this.$_rest.get(`/practitioners/${this.$store.getters['app/currentUserId']}/datasets/appointments`, {
        filter: this.gridFilter,
        limit: this.gridOptions.itemsPerPage,
        page: this.gridOptions.page
      }, response => {
        this.rowsNumber = response.meta.rowsNumber
        this.items = this.mapDatasetResponse(response)
        this.$store.dispatch('appointment/setAppointmentsList', this.items).then(() => {
          this.loading = false
        })
      }, error => {
        if (error.name !== 'AbortError') {
          this.loading = false
          this.$_notify.error(error)
        }
      })
    },

    rowAdd () {
    },
    rowClicked (row) {
      this.$router.push({ name: 'appointment-details', params: { id: row.appointmentId } })
    },
    gridOptionsChanged(options) {
      this.gridOptions = options
      this.getData()
    },
    gridSearch(val) {
      this.gridSearchPhrase = val
      if (val) {
        this.filter.appointmentStatuses = []
      }
      this.getData(true)
    }
  },
  computed:{
    refreshed() {
      return this.$store.getters['app/getRefreshed']
    },
    gridFilter() {
      const filter = {
        practitionerPracticeId: this.$store.getters['app/practitionerPractice'].id,
      }

      const searchableColumns = [
        'searchPatientFullName',
        'searchClinicNameShort',
        'searchProcedureName',
        'searchInsurerName',
        'searchClinicScanRoomCode',
        'searchPatientNumber1',
      ];

      if (this.filter.appointmentStatuses.length > 0) {
        filter['appointmentStatusNameWeb'] = {
          type: 'in',
          x: this.filter.appointmentStatuses.join(',')
        }
      }

      if (this.gridSearchPhrase) {
        searchableColumns.forEach((col) => {
          filter[col] = {
            type: 'like',
            x: this.gridSearchPhrase,
            connector: 'OR'
          }
        })
      }

      return filter
    }
  },

  watch:{
    refreshed(val) {
       if(val) {
        this.render = false
        this.$nextTick(()=>{
          this.render = true
          this.$store.commit('app/setRefreshed',false)
        })
      }
    },
  },
  mounted () {
    this.getAppointmentStatuses()
  }
}
</script>
