



































































































































































import { computed, defineComponent, getCurrentInstance, ref, WritableComputedRef, PropType, reactive } from '@vue/composition-api'
import PatientAppointmentGrid from '@components/patient/PatientAppointmentGridComponent.vue'
import PractitionerPractice from '@components/practitioner/PractitionerPractice.vue'
import UserProfilePatient from '@components/userProfile/Patient.vue'
import UserProfilePractitioner from '@components/userProfile/Practitioner.vue'
import { RightDrawer } from 'src/interfaces/RightDrawer'
import { MenuItemsInterface } from 'src/interfaces/MenuItems'
import PatientBanner from '@components/common/PatientBanner.vue'
import { PatientBannerEnum } from '@src/enum/PatientBanner'
import { MenuItems } from '@src/data/MenuItems'
import useCommonHelpers from '../../helpers/useCommonHelpers';
import { PractitionerRoles } from '../../enum/PractitionerRoles'
import config from '@src/config'



export default defineComponent({
  name: 'PractitionerLayout',
  props: {
    rightDrawer: {
      type: Object as PropType<RightDrawer>
    }
  },
  components: {
    PatientAppointmentGrid, PractitionerPractice, UserProfilePatient, UserProfilePractitioner,PatientBanner
  },
  emits:['setExtendedToolbarHeight'],
  setup(props, {emit}) {
    const { isGrantedAny } = useCommonHelpers()

    const vm = getCurrentInstance() as any
    // console.log("🚀 ~ file: practitioner.vue ~ line 196 ~ setup ~ vm", vm)

   const menuItemsCopy: MenuItemsInterface[] = MenuItems
   const menuItems: MenuItemsInterface[] = []

    // const menuItems = computed(()=>{  //temporary switch of Greg
    //   return MenuItems.filter(menuItem => isGrantedAny([...menuItem.roles as any, PractitionerRoles.superAdmin]))
    // })

    config.practitionerMenuItems.forEach((item: string) => {
      const foundMenuItem = menuItemsCopy.find((menu: MenuItemsInterface)=> menu.id === item.trim())
      if(typeof foundMenuItem !== 'undefined'){
        menuItems.push(foundMenuItem)
      }
    })

    const extendedToolbarHeight = reactive({
        mobileHeight:PatientBannerEnum.defaultMobileHeight,
        desktopHeight:PatientBannerEnum.defaultDesktopHeight
    })

    const setExtendedToolbarHeight = (mobileHeight: number,desktopHeight: number) =>{
        extendedToolbarHeight.mobileHeight = mobileHeight
        extendedToolbarHeight.desktopHeight = desktopHeight
    }

    const bottomNavigationValue = ref(0)
    bottomNavigationValue.value = menuItemsCopy.findIndex(item => item.title === vm.proxy.$route.name)

    const drawerMini = ref(true)
    const appBarCollapse = ref(true)
    const getMobile = computed(() => {
      return vm?.root.proxy.isMobile
    })

    const transitioned = () => {}
    const drawer = ref(true)
    const bottomNav = ref()

    // const getMobile = comp(ctx.root.$vuetify.breakpoint.mobile)
    const temp = ref(false)
    const drawer2 = ref(false)
    const drawerRight = ref(false)
    // @ts-ignore

    const currentUser = computed(() => {
      return vm?.proxy.$store.getters['app/currentUser']
    })
    const extendedToolbarShow = computed(() => {
      return vm?.proxy.$store.getters['app/patientBanner']
    })
    const alertsBadge = computed(() => {
      return vm?.proxy.$store.getters['alerts/count']
    })
    const rightDrawerModel = computed({
      get() {
        return props.rightDrawer && props.rightDrawer.visible
      },
      set(value): void {
        emit('update:rightDrawer', props.rightDrawer, {visible: value})
      }
    });

    const currentPractitionerPractice = computed({
      get(): string {
        return vm?.proxy.$store.getters['app/practitionerPractice']
      },
      set(practice: string): void {
        vm?.proxy.$store.dispatch('app/setPractitionerPractice', practice).then(() => {
          window.location.reload()
        })
      }
    });

    const closeRightDrawer = (state: boolean) => {
      if (!state) {
        rightDrawerModel.value = state
        emit('close-right-driver', state)
      }
    }

    const setEvents = ()=>{
        vm?.proxy.$root.$on('extend-panel',(mobileHeight: number,desktopHeight: number)=>{
            setExtendedToolbarHeight(mobileHeight,desktopHeight)
        })
    }

    vm?.proxy.$store.dispatch('alerts/pollAlerts', 30000)
    setEvents()

    return {
      bottomNavigationValue,
      drawer,
      drawerMini,
      menuItems,
      bottomNav,
      transitioned,
      temp,
      drawer2,
      drawerRight,
      appBarCollapse,
      currentUser,
      alertsBadge,
      currentPractitionerPractice,
      rightDrawerModel,
      closeRightDrawer,
      extendedToolbarShow,
      extendedToolbarHeight,
      setExtendedToolbarHeight,
      getMobile
    }
  }
})
