<template>
  <v-container>
    <v-select
        v-model="model"
        :items="practitionerPractices"
        item-text="practice.name"
        item-value="id"
        :label="$_t('Practices')"
        :loading="loading"
        return-object
        dark
        dense
        outlined
    ></v-select>
  </v-container>
</template>

<script>

export default {
  name: 'PractitionerPractice',
  props: {
    practitionerPractices: {
      type: Array,
      required: true
    },
    value: {
      type: null,
      required: true
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      availablePractices: [],
    }
  },
  mounted() {

  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
