const state = {
  appointmentAccessCode: null,
  appointment: null,
  clinic: null,
  availability: null,
  availabilitySlot: null,
  slot: null,
  invalidSlots: []
};

const mutations = {
  setAppointmentAccessCode(state, appointmentAccessCode) {
    state.appointmentAccessCode = appointmentAccessCode;
  },
  setAppointment(state, appointment) {
    state.appointment = appointment;
  },
  setClinic(state, clinic) {
    state.clinic = clinic;
  },
  setAvailability(state, availability) {
    state.availability = availability;
  },
  setAvailabilitySlot(state, availabilitySlot) {
    state.availabilitySlot = availabilitySlot;
  },
  setSlot(state, slot) {
    state.slot = slot;
  },
  setInvalidSlots(state, invalidSlots) {
    state.invalidSlots = invalidSlots;
  }
};

const getters = {
  getAppointmentAccessCode(state) {
    return state.appointmentAccessCode;
  },
  getAppointment(state) {
    return state.appointment;
  },
  getClinic(state) {
    return state.clinic ? state.clinic : null;
  },
  getAvailability(state) {
    return state.availability;
  },
  getAvailabilitySlot(state) {
    return state.availabilitySlot;
  },
  getSlot(state) {
    return state.slot;
  },
  getInvalidSlots(state) {
    return state.invalidSlots;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
