<template>
  <v-flex class="mt-16">
    <v-col cols="12" offset-md="3" md="6" v-if="loading">
      <v-card class="elevation-4">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" offset-md="3" md="6" v-else-if="!shares">
      <v-card class="elevation-4">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text class="text-center">
          <v-alert type="error" v-if="error">{{ error }}</v-alert>
          <v-col v-if="!error && !OTPVerified">
            <v-form v-model="OTPFormValid" ref="OTPForm">
              <v-text-field
                append-icon="mdi-key"
                v-model="OTPCode"
                name="code"
                :label="$_t('OTP code')"
                type="text"
                outlined
                autocomplete="off"
                autofocus
                :rules="OTPRules"
              ></v-text-field>
              <v-btn
                type="submit"
                color="primary"
                style="width:100%"
                :disabled="!OTPFormValid || submitted"
                @click="submitOTP"
              >
                {{ $_t('Submit') }}
              </v-btn>
              <v-alert class="ma-2" type="error" v-if="OTPError">{{
                OTPError
              }}</v-alert>
            </v-form>
            <div v-if="OTPMethod === 'sms'">
              <br /><br />
              <h4>{{ $_t('Did not receive the OTP code?') }}</h4>
              <p v-if="timer === 0">
                <br />
                <v-btn
                  outlined
                  class="text-none"
                  small
                  :disabled="timer > 0"
                  @click="verifyAccessTokenAndSendOTP"
                >
                  {{ $_t('Send again') }}
                </v-btn>
              </p>
              <p v-else>
                {{ $_t('You can try again in') }}&nbsp;{{ timer }}&nbsp;{{
                  $_t('seconds')
                }}
              </p>
            </div>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="10" offset="1" v-else-if="shares">
      <h3>{{ $_t('Hi') }}&nbsp;{{ shares[0]["name"] }}!</h3>
      <p class="mb-8 subtitle-1">
        {{ $_t('Below procedure outcomes have been shared with you') }}:
      </p>
      <v-card>
        <v-card-text>
          <v-row
            v-for="share in shares"
            v-bind:key="share.appointment_procedure_share_id"
          >
            <v-col cols="12">
              <v-card class="elevation-4">
                <v-list>
                  <v-list-item class="d-block">
                    <p class="mb-2 caption">
                      {{ $_t('Procedure name') }}:<br />
                    </p>
                    <p class="mb-2 subtitle-2">
                      {{ share.data.procedure.name }}
                    </p>
                  </v-list-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item class="d-block">
                    <p class="mb-2 caption">{{ $_t('Scan name') }}:<br /></p>
                    <p class="mb-2 subtitle-2">
                      {{ share.data.procedure.scan.name }}
                    </p>
                  </v-list-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item class="d-block">
                    <p class="mb-2 caption">{{ $_t('Patient details') }}:<br /></p>
                    <p class="mb-2 subtitle-2">
                      {{ share.patient_first_name + ' ' + share.patient_last_name }}&nbsp;
                      ({{ $_t('Patient number')}}: {{share.patient_number_1}})
                    </p>
                  </v-list-item>
                  <v-divider class="mb-2"></v-divider>
                </v-list>
                <v-card-actions>
                  <v-btn
                    v-if="share.report_blob"
                    @click="downloadReport(share.appointment_procedure_share_id, share.report_blob)"
                    outlined
                    color="primary"
                    class="text-none"
                    ><v-tooltip
                      v-if="share.data.reportIsSigned"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="green"
                          small
                          class="mr-1"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-shield-check-outline
                        </v-icon>
                      </template>
                      <span>{{
                        $_t('This report is signed')
                      }}</span> </v-tooltip
                    >{{ $_t('Report')
                    }}<v-icon small class="ml-1">mdi-download</v-icon></v-btn
                  >
                  <v-btn
                    @click="openViewerLink(share)"
                    v-if="share.data.appointmentProcedureStudyId"
                    class="text-none"
                    color="primary"
                    outlined
                  >
                    <v-icon class="mr-1" small>mdi-eye</v-icon>
                    {{ $_t('View images') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-flex>
</template>

<script>
import config from '@src/config';

export default {
  name: 'ShareViewer',
  components: {},
  data() {
    return {
      loading: true,
      title: '',
      error: false,
      OTPVerified: false,
      OTPCode: '',
      OTPRules: [
        v => !!v || this.$_t('OTP code is required'),
        v => v.length >= 6 || this.$_t('OTP code must be 6 characters long')
      ],
      OTPFormValid: false,
      OTPError: null,
      timer: 60,
      timerInterval: null,
      accessToken: null,
      shares: null,
      submitted: false,
      OTPMethod: null
    };
  },
  mounted() {
    if (!this.$route.params.shareAccessToken) {
      this.loading = false;
      this.error = this.$_t('Invalid share access token provided');
    } else {
      this.accessToken = this.$route.params.shareAccessToken;
      this.verifyAccessTokenAndSendOTP();
    }
  },
  computed: {},
  methods: {
    verifyAccessTokenAndSendOTP() {
      this.timer = 60;
      this.timerInterval = setInterval(() => {
        this.timer -= 1;
      }, 1000);
      fetch(
        config.standaloneWebBookingUrl +
          '/init/?type=portal_share&hash=' +
          config.webBookingOrgHash +
          '&action=verifyAccessTokenAndSendOTP',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            shareAccessToken: this.accessToken
          })
        }
      )
        .then(resp => {
          return resp.json();
        })
        .then(json => {
          if (json.status === 'E') {
            this.error = this.$_t(json.error_descr);
          } else if (json.status === 'S') {
            this.OTPMethod = json.response.otpMethod
            if (json.response.otpMethod == 'sms') {
              this.$_notify.success(this.$_t('OTP sent'));
              this.title = this.$_t('Enter OTP code sent to you by SMS');
            } else {
              this.title = this.$_t('Enter a pre-generated OTP code that has been shared with you by the patient');
            }
          } else {
            throw Error();
          }
          this.loading = false;
        })
        .catch(error => {
          this.error = this.$_t('Could not complete the request, try again');
          this.loading = false;
        });
    },
    submitOTP() {
      if (this.OTPFormValid) {
        this.loading = true;
        this.OTPError = null;
        fetch(
          config.standaloneWebBookingUrl +
            '/init/?type=portal_share&hash=' +
            config.webBookingOrgHash +
            '&action=submitOTP',
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              shareAccessToken: this.accessToken,
              OTPCode: this.OTPCode
            })
          }
        )
          .then(resp => {
            return resp.json();
          })
          .then(json => {
            if (json.status === 'E') {
              this.OTPError = this.$_t(json.error_descr);
            } else if (json.status === 'S') {
              json.response.forEach(share => {
                share.data = JSON.parse(share.data_json)
              })
              this.shares = json.response;
            } else {
              throw Error();
            }
            this.loading = false;
          })
          .catch(error => {
            this.error = this.$_t('Could not complete the request, try again');
            this.loading = false;
          });
      }
    },
    logAccess(appointmentProcedureShareId, type) {
      fetch(
        config.standaloneWebBookingUrl +
          '/init/?type=portal_share&hash=' +
          config.webBookingOrgHash +
          '&action=logAccess',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            shareAccessToken: this.accessToken,
            appointmentProcedureShareId,
            type
          })
        }
      )
    },
    downloadReport(appointmentProcedureShareId, base64Blob) {
      this.logAccess(appointmentProcedureShareId, 'report');
      fetch(base64Blob).then(res =>
        res.blob().then(blob => {
          const objectUrl = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = objectUrl;
          anchor.download = Date.now();
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
        })
      );
    },
    openViewerLink(share) {
      this.loading = true
      this.logAccess(share.appointment_procedure_share_id, 'images');

      fetch(
        config.standaloneWebBookingUrl +
        '/init/?type=portal_share&hash=' +
        config.webBookingOrgHash +
        '&action=generateJ4CareViewerLink&appointmentProcedureStudyId=' + share.data.appointmentProcedureStudyId,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).then(async response => {
        const json = await response.json()
        this.loading = false
        if (!json.status || json.status !== 'S') {
          this.$_notify.error(this.$_t('Could not generate a images link'));
          return
        }
        window.open(json.response, '_blank');
      }).catch(error => {
        this.$_notify.error(this.$_t('Could not generate a images link'));
        this.loading = false
      })
    }
  },
  watch: {
    OTPCode(val) {
      this.OTPCode = val.toUpperCase();
    },
    timer(val) {
      if (val === 0) {
        clearInterval(this.timerInterval);
      }
    }
  }
};
</script>
