import Vue from 'vue';

declare module 'vue/types/vue' {
  interface VueConstructor {
    http: any;
  }
}

const state = {
  practitioner: {
    practices: []
  },
  scheduleClinicScans: [],
  scheduleClinicSlotsPerScan: []
};

const actions = {
  getPractitioner({ commit }) {
    const practitioner = {};
    commit('setPractitioner', practitioner);
  },
  getPractices({ commit }) {
    const practices = {};
    commit('setPractices', practices);
  },
  getPractitionerScheduleClinicScans({ commit }) {
    try {
      const scans = [
        { scanName: 'Rezonans Magnetyczny', scanCode: 'MR' },
        { scanName: 'Tomografia Komputerowa', scanCode: 'TK' }
      ];

      commit('setPractitionerScheduleClinicScans', scans);
    } catch (error) {
     //console.log(error)
    }
  },
  async getPractitionerScheduleClinicSlotsPerScan(
    { commit }: any, requestData: any
  ) {
    (this as any)._vm.$_rest.get(
      `/organizations/${requestData.organizationId}/practitioners/${requestData.practitionerId}/datasets/clinic-schedule/default?clinicScanRoomId=${requestData.clinicScanRoomId}&procedureId=${requestData.procedureId}&slotsOnlyForAvailabilityCategoriesIdsCsv=${requestData.slotsOnlyForAvailabilityCategoriesIdsCsv}&startDate=${requestData.startDateSql}&endDate=${requestData.endDateSql}`,
      {},
      (response) => {
        commit('setPractitionerScheduleClinicSlotsPerScan', response);
      },
      (error) => {
        //console.log(error);
      }, {
        prefixRoutesWithOrganizationId: false
      }
    );
  }
};

const mutations = {
  setPractitioner: (state: any, practitioner: any) =>
    (state.practitioner = practitioner),
  setPractices: (state: any, practices: any) =>
    (state.practitioner.practices = practices),
  setPractitionerScheduleClinicScans: (state: any, clinicScans: any) =>
    (state.scheduleClinicScans = clinicScans),
  setPractitionerScheduleClinicSlotsPerScan: (state: any, clinicSlots: any) =>
    (state.scheduleClinicSlotsPerScan = clinicSlots)
};

const getters = {
  practitioner: (state: any) => state.practitioner,
  practitionerPractices: (state: any) => state.practitioner.practices,
  getPractitionerScheduleClinicScans: (state: any) => state.scheduleClinicScans,
  getPractitionerScheduleClinicSlotsPerScan: (state: any) =>
    state.scheduleClinicSlotsPerScan
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
