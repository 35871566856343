<template>
  <v-row>
    <v-col
      cols="12"
      class="my-0 py-0"
    >
      <v-text-field
        v-model="patient.address1"
        label="Address Line 1"
        ref="address1"
        outlined
        :rules="[rules.required]"
        @input="$emit('input', patient)"
        @click="selectText('address1')"
      />
    </v-col>
    <v-col
      cols="12"
      class="my-0 py-0"
    >
      <v-text-field
        v-model="patient.address2"
        label="Address Line 2"
        ref="address2"
        outlined
        @input="$emit('input', patient)"
        @click="selectText('address2')"
      />
    </v-col>
    <v-col
      cols="12"
      class="my-0 py-0"
    >
      <v-text-field
        v-model="patient.postCode"
        :label="$_t('Postcode')"
        ref="postCode"
        outlined
        @input="$emit('input', patient)"
        @click="selectText('postCode')"
      />
    </v-col>
    <v-col
      cols="12"
      class="my-0 py-0"
    >
      <v-text-field
        v-model="patient.town"
        label="Town/City"
        ref="town"
        :rules="[rules.required]"
        outlined
        @input="$emit('input', patient)"
        @click="selectText('town')"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Address',
  props: {},
  data () {
    return {
      patient: {},
      rules: {
        required: value => !!value || 'This field is required.'
      }
    }
  },
  mounted () {
    this.patient = {
      address1: this.storeAppointment.patient.address1,
      address2: this.storeAppointment.patient.address2,
      postCode: this.storeAppointment.patient.postCode,
      town: this.storeAppointment.patient.town
    }
  },
  computed: {
    ...mapGetters('app', { currentPatient: 'currentPatient' }),
    ...mapGetters('appointment', { storeAppointment: 'appointment' })
  },
  methods: {
    selectText (ref) {
      const field = this.$refs[ref]
      this.$nextTick(() => {
        setTimeout(() => {
          field.$el.querySelector('input').select()
        }, 0)
      })

    }
  }
}

</script>
