<template>
  <patient-layout> 
    <patient-appointment-booking-clinics></patient-appointment-booking-clinics>
  </patient-layout>
</template>

<script>
import PatientLayout from '@router/layouts/patient.vue'
import PatientAppointmentBookingClinics from '@components/appointment/booking/clinics/Clinics.vue'

export default {
  components: {
    PatientLayout,
    PatientAppointmentBookingClinics
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped></style>
