<template>
  <div>
    <patient-layout
      :title="$_t('My appointments')"
      :action-href="config.webBookingLink"
      action-title="Schedule a visit"
    >
      <v-tabs
        @change="cancelShare"
        :fixed-tabs="$vuetify.breakpoint.smAndUp"
        style="border-radius: 5px"
        v-model="tab"
        :vertical="$vuetify.breakpoint.xsOnly"
        v-if="!loading && !sharesLoading"
      >
        <v-tab
          v-for="(v, k) in tabs"
          v-bind:key="k"
          style="max-width: 100%"
          class="text-none"
          active-class="tab-active"
          ripple
        >
          {{ $_t(v.name) }}
          <v-badge
            color="primary"
            :content="'' + v.count"
            class="ml-1"
          ></v-badge>
        </v-tab>
      </v-tabs>
      <v-card flat>
        <v-card-text>
          <div v-if="loading || sharesLoading" class="text-center pt-12 pb-12">
            <v-progress-circular
              :size="75"
              color="primary"
              indeterminate
              class="mb-12"
            ></v-progress-circular>
            <br/>
            <span class="subtitle-1">{{ $_t('Loading appointments...') }}</span>
          </div>
          <div v-else>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(v, k) in tabs" v-bind:key="k">
                <v-row v-if="tab === 1 && proceduresShareable > 0" class="px-5">
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="shareEnabled = !shareEnabled"
                    color="primary"
                    :outlined="!shareEnabled"
                    :width="$vuetify.breakpoint.xsOnly ? '100%' : ''"
                    large
                    v-show="config.procedureSharingEnabled"
                  >
                    {{ !shareEnabled ? $_t('Share') : $_t('Close share') }}
                    <v-icon small class="ml-1">mdi-share</v-icon>
                  </v-btn>
                </v-row>
                <div v-if="k !== 'shared'">
                  <div v-for="(apps, key) in appointments[k]" v-bind:key="key">
                    <p class="subtitle-2 mt-4 mb-4">
                      <v-icon class="mr-1" left>
                        mdi-calendar-check
                      </v-icon>
                      <span> {{ _formatDate(key) }}</span>
                    </p>
                    <div v-for="(app, appIndex) in apps" v-bind:key="appIndex">
                      <div
                        v-for="(appProc,
                        appProcIndex) in app.appointmentProcedures"
                        v-bind:key="appProcIndex"
                        class="mb-8"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            @click="selectProcedureForSharing(app, appProc)"
                          >
                            <AppointmentCard
                              :appointment="app"
                              :appointmentProcedure="appProc"
                              :report="appProc.report"
                              :studyViewerLinkExists="
                                Boolean(appProc.appointmentProcedureStudyId)
                              "
                              v-on:appointmentCancelled="onAppointmentCancelled"
                              :disabled="
                                shareEnabled &&
                                  !(
                                    appProc.report ||
                                    Boolean(appProc.appointmentProcedureStudyId)
                                  )
                              "
                              :sharable="shareEnabled"
                              :selected="
                                selectedProceduresToShare &&
                                  selectedProceduresToShare.indexOf(appProc) !==
                                    -1
                              "
                              :css-style="
                                selectedProceduresToShare.includes(appProc)
                                  ? 'border: 2px solid var(--v-success-base)'
                                  : 'border: 2px solid transparent'
                              "
                              :is-cancelled="tab === 2"
                              :is-past="tab === 1"
                              :is-upcoming="tab === 0"
                            ></AppointmentCard>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(sharedPerAccessToken, key) in appointments.shared" :key="key">
                    <div
                      v-for="(share, index) in sharedPerAccessToken"
                      :key="index"
                      class="subtitle-2 mt-4 mb-4"
                    ></div>
                    <div class="row">
                      <div class="col col-12">
                        <v-card class="elevation-4">
                          <v-list class="py-0">
                            <v-list-item class="blue-grey lighten-5">
                              <v-list-item-avatar>
                                <v-icon class="mr-1">
                                  mdi-account-arrow-right
                                </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <div
                                  v-for="(share, index) in sharedPerAccessToken"
                                  :key="index"
                                >
                                  <div v-if="index === 0">
                                    <div class="row">
                                      <div class="col col-12 col-lg-3 py-0">
                                        <v-list class="py-0">
                                          <v-list-item class="d-block blue-grey lighten-5">
                                            <span class="mb-2 caption">
                                              {{ $_t('Sent to') }}:<br/>
                                            </span>
                                            <span class="mb-2 subtitle-2">
                                              {{ share.name }} ({{
                                                share.email
                                              }})
                                            </span>
                                          </v-list-item>
                                        </v-list>
                                      </div>
                                      <div class="col col-12 col-lg-3 py-0">
                                        <v-list class="py-0">
                                          <v-list-item class="d-block blue-grey lighten-5">
                                            <span class="mb-2 caption">
                                              {{ $_t('Sent on') }}:<br/>
                                            </span>
                                            <span class="mb-2 subtitle-2">
                                              {{
                                                _formatDate(
                                                  share.created_datetime
                                                )
                                              }}
                                              {{
                                                _formatTime(
                                                  share.created_datetime
                                                )
                                              }}
                                            </span>
                                          </v-list-item>
                                        </v-list>
                                      </div>
                                      <div class="col col-12 col-lg-3 py-0">
                                        <v-list class="py-0">
                                          <v-list-item class="d-block blue-grey lighten-5">
                                            <span class="mb-2 caption">
                                              {{ $_t('Expires on') }}:<br/>
                                            </span>
                                            <span class="mb-2 subtitle-2">
                                              {{
                                                _formatDate(
                                                  share.expires_datetime
                                                )
                                              }}
                                              {{
                                                _formatTime(
                                                  share.expires_datetime
                                                )
                                              }}
                                            </span>
                                          </v-list-item>
                                        </v-list>
                                      </div>
                                      <div class="col col-12 col-lg-3 py-0">
                                        <v-list class="py-0">
                                          <v-list-item class="d-block blue-grey lighten-5">
                                            <span class="mb-2 caption">
                                              {{ $_t('Share status') }}:<br/>
                                            </span>
                                            <span class="mb-2 subtitle-2">
                                              <v-chip
                                                v-if="
                                                  share.is_active === '1' &&
                                                    share.expired === '0'
                                                "
                                                color="primary"
                                                text-color="white"
                                                class="py-1"
                                                label
                                              >
                                                {{ $_t('Active') }}
                                              </v-chip>
                                              <v-row
                                                v-if="
                                                  share.is_active === '1' &&
                                                  share.expired === '0' &&
                                                  share.otp_method === 'generate'
                                                ">
                                                <v-col cols="12" sm="6" lg="12" class="pb-0">
                                                  <v-text-field
                                                    append-icon="mdi-eye"
                                                    append-outer-icon="mdi-content-copy"
                                                    :label="$_t('Access code')"
                                                    :type="(OTPShow) ? 'text' : 'password'"
                                                    @click:append="OTPShow = !OTPShow"
                                                    @click:append-outer="copyToClipboard(share.otp_code)"
                                                    v-model="share.otp_code"
                                                    dense
                                                    outlined
                                                    readonly
                                                    hide-details
                                                  ></v-text-field>
                                                </v-col>
                                              </v-row>
                                              <v-chip
                                                v-if="
                                                  share.is_active === '1' &&
                                                    share.expired === '1'
                                                "
                                                color="red"
                                                text-color="white"
                                                class="py-1"
                                                label
                                              >
                                                {{ $_t('Expired') }}
                                              </v-chip>
                                              <v-chip
                                                v-if="share.is_active === '0'"
                                                color="grey"
                                                text-color="white"
                                                class="py-1"
                                                label
                                              >
                                                {{ $_t('Revoked') }}
                                              </v-chip>
                                            </span>
                                          </v-list-item>
                                        </v-list>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-divider></v-divider>
                          <v-list class="py-0">
                            <v-list-item
                              v-for="(share, index) in sharedPerAccessToken"
                              :key="index"
                            >
                              <v-list-item-avatar>
                                {{ (index + 1) }}
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <div class="row">
                                  <div class="col col-12 col-lg-3 py-0">
                                    <v-list class="py-0">
                                      <v-list-item class="d-block">
                                        <span class="mb-2 caption">
                                          {{ $_t('Procedure') }}:<br/>
                                        </span>
                                        <span class="mb-2 subtitle-2">
                                          {{
                                            share.data.procedure.name
                                          }}
                                        </span>
                                      </v-list-item>
                                    </v-list>
                                  </div>
                                  <div class="col col-12 col-lg-3 py-0">
                                    <v-list class="py-0">
                                      <v-list-item class="d-block">
                                        <span class="mb-2 caption">
                                          {{ $_t('Clinic') }}:<br/>
                                        </span>
                                        <span class="mb-2 subtitle-2">
                                          {{
                                            share.data.clinic.name
                                          }}
                                        </span>
                                      </v-list-item>
                                    </v-list>
                                  </div>
                                  <div class="col col-12 col-lg-3 py-0">
                                    <v-list class="py-0">
                                      <v-list-item class="d-block">
                                        <span class="mb-2 caption">
                                          {{ $_t('Time') }}:<br/>
                                        </span>
                                        <span class="mb-2 subtitle-2">
                                          {{
                                            _formatDate(share.data.appointment.date)
                                          }}
                                          {{
                                            _formatTime(share.data.appointment.date)
                                          }}
                                        </span>
                                      </v-list-item>
                                    </v-list>
                                  </div>
                                  <div class="col col-12 col-lg-3 py-0">
                                    <v-list class="py-0">
                                      <v-list-item class="d-block">
                                        <span class="mb-2 caption">
                                          {{ $_t('View status') }}:<br/>
                                        </span>
                                        <v-chip
                                          v-if="
                                            share.access_to_report_attempted ===
                                              '1' || share.access_to_images_attempted === '1'
                                          "
                                          color="success"
                                          text-color="white"
                                          class="py-1"
                                          label
                                        >
                                          {{ $_t('Viewed') }}
                                        </v-chip>
                                        <v-btn
                                          v-if="
                                            share.access_to_report_attempted ===
                                              '1' || share.access_to_images_attempted === '1'
                                          "
                                          color="primary"
                                          text
                                          @click="openShareAccessDetails(share)"
                                          small
                                        >{{ $_t('view details') }}
                                        </v-btn
                                        >
                                        <v-chip
                                          v-else
                                          color="grey"
                                          text-color="white"
                                          class="py-1"
                                          label
                                        >
                                          {{ $_t('Not yet viewed') }}
                                        </v-chip>
                                      </v-list-item>
                                    </v-list>
                                  </div>
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          <v-card-actions class="pb-4 px-4 justify-end"
                                          v-if="sharedPerAccessToken[0].is_active === '1' && sharedPerAccessToken[0].expired === '0'">
                            <v-btn class="text-none" color="#0078FF" outlined
                                   @click="revokeShareAccess(sharedPerAccessToken)">
                              <v-icon class="mr-1" small>mdi-cancel</v-icon>
                              {{ $_t('Revoke Access') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </div>
                <v-layout
                  row
                  wrap
                  align-center
                  v-if="!Object.keys(appointments[k]).length"
                  style="height:100px"
                >
                  <v-flex class="text-center">
                    <v-alert
                      color="primary"
                      outlined
                      dark
                      icon="mdi-exclamation-thick"
                    >{{ $_t('No appointments found') }}
                    </v-alert>
                  </v-flex>
                </v-layout>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card-text>
      </v-card>
    </patient-layout>
    <v-bottom-navigation
      v-show="shareEnabled"
      transition="scroll-y-transition"
      color="white"
      :background-color="
        selectedProceduresToShare.length > 0 ? 'primary' : 'success'
      "
      app
    >
      <div v-if="selectedProceduresToShare.length > 0" class="d-flex align-center">
        <v-btn
          @click="shareDialog = true"
          color="white"
          class="d-flex align-center py-3 text-body-1 grey--text rounded"
          style="max-width:500px"
        >
          <p class="ma-0 mr-8">
            {{ $_t('Share selected') }}
          </p>
          <v-badge
            color="primary"
            :content="selectedProceduresToShare.length"
            class="mr-4"
            style="position: absolute; right: 0;"
          ></v-badge>
        </v-btn>
      </div>
      <span v-else class="pa-3 title">
        {{ $_t('Select at least one procedure to share') }}.
      </span>
    </v-bottom-navigation>
    <v-dialog v-model="shareDialog" width="550" persistent>
      <v-card v-if="!shareDialogLoading">
        <v-card-title class="text-h5">
          {{ $_t('Share procedures') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <p>{{ $_t('You are about to share the following procedures') }}:</p>
          <ul>
            <li v-for="p in selectedProceduresToShare" v-bind:key="p.id">
              {{ p.procedure.name }}
            </li>
          </ul>
          <v-divider class="my-4"></v-divider>
          <p>
            {{
              $_t('Provide details of the person you are sharing the above procedures with. We will send a welcome email to the email address provided with instructions on how to access your shared procedures')
            }}:
          </p>
          <v-form ref="shareForm" v-model="shareFormValid">
            <v-text-field
              :label="$_t('Name')"
              v-model="shareDetails.name"
              outlined
              dense
              :rules="nameRules"
            ></v-text-field>
            <v-text-field
              :label="$_t('Email')"
              v-model="shareDetails.email"
              outlined
              dense
              :rules="emailRules"
            ></v-text-field>
            <p class="my-4">
              {{
                $_t('For enhanced security, we require the person you are sharing with to use 2-factor authentication to gain access')
              }}.
              <br/>
              <span
                v-if="config.shareOtpMethodGenerate && config.shareOtpMethodSms">{{ $_t('Please select one of the below options') }}:</span>
              <v-radio-group v-model="shareDetails.shareOtpMethod" dense>
                <v-radio
                  v-if="config.shareOtpMethodSms"
                  :label="$_t('SMS code sent to the person you share with')"
                  value="sms"
                  class="custom-radio"
                ></v-radio>
                <v-radio
                  v-if="config.shareOtpMethodGenerate"
                  :label="$_t('Pre-generated authentication code. You must share this code directly with the person you are sharing your procedures with')"
                  value="generate"
                  class="custom-radio"
                ></v-radio>
              </v-radio-group>
            </p>

            <v-text-field
              outlined
              dense
              readonly
              v-if="shareDetails.shareOtpMethod === 'generate'"
              v-model="generatedOTPCode"
              append-icon="mdi-content-copy"
              @click="copyToClipboard(generatedOTPCode)"
              @click:append="copyToClipboard(generatedOTPCode)"
            >
            </v-text-field>
            <span v-if="shareDetails.shareOtpMethod === 'generate'">
              {{$_t('Access code disclaimer message.')}}
            </span>
            <br/>
            <vue-tel-input
              v-if="shareDetails.shareOtpMethod === 'sms'"
              v-model="shareDetails.mobile"
              mode="international"
              @validate="validatePhoneObject"
              :placeholder="$_t('Phone number')"
            ></vue-tel-input>
            <span>
              <br/>
              {{$_t('Share duration (in hours)')}}:
            </span>
            <v-slider
              v-model="shareDetails.duration"
              min="1"
              max="72"
              thumb-label="always"
              thumb-size="24"
              dense
              class="mt-8 custom-slider"
            ></v-slider>
            <p>
              {{ $_t('Please ensure the data you have entered is correct as otherwise you might be sharing your medical documentation with an unauthorised person') }}.
            </p>
            <v-checkbox v-model="shareCheckbox" class="custom-checkbox">
              <template v-slot:label>
                <div>
                  *
                  {{
                    $_t('I authorise the person above to access my medical documentation and I agree it to be shared electronically')
                  }}.&nbsp;
                  <v-tooltip top max-width="300px" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="text-decoration-underline text-color-primary"
                      >{{$_t('What does this mean')}}?</span>
                    </template>
                    <span>{{$_t("Once you click 'share', an email will be sent to the person you are sharing with detailing the access to your medical documentation that will be further secured by the selected additional authentication method")}}.</span>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
            <v-btn
              @click="shareProcedures"
              color="primary"
              width="100%"
              :disabled="!shareFormValid || (!mobileValid && shareDetails.shareOtpMethod === 'sms') || !shareCheckbox"
            >
              {{ $_t('Share') }}
            </v-btn>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="shareDialog = false" width="100%">
            {{ $_t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>&nbsp;</v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            size="32"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shareAccessDialog" width="70%" max-width="750px">
      <v-card v-if="shareAccessDialogLoading">
        <v-card-title>&nbsp;</v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            size="32"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          {{ $_t('Share access details') }}
          <v-spacer></v-spacer>
          <v-btn fab color="primary" x-small @click="shareAccessDialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="shareAccessData"
            :headers="shareAccessHeaders"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
                 'items-per-page-text':$_t('Rows per page')
            }"
          >
            <template v-slot:item.accessed_item_code="{ item }">
              <span class="text-capitalize">{{ $_t(item.accessed_item_code) }}</span>
            </template>
            <template v-slot:item.accessed_datetime="{ item }">
              {{ _formatDate(item.accessed_datetime) }} {{_formatTime(item.accessed_datetime)}}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns'
import PatientLayout from '@router/layouts/patient.vue'
import AppointmentCard from '@components/appointment/cards/PatientCard.vue'
import config from '@config'
import { VueTelInput } from 'vue-tel-input'

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export default {
  name: 'AppointmentGrid',
  components: {
    PatientLayout,
    AppointmentCard,
    VueTelInput
  },
  data () {
    return {
      tab: 0,
      appointments: {
        all: {},
        upcoming: {},
        past: {},
        cancelled: {},
        shared: {}
      },
      loading: false,
      config: config,
      proceduresToProcess: 0,
      proceduresProcessed: 0,
      proceduresShareable: 0,
      tabs: {
        upcoming: {
          name: 'Upcoming',
          count: 0
        },
        past: {
          name: 'Past',
          count: 0
        },
        cancelled: {
          name: 'Cancelled',
          count: 0
        }
      },
      shareEnabled: false,
      shareDialog: false,
      selectedProceduresToShare: [],
      shareDetails: {
        name: '',
        email: '',
        mobile: '',
        duration: 24,
        data: [],
        shareOtpMethod: null,
        shareSubmitted: false
      },
      nameRules: [v => !!v || this.$_t('Name is required')],
      emailRules: [
        v => !!v || this.$_t('E-mail is required'),
        v => /.+@.+\..+/.test(v) || this.$_t('E-mail must be valid')
      ],
      shareFormValid: null,
      mobileValid: false,
      shareDialogLoading: false,
      shareCheckbox: false,
      sharesLoading: false,
      shareAccessDialog: false,
      shareAccessDialogLoading: false,
      shareAccessData: [
        {
          accessed_item_code: 'images',
          accessed_datetime: '2021-09-22 12:56:39'
        }
      ],
      shareAccessHeaders: [
        {
          text: this.$_t('Access type'),
          value: 'accessed_item_code'
        },
        {
          text: this.$_t('Accessed at'),
          value: 'accessed_datetime'
        }
      ],
      generatedOTPCode: null,
      OTPShow: false
    }
  },
  mounted () {
    this.loading = true
    this.getAppointmentsFromApi()
    if (config.procedureSharingEnabled) {
      this.sharesLoading = true
      this.tabs.shared = {
        name: 'Shared',
        count: 0
      }
      if (!config.shareOtpMethodSms && !config.shareOtpMethodGenerate) {
        console.error('At least one OTP method should be configured (sms/generate)')
      } else if (config.shareOtpMethodSms) {
        this.shareDetails.shareOtpMethod = 'sms'
      } else {
        this.shareDetails.shareOtpMethod = 'generate'
      }
    }
  },
  watch: {
    proceduresProcessed (val) {
      if (val > 0 && val === this.proceduresToProcess) {
        this.loading = false
      }
    },
    shareEnabled (val) {
      //will be filtered to only those available to share
      this.appointments.past = this.filterPastAppointments(
        this.appointments.all,
        false
      )
      if (!val) {
        this.selectedProceduresToShare = []
        this.shareDialog = false
        this.shareDetails = {
          name: '',
          email: '',
          mobile: '',
          duration: 24,
          data: [],
          shareOtpMethod: null,
          shareSubmitted: false
        }
      }
    },
    shareDetails: {
      handler (val) {
        if (
          val.data.length > 0 &&
          val.data.length === this.selectedProceduresToShare.length &&
          !val.shareSubmitted
        ) {
          this.postShareRequest()
        }
      },
      deep: true
    },
    shareDialog (val) {
      if (val) {
        this.generatedOTPCode = Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 6).toUpperCase()
      }
    }
  },
  computed: {},
  methods: {
    _formatDate (appointmentDate) {
      const dateObj = new Date(appointmentDate.replace(/-/g, '/').slice(0,19).replace('T', ' '))
      const dateTxt = this.dateFormatted(dateObj)
      const dayName = this.$_t(days[dateObj.getDay()])
      return `${dayName} ${dateTxt}`
    },
    _formatTime (datetime) {
      return datetime.substr(11, 5)
    },
    dateFormatted (date) {
      return format(date, 'dd.MM.yyyy')
    },
    groupBy (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    },
    cancelShare () {
      this.shareEnabled = false
      this.shareDialog = false
      this.selectedProceduresToShare = []
    },
    onAppointmentCancelled () {
      this.getAppointmentsFromApi()
    },
    filterUpcomingAppointments (data) {
      const today = new Date()
      const appointments = data.filter(appointment => {
        if (!appointment.date) {
          return false
        }

        if (appointment.status.isCancel === 1) {
          return false
        }

        const activeForWeb = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isActiveForWeb === 1
        })
        if (!activeForWeb) {
          return false
        }

        const isFinished = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isFinished === 1
        })
        if (isFinished) {
          return false
        }

        const isScheduled = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isScheduled === 1
        })
        if (!isScheduled) {
          return false
        }

        const appDate = new Date(appointment.date.replace(/-/g, '/').slice(0,19).replace('T', ' '))
        if (appDate < today) {
          return false
        }

        appointment.appointmentProcedures.forEach(
          () => this.tabs.upcoming.count++
        )

        return true
      })
      return this.groupBy(appointments, 'date')
    },
    filterPastAppointments (data, fetchReportsAndStudies = true) {
      const appointments = data.filter(appointment => {
        if (!appointment.date) {
          return false
        }

        const activeForWeb = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isActiveForWeb === 1
        })
        if (!activeForWeb) {
          return false
        }

        const isFinished = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isFinished === 1
        })
        if (!isFinished) {
          return false
        }

        const reportExists = appointment.appointmentProcedures.some(appProc => {
          return appProc.report || Boolean(appProc.appointmentProcedureStudyId)
        })
        if (!reportExists && this.shareEnabled) {
          return false
        }

        if (fetchReportsAndStudies) {
          appointment.appointmentProcedures.forEach(appProc => {
            this.tabs.past.count++
            this.getReportAndStudyData(appointment, appProc)
          })
        }

        return true
      })

      if (this.proceduresToProcess === 0) {
        this.loading = false
      }

      return this.groupBy(appointments, 'date')
    },
    filterCancelledAppointments (data) {
      const today = new Date()
      const appointments = []
      data.forEach(appointment => {
        if (!appointment.date) {
          return
        }

        const activeForWeb = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isActiveForWeb === 1
        })
        if (!activeForWeb) {
          return
        }

        const isCancelled = appointment.status.isCancel === 1
        const isFinished = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isFinished === 1
        })
        const isScheduled = appointment.appointmentProcedures.some(appProc => {
          return appProc.status.isScheduled === 1
        })

        const appDate = new Date(appointment.date.replace(/-/g, '/').slice(0,19).replace('T', ' '))
        if (isCancelled || (appDate < today && isScheduled && !isFinished)) {
          appointment.appointmentProcedures.forEach(
            () => this.tabs.cancelled.count++
          )
          appointments.push(appointment)
        }
      })

      return this.groupBy(appointments, 'date')
    },
    async getSharedAppointments () {
      this.sharesLoading = true
      const response = await fetch(
        config.standaloneWebBookingUrl +
        '/init/?type=portal_share&hash=' +
        config.webBookingOrgHash +
        '&action=get',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          }
        }
      )

      const json = await response.json()
      this.tabs.shared.count = json.response.shares.length
      this.appointments.shared = this.groupBy(json.response.shares, 'access_token')
      for(const [key, value] of Object.entries(this.appointments.shared)) {
        value.forEach((share) => {
          try {
          share.data = JSON.parse(share.data_json)
          } catch (e) {
            console.error('Failed to parse share data json for access token: ' + key)
          }
        })
      }
      this.sharesLoading = false
    },
    getAppointmentsFromApi () {
      this.$_rest.get(
        `/patients/${this.$store.getters['app/currentUserId']}/appointments?groups=all,appointment_appointment_procedures,appointment_clinic,appointment,appointment_procedure_status,appointment_procedure_procedure,appointment_status,appointment_procedure_parent_appointment_procedure,procedure_scan&orderBy[date]=desc&limit=500`,
        {},
        async response => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })

          this.appointments.all = response.data

          const upcoming = this.filterUpcomingAppointments(response.data)
          if (Object.keys(upcoming).length > 0) {
            this.appointments.upcoming = upcoming
          }
          const cancelled = this.filterCancelledAppointments(response.data)
          if (Object.keys(cancelled).length > 0) {
            this.appointments.cancelled = cancelled
          }
          const past = this.filterPastAppointments(response.data)
          if (Object.keys(past).length > 0) {
            this.appointments.past = past
          }
          if (config.procedureSharingEnabled) {
            await this.getSharedAppointments()
          }
        },
        error => {
          this.loading = false
          this.$_notify.error(error)
        }
      )
    },
    getReportAndStudyData (apt, appProc) {
      this.proceduresToProcess += 2 //report & study to process

      if (appProc.status.code === 'FAP') {
        this.getReport(apt.id, appProc)
      } else {
        this.proceduresProcessed++
      }
      if (appProc.status.isFinished) {
        this.getAppointmentProcedureStudy(apt.id, appProc)
      } else {
        this.proceduresProcessed++
      }
    },
    getAppointmentProceduresActiveForWeb (appointmentProcedures) {
      return appointmentProcedures.filter(appProc => {
        return appProc.status.isActiveForWeb === 1
      })
    },
    getReport (appointmentId, appProc) {
      this.$_rest.get(
        `/patients/${this.$store.getters['app/currentUserId']}/appointments/${appointmentId}/appointment-procedures/${appProc.id}/report?groups=appointment_procedure_report_status,all,appointment_procedure_report_appointment_procedure_report_signatures`,
        {},
        response => {
          appProc.report = response.data
          this.proceduresProcessed++
          this.proceduresShareable++
        },
        error => {
          appProc.reportNotFound = true
          this.proceduresProcessed++
          this.proceduresShareable++
        }
      )
    },
    getAppointmentProcedureStudy (appointmentId, appProc) {
      this.$_rest.get(
        `/patients/${this.$store.getters['app/currentUserId']}/appointments/${appointmentId}/appointment-procedures/${appProc.id}/appointment-procedure-studies?status=S&orderBy[id]=desc&limit=1`,
        {},
        resp => {
          if (resp.data.length === 1 && resp.data[0].mwlDataJson) {
            this.proceduresShareable++
            appProc.appointmentProcedureStudyId = resp.data[0].id
          }
          this.proceduresProcessed++
        },
        error => {
          console.error(error)
          this.$_notify.error(
            this.$_t('Failed to fetch appointment procedure study')
          )
        }
      )
      return {}
    },
    selectProcedureForSharing (apt, aptProc) {
      if (!this.shareEnabled) {
        return
      }

      if (!(aptProc.report || Boolean(aptProc.appointmentProcedureStudyId))) {
        return
      }
      const date = apt.date.substr(0, 10) + ' ' + aptProc.startTime.substr(11, 5);
      aptProc.appointmentId = apt.id
      aptProc.appointmentDate = date
      aptProc.clinic = apt.clinic
      if (!this.selectedProceduresToShare.includes(aptProc)) {
        this.selectedProceduresToShare.push(aptProc)
      } else {
        const index = this.selectedProceduresToShare.indexOf(aptProc)
        this.selectedProceduresToShare.splice(index, 1)
      }
    },
    shareProcedures () {
      if (!this.shareDialogLoading) {
        this.shareDialogLoading = true
        this.selectedProceduresToShare.forEach(ap => {
          if (ap.report) {
            this.fetchShareData(ap)
          } else {
            const aptProcDetails = {
              appointment: {
                id: ap.appointmentId,
                date: ap.appointmentDate
              },
              appointmentProcedureId: ap.id,
              procedure: ap.procedure,
              clinic: ap.clinic,
              appointmentProcedureStudyId: ap.appointmentProcedureStudyId
            }

            this.shareDetails.data.push(aptProcDetails)
          }
        })
      }
    },
    fetchShareData (aptProc) {
      //1st fetch for app proc details
      this.$_rest.get(
        `/patients/${this.$store.getters['app/currentUserId']}/appointments/${aptProc.appointmentId}/appointment-procedures/${aptProc.id}/report/file`,
        {},
        response => {
          if (response.status === 202) {
            this.$nextTick(() => {
              this.fetchShareData(aptProc)
            })
          } else if (response.status === 200) {
            response.blob().then(async blob => {
              const aptProcDetails = {
                appointment: {
                  id: aptProc.appointmentId,
                  date: aptProc.appointmentDate
                },
                appointmentProcedureId: aptProc.id,
                procedure: aptProc.procedure,
                clinic: aptProc.clinic,
                reportIsSigned:
                  aptProc.report.appointmentProcedureReportSignatures.length >
                  0,
                reportBlob: await this.blobToBase64(blob),
                appointmentProcedureStudyId: aptProc.appointmentProcedureStudyId
              }

              this.shareDetails.data.push(aptProcDetails)
            })
          } else {
            this.$_notify.error(
              this.$_t('Could not locate report to be shared')
            )
          }
        },
        error => {
          console.error(error)
          this.$_notify.error(
            this.$_t('Request to locate report to be shared failed')
          )
        },
        {
          handleResponse: false
        }
      )
    },
    validatePhoneObject (phoneObject) {
      this.mobileValid = phoneObject.valid
      if (phoneObject.valid) {
        this.shareDetails.mobile = phoneObject.number.international
      }
    },
    postShareRequest () {
      this.shareDetails.shareSubmitted = true
      if (this.shareDetails.shareOtpMethod === 'generate') {
        this.shareDetails.otpCode = this.generatedOTPCode
        this.shareDetails.mobile = null
      }

      fetch(
        config.standaloneWebBookingUrl +
        '/init/?type=portal_share&hash=' +
        config.webBookingOrgHash +
        '&action=share',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          },
          body: JSON.stringify(this.shareDetails)
        }
      )
        .then(async resp => {
          this.shareDialogLoading = false
          if (resp.status === 200) {
            this.$_notify.success(
              this.$_t('The selected procedures have been shared')
            )
            this.shareDialog = false
            this.shareEnabled = false
            this.shareDetails.data = []
            this.tab = 3
            await this.getSharedAppointments()
          } else {
            this.$_notify.error(
              this.$_t(
                'Something went wrong. If the issue persists, try to re-login and try again'
              )
            )
          }
        })
        .catch(error => {
          this.$_notify.error(
            this.$_t('Could not complete the request, try again')
          )
          this.shareDialogLoading = false
        })
    },
    blobToBase64 (blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    async openShareAccessDetails (share) {
      this.shareAccessDialog = true
      this.shareAccessDialogLoading = true
      // fetch access details
      const response = await fetch(
        config.standaloneWebBookingUrl +
        '/init/?type=portal_share&hash=' +
        config.webBookingOrgHash +
        '&action=getAccessDetails&appointmentProcedureShareId=' + share.appointment_procedure_share_id,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          }
        }
      ).catch(error => {
        console.error(error)
        this.$_notify.error(
          this.$_t('Could not complete the request, try again')
        )
      })
      const json = await response.json()
      if (!json.status || json.status !== 'S') {
        this.$_notify.error(this.$_t('Failed to fetch share access data'))
        return
      }
      this.shareAccessData = json.response
      this.shareAccessDialogLoading = false
    },
    revokeShareAccess (sharesPerAccessToken) {
      this.sharesLoading = true
      fetch(
        config.standaloneWebBookingUrl +
        '/init/?type=portal_share&hash=' +
        config.webBookingOrgHash +
        '&action=revokeAccessByToken',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          },
          body: JSON.stringify({
            accessToken: sharesPerAccessToken[0].access_token
          })
        }
      )
        .then(async resp => {
          if (resp.status === 200) {
            this.$_notify.success(
              this.$_t('The share access has been revoked')
            )
            await this.getSharedAppointments()
          } else {
            this.$_notify.error(
              this.$_t(
                'Something went wrong. If the issue persists, try to re-login and try again'
              )
            )
          }
        })
        .catch(error => {
          this.$_notify.error(
            this.$_t('Could not complete the request, try again')
          )
        })
    },
    copyToClipboard (generatedCode) {
      if (!generatedCode) {
        generatedCode = this.generatedOTPCode
      }
      navigator.clipboard.writeText(generatedCode).then(() => {
        this.$_notify.success(this.$_t('OTP code copied to clipboard'))
      })
    }
  }
}
</script>
<style scoped>
.tab-active {
  background: var(--v-primary-lighten1);
  color: white !important;
}
.text-color-primary {
  color: var(--v-primary-base);
}
</style>
<style>
.custom-radio label, .custom-checkbox label, .custom-slider label {
  font-size: .875rem;
}
</style>
