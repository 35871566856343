<template>
  <v-container
    class="mt-0 pt-0"
    style="min-height: 400px;"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    />
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
      v-else-if="!currentPayment && !paymentRequired"
    >
      <v-col cols="12">
        <h6 class="text-h6 pb-5">
          {{$_t('Payment is not currently required')}}
        </h6>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
      v-else-if="!currentPayment && paymentRequired && price"
    >
      <v-col cols="12" class="text-center">
        <h6 class="text-h5 pb-5" v-if="charge">
          {{ $_t('A payment is required to secure your appointment, you will now be redirected to a new screen to take payment. Please see link to our') }}
          &nbsp;<a :href="config.cancellationPolicyLink" target="_blank">{{ $_t('cancellation policy') }}</a>
        </h6>
        <h6 class="text-h5 pb-5" v-else>
          {{ $_t('Card details are required to secure your appointment, you will now be redirected to a new payment screen to enter your details as per our') }}&nbsp;<a :href="config.cancellationPolicyLink" target="_blank">{{ $_t('cancellation policy') }}</a>.&nbsp; {{ $_t('No payment will be taken at this time.') }}
        </h6>
        <h6 class="text-h5 pb-5" v-if="charge">
           {{ currencySymbol }}&nbsp;{{ price }}
        </h6>
        <h6 class="text-subtitle-1 pb-5" v-if="charge">
          {{$_t('Accepted payment methods:')}}
        </h6>
        <v-btn large outlined color="primary" @click="createPaymentAndRedirect">
          <v-icon>mdi-credit-card</v-icon>
          <span v-if="charge">{{$_t('Pay with Debit or Credit card')}}</span>
          <span v-else>{{$_t('Continue to payment screen')}}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
      v-else-if="currentPayment && currentPayment.paymentStatus.code === 'IP'"
    >
      <v-col cols="12">
        <h6 class="text-h6 pb-5">
          {{$_t('Your payment is being processed...')}}
        </h6>
        <p>
          {{$_t('Check back later')}}
        </p>
        <v-progress-circular color="primary" indeterminate size="48"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
      v-else-if="currentPayment && currentPayment.paymentStatus.code === 'S'"
    >
      <v-col cols="12">
        <h6 class="text-h5 pb-5" v-if="charge">
          {{$_t('Your payment has been processed.')}}
        </h6>
        <h6 class="text-h5 pb-5" v-else>
          {{$_t('We have now secured your booking.')}}
        </h6>
        <p v-if="charge">
          <v-icon color="green" size="xl" class="mr-1" style="margin-top:-7px;">mdi-checkbox-marked-circle-outline</v-icon><span class="text-h5">{{currentPayment.currency.symbol}}{{parseFloat(currentPayment.paymentAmount).toFixed(2)}}</span>
        </p>
        <div class="text-h5">
          {{$_t('Thank you!')}}
        </div>
        <div class="text-center mt-6">
          <v-btn
            color="primary"
            class=""
            @click="$emit('next')"
          >
            {{ $_t('Next') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
      v-else-if="currentPayment && currentPayment.paymentStatus.code === 'F'"
    >
      <v-col cols="12">
        <h6 class="text-h5 pb-5">
          {{ $_t('Your card could not be processed') }}
        </h6>
        <div class="text-subtitle-1">
          {{ $_t('Please be aware your appointment is not confirmed, our team will be in contact to schedule the appointment by phone.') }}<br>
          {{ $_t('Please note that we are currently experiencing a high volume of requests.') }}
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="payTmpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <pay-tmp
          v-model="payTmpDialog"
          :deposit="false"
          :appointment-id="appointment.id"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import PayTmp from '@components/appointment/PayTmp.vue'
import config from '@src/config'

export default defineComponent({
  name: 'Payment',
  components: { PayTmp },
  props: { },
  setup(props, ctx) {
    const loading = ref(true)
    const currentPayment = ref(false)
    const paymentRequired = ref(true)
    const maxInProgressChecks = ref(10)
    const currentInProgressChecks = ref(0)
    const payTmpDialog = ref(false)
    const deposit = ref(false)
    const price = ref(0)
    const currencySymbol = ref('€')
    const charge = ref(false)

    const init = () => {
      ctx.root.$store.commit('app/setAppBarTitle', 'Payment')
      ctx.root.$store.commit('app/setAppBarButton1', null)
      ctx.root.$store.commit('app/setAppBarButton2', null)

      ctx.root.$store.dispatch('app/appBarShow')
      ctx.root.$root.$emit('scrollToTop')
    }
    const appointment = ctx.root.$store.getters['appointment/appointment']

    const fetchPrice = () => {
      loading.value = true
      const params = {
        groups: 'appointment_payment_charge, appointment_payment_skip, appointment_payment_currency, all'
      }
      if (sessionStorage.getItem('patientInsurerId')) {
        params.insurerId = sessionStorage.getItem('patientInsurerId')
      }

      ctx.root.$_rest.get(`/appointments/${appointment.id}/price`, params, (response) => {
        if (response.data && response.data.isSkip) {
          ctx.emit('next')
        } else if (response.data && response.data.paymentAmount && !isNaN(response.data.paymentAmount) && response.data.paymentAmount > 0) {
          price.value = response.data.paymentAmount
          if (response.data.currency && response.data.currency.symbol) {
            currencySymbol.value = response.data.currency.symbol
          }
          charge.value = response.data.isCharge
          sessionStorage.setItem('lastPaymentCharge', response.data.isCharge);
        } else {
          ctx.emit('next')
        }
        loading.value = false
      }, () => {
        loading.value = false
        ctx.root.$_notify('Price details could not be retrieved')
      },
      { prefixRoutesWithPatientId: true }
      )
    }

    const verifyCurrentPaymentStatus = () => {
      sessionStorage.setItem('paymentAppointmentId' , ctx.root.$route.params.appointmentId)

      ctx.root.$_rest.get(`/appointments/${appointment.id}/payments`, {groups: 'appointment_payment_payment_status, appointment_payment_currency, all'}, (response) => {
        if (!response.data || !Array.isArray(response.data) || !response.data.length) {
          fetchPrice()
        } else {
          response.data.forEach((payment) => {
            if (payment.paymentStatus.code === 'IP') {
              currentPayment.value = payment
              currentInProgressChecks.value++
              if (maxInProgressChecks.value > currentInProgressChecks.value) {
                setTimeout(verifyCurrentPaymentStatus, 5000)
              }
            } else if (payment.paymentStatus.code === 'S') {
              if (appointment.statusCode !== 'S') {
                ctx.root.$store.commit('app/setAppBarButton2', {
                  label: 'Next',
                  icon: 'mdi-chevron-right',
                  click: () => {
                    ctx.emit('next')
                  }
                })
              }
              currentPayment.value = payment
              charge.value = sessionStorage.getItem('lastPaymentCharge') === true
            } else if (payment.paymentStatus.code === 'F') {
              currentPayment.value = payment
              ctx.root.$store.commit('app/setAppBarButton1', null)
            } else {
              fetchPrice()
            }
          })

          loading.value = false
        }
      },
      () => {},
      { prefixRoutesWithPatientId: true })
    }

    const createPaymentAndRedirect = () => {
      loading.value = true
      const appointment = ctx.root.$store.getters['appointment/appointment']
      const currentUser = ctx.root.$store.getters['app/currentUser']

      ctx.root.$_rest.post(`/patients/${currentUser.id}/appointments/${appointment.id}/payments`, {
        paymentType: 2, //cc
        currencyCode: 'EUR'
      }, (response) => {
        if (response.data.paymentAmount && response.data.paymentGatewayUrl) {
          window.location = response.data.paymentGatewayUrl
          // payTmpDialog.value = true
          // deposit.value = response.data.paymentGatewayUrl && response.data.paymentGatewayUrl.indexOf('charge=0') > -1
        } else {
          paymentRequired.value = false
          loading.value = false
        }
      })
    }

    onMounted(() => {
      init()
      verifyCurrentPaymentStatus()
    })

    watch(payTmpDialog, (currentValue, oldValue) => {
      if (!currentValue && oldValue) {
        init()
        verifyCurrentPaymentStatus()
      }
    });

    return {
      loading,
      createPaymentAndRedirect,
      currentPayment,
      paymentRequired,
      payTmpDialog,
      deposit,
      appointment,
      price,
      currencySymbol,
      charge,
      config
    }
  }
})
</script>
