import _Vue from 'vue'

const translate = {
  install (Vue: typeof _Vue) {
    Vue.prototype.$_t = function (msg: string) {
      // use this to turn on translations
       return this.$t(msg)

      // use this to turn off translations
      //return msg

      // use this to only show untranslated text
      // return '-'
    }
  }
}

export default translate
