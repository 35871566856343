<template>
 <safety-questions
   v-model="questions"
   :appointment="appointment"
   :patient-id="patientId"
   :fetch-route="fetchRoute"
   :save-route="saveRoute"
   :show-warning="true"
   ref="safetyQuestions"
   @loadingStart="loading=true"
   @loadingEnd="loading=false"
 />
</template>

<script>
import { mapGetters } from 'vuex'
import SafetyQuestions from '@components/appointment/SafetyQuestions.vue';

export default {
  name: 'AppointmentCarouselSafetyQuestions',
  components: { SafetyQuestions },
  props: {},
  data () {
    return {
      questions: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters('app', { currentPatient: 'currentUser' }),
    ...mapGetters('appointment', { appointment: 'appointment' }),
    patientId () {
      return this.currentPatient && this.currentPatient.id ? this.currentPatient.id : ''
    },
    saveRoute () {
      return `patients/${ this.patientId }/appointments/${ this.appointment.id }/safety-questions`
    },
    fetchRoute () {
      return `patients/${ this.patientId }/appointments/${ this.appointment.id }/safety-questions`
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // TODO this would need to be moved to the parent component
      this.$store.commit('app/setAppBarTitle', 'Safety Questions')
      this.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          this.$emit('prev')
        }
      })
      this.$store.commit('app/setAppBarButton2', {
        label: 'Next',
        icon: 'mdi-chevron-right',
        click: () => {
          if (this.loading) {
            return
          }
          if (process.env.NODE_ENV !== 'production') {
            this.$emit('next')
          }
          const missingQuestion = this.questions.findIndex(el => el.isMandatory && !el.answer)

          if (missingQuestion > -1) {
            this.$_notify.error('Please answer all required safety questions marked with *')
            this.$refs.safetyQuestions.goTo(missingQuestion)

            return
          }
          this.$emit('next')
        }
      })
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')
    },
  },
  watch: {}
}
</script>
