





























































import { computed, defineComponent, ref, getCurrentInstance } from '@vue/composition-api'
import useCommonHelpers from '../../helpers/useCommonHelpers'

export default defineComponent({
  name: 'UserProfile',

  setup () {
    const { getCurrentPatient, logout } = useCommonHelpers()
    const menu = ref(false)
    const user = computed(() => {
      return getCurrentPatient()
    })

    const userFullName = computed(() => {
      return [user.value.firstName, user.value.lastName].join(' ')
    })

    const userLogout = () => {
      logout()
    }

    return {
      menu,
      userFullName,
      user,
      userLogout
    }
  }
})
