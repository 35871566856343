export const gridColumns = {
  appointmentPractices: [
    'appointmentStatusName',
    'patientNumber1',
    'patientFullName',
    'patientDateOfBirth',
    'procedureName',
    'insurerName',
    'clinicNameShort',
    'appointmentDate',
    'startTime',
    'cancelReason'
  ],
  alerts: [
    'alertStatusName',
    'isAppointmentCancelRequested',
    'alertTypeName',
    'createdDatetime',
    'alertBody',
    'appointmentId',
    'patient',
    'sex',
    'dateOfBirth',
    'procedureName',
    'appointmentProcedureStatusName',
    'clinicName',
    'appointmentDate',
    'startTime'
  ],
  appointments: [
    'appointmentStatusName',
    'appointmentId',
    'procedureName',
    'clinicName',
    'appointmentDate',
    'startTime'
  ],
  episodes:[
    'patientEpisodeStatusName', 'episodeAppointmentCategoryName', 'currentHospitalLocationPointOfCare','patientId', 'patientEpisodeNumber','referringPractitionerFullName', 'consultingPractitionerFullName', 'event', 'createdDatetime'
  ]
};
