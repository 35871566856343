export default [
  {
    value: 'appointmentId',
    text: 'Appointment ID',
    align: 'left',
    width: 140,
    visible:true
  },
  {
    value: 'patientId',
    text: 'Patient ID',
    align: 'left',
    width: 140,
    visible:false
  },
  {
    value: 'organizationId',
    text: 'Organization Id',
    align: 'left',
    visible:false
  },
  {
    value: 'patientIdHis',
    text: 'MRN',
    align: 'left',
    width: 140,
    visible:false
  },
  {
    value: 'appointmentStatusCode',
    text: 'Appointment Status Code',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingForceReadOnly',
    text: 'Reporting Force Read Only',
    align: 'left',
    visible:false
  },
  {
    value: 'isScheduled',
    text: 'Is Scheduled',
    align: 'left',
    visible:false
  },
  {
    value: 'isFinished',
    text: 'Is Finished',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingIsReportApproved',
    text: 'Reporting Is Report Approved',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentStatusName',
    text: 'Appointment Status',
    align: 'left',
    width: 200,
    visible:true
  },
  {
    value: 'appointmentStatusColorCode',
    text: 'Appointment Status Color Code',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProtocollingStatusCode',
    text: 'Appointment Protocolling Status Code',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentStatusCode',
    text: 'Appointment Document Status Code',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentStatusName',
    text: 'Appointment Document Status Name',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentStatusColorCode',
    text: 'Appointment Document Status Color Code',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProtocollingStatusName',
    text: 'Vetting Status',
    align: 'left',
    width: 160,
    visible:false
  },
  {
    value: 'appointmentProtocollingStatusColorCode',
    text: 'Appointment Protocolling Status Color Code',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentSourceId',
    text: 'Appointment Source Id',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentSourceName',
    text: 'Appointment Source Name',
    align: 'left',
    visible:false
  },
  {
    value: 'allowRemoveProcedure',
    text: 'Allow Remove Procedure',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentSourceColorCode',
    text: 'Appointment Source Color Code',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDate',
    text: 'Appointment Date',
    align: 'left',
    width: 150,
    visible:true
  },
  {
    value: 'appointmentComments',
    text: 'Appointment Comments',
    align: 'left',
    visible:false
  },
  {
    value: 'isConfirmedVerbally',
    text: 'Is Confirmed Verbally',
    align: 'left',
    visible:false
  },
  {
    value: 'practitionerPracticeId',
    text: 'Practitioner Practice Id',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureId',
    text: 'Appointment Procedure Id',
    align: 'left',
    visible:false
  },
  {
    value: 'sfAppointmentProcedureIdHisGet',
    text: 'Sf Appointment Procedure Id His Get',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureOption',
    text: 'Appointment Procedure Option',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureResultJson',
    text: 'Appointment Procedure Result Json',
    align: 'left',
    visible:false
  },
  {
    value: 'excludeFromInsurerQueue',
    text: 'Exclude From Insurer Queue',
    align: 'left',
    visible:false
  },
  {
    value: 'procedureOptions',
    text: 'Procedure Options',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureIdRis',
    text: 'Appointment Procedure Id Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureIdExternal',
    text: 'Appointment Procedure Id External',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureIdExternalSourceDomain',
    text: 'Appointmnt Procedure Id External Source Domain',
    align: 'left',
    visible:false
  },
  {
    value: 'procedureId',
    text: 'Procedure Id',
    align: 'left',
    visible:false
  },
  {
    value: 'scanId',
    text: 'Scan Id',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentId',
    text: 'Appointment Document Id',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureStatusCode',
    text: 'Appointment Procedure Status Code',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureStatusName',
    text: 'Appointment Procedure Status Name',
    align: 'left',
    visible:false
  },
  {
    value: 'conditionUserColumn',
    text: 'Condition User Column',
    align: 'left',
    visible:false
  },
  {
    value: 'schedulingForceReadOnly',
    text: 'Scheduling Force Read Only',
    align: 'left',
    visible:false
  },
  {
    value: 'startTime',
    text: 'Scheduled Time',
    align: 'left',
    width: 140,
    visible:true
  },
  {
    value: 'endTime',
    text: 'End Time',
    align: 'left',
    visible:false
  },
  {
    value: 'duration',
    text: 'Duration',
    align: 'left',
    width: 100,
    visible:false
  },
  {
    value: 'durationText',
    text: 'Duration Text',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedurePrice',
    text: 'Appointment Procedure Price',
    align: 'left',
    visible:false
  },
  {
    value: 'doseKv',
    text: 'Dose Kv',
    align: 'left',
    visible:false
  },
  {
    value: 'doseMa',
    text: 'Dose Ma',
    align: 'left',
    visible:false
  },
  {
    value: 'doseDistance',
    text: 'Dose Distance',
    align: 'left',
    visible:false
  },
  {
    value: 'doseDpl',
    text: 'Dose Dpl',
    align: 'left',
    visible:false
  },
  {
    value: 'numberOfExposures',
    text: 'Number Of Exposures',
    align: 'left',
    visible:false
  },
  {
    value: 'doseGfr',
    text: 'Dose Gfr',
    align: 'left',
    visible:false
  },
  {
    value: 'durationProtocolling',
    text: 'Duration Protocolling',
    align: 'left',
    visible:false
  },
  {
    value: 'createdDatetime',
    text: 'Created',
    align: 'left',
    width: 140,
    visible:false
  },
  {
    value: 'createdByUserId',
    text: 'Created By User Id',
    align: 'left',
    visible:false
  },
  {
    value: 'removedDatetime',
    text: 'Removed Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'removedByUserId',
    text: 'Removed By User Id',
    align: 'left',
    visible:false
  },
  {
    value: 'removedByUserFullName',
    text: 'Removed By User Full Name',
    align: 'left',
    visible:false
  },
  {
    value: 'removalNote',
    text: 'Removal Note',
    align: 'left',
    visible:false
  },
  {
    value: 'procedureNameGeneric',
    text: 'Procedure Name Generic',
    align: 'left',
    visible:false
  },
  {
    value: 'procedureName',
    text: 'Procedure',
    align: 'left',
    width: 200,
    visible:true
  },
  {
    value: 'importProcedureName',
    text: 'Import Procedure Name',
    align: 'left',
    visible:false
  },
  {
    value: 'importReportingRadiologistFullName',
    text: 'Import Reporting Radiologist Full Name',
    align: 'left',
    visible:false
  },
  {
    value: 'bodyPartId',
    text: 'Body Part Id',
    align: 'left',
    visible:false
  },
  {
    value: 'bodySubPartId',
    text: 'Body Sub Part Id',
    align: 'left',
    visible:false
  },
  {
    value: 'isGeneric',
    text: 'Is Generic',
    align: 'left',
    visible:false
  },
  {
    value: 'isApplicableForExaminationWorkflow',
    text: 'Is Applicable For Examination Workflow',
    align: 'left',
    visible:false
  },
  {
    value: 'isApplicableForReportingWorkflow',
    text: 'Is Applicable For Reporting Workflow',
    align: 'left',
    visible:false
  },
  {
    value: 'procedureCodeRis',
    text: 'Procedure Code Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicId',
    text: 'Clinic Id',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicCodeRis',
    text: 'Clinic Code Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicFacilityCodeRis',
    text: 'Clinic Facility Code Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicName',
    text: 'Clinic',
    align: 'left',
    width: 150,
    visible:true
  },
  {
    value: 'clinicNameShort',
    text: 'Clinic Name Short',
    align: 'left',
    visible:false
  },
  {
    value: 'insurerId',
    text: 'Insurer Id',
    align: 'left',
    visible:false
  },
  {
    value: 'insurerIdRis',
    text: 'Insurer Id Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'insurerCodeRis',
    text: 'Insurer Code Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'insurerName',
    text: 'Insurer Name',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureInsurerId',
    text: 'Appointment Procedure Insurer Id',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureInsurerIdRis',
    text: 'Appointment Procedure Insurer Id Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureInsurerCodeRis',
    text: 'Appointment Procedure Insurer Code Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureInsurerName',
    text: 'Appointment Procedure Insurer Name',
    align: 'left',
    visible:false
  },
  {
    value: 'insurerClinicCodeExternal',
    text: 'Insurer Clinic Code External',
    align: 'left',
    visible:false
  },
  {
    value: 'title',
    text: 'Title',
    align: 'left',
    visible:false
  },
  {
    value: 'firstName',
    text: 'First Name',
    align: 'left',
    visible:false
  },
  {
    value: 'lastName',
    text: 'Last Name',
    align: 'left',
    visible:false
  },
  {
    value: 'motherMaidenName',
    text: 'Mother Maiden Name',
    align: 'left',
    visible:false
  },
  {
    value: 'sex',
    text: 'Sex',
    align: 'left',
    width: 90,
    visible:false
  },
  {
    value: 'dateOfBirth',
    text: 'Date Of Birth',
    align: 'left',
    width: 150,
    visible:false
  },
  {
    value: 'patientNumber1',
    text: 'NHS No',
    align: 'left',
    visible:false
  },
  {
    value: 'address1',
    text: 'Address 1',
    align: 'left',
    visible:false
  },
  {
    value: 'address2',
    text: 'Address 2',
    align: 'left',
    visible:false
  },
  {
    value: 'address3',
    text: 'Address 3',
    align: 'left',
    visible:false
  },
  {
    value: 'town',
    text: 'Town',
    align: 'left',
    visible:false
  },
  {
    value: 'postCode',
    text: 'Post Code',
    align: 'left',
    visible:false
  },
  {
    value: 'countryCode',
    text: 'Country Code',
    align: 'left',
    visible:false
  },
  {
    value: 'phoneNumber1',
    text: 'Phone Number 1',
    align: 'left',
    visible:false
  },
  {
    value: 'phoneNumber2',
    text: 'Phone Number 2',
    align: 'left',
    visible:false
  },
  {
    value: 'mobileNumber1',
    text: 'Mobile Number 1',
    align: 'left',
    visible:false
  },
  {
    value: 'countryName',
    text: 'Country Name',
    align: 'left',
    visible:false
  },
  {
    value: 'patientStatusCode',
    text: 'Patient Status Code',
    align: 'left',
    visible:false
  },
  {
    value: 'patientStatusName',
    text: 'Patient Status Name',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicScanRoomCode',
    text: 'Clinic Scan Room Code',
    align: 'left',
    visible:false
  },
  {
    value: 'roomConfigJson',
    text: 'Room Config Json',
    align: 'left',
    visible:false
  },
  {
    value: 'removeFromMwlOnExaminationFinish',
    text: 'Remove From Mwl On Examination Finish',
    align: 'left',
    visible:false
  },
  {
    value: 'pacsIntegrationCode',
    text: 'Pacs Integration Code',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicScanRoomId',
    text: 'Clinic Scan Room Id',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicScanRoomTemporaryIdCsv',
    text: 'Clinic Scan Room Temporary Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'emailAddress1',
    text: 'Email Address 1',
    align: 'left',
    visible:false
  },
  {
    value: 'custom6',
    text: 'Custom 6',
    align: 'left',
    visible:false
  },
  {
    value: 'custom7',
    text: 'Custom 7',
    align: 'left',
    visible:false
  },
  {
    value: 'custom8',
    text: 'Custom 8',
    align: 'left',
    visible:false
  },
  {
    value: 'isStudyImport',
    text: 'Is Study Import',
    align: 'left',
    visible:false
  },
  {
    value: 'practitionerPracticeCodeRis',
    text: 'Practitioner Practice Code Ris',
    align: 'left',
    visible:false
  },
  {
    value: 'practitionerPracticeName',
    text: 'Practitioner Practice Name',
    align: 'left',
    visible:false
  },
  {
    value: 'practitionerFullName',
    text: 'Practitioner Full Name',
    align: 'left',
    visible:false
  },
  {
    value: 'importPractitionerFullName',
    text: 'Import Practitioner Full Name',
    align: 'left',
    visible:false
  },
  {
    value: 'importPractitionerPracticeName',
    text: 'Import Practitioner Practice Name',
    align: 'left',
    visible:false
  },
  {
    value: 'enableOptimization',
    text: 'Enable Optimization',
    align: 'left',
    visible:false
  },
  {
    value: 'optimizationId',
    text: 'Optimization Id',
    align: 'left',
    visible:false
  },
  {
    value: 'optimizationDescription',
    text: 'Optimization Description',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessId',
    text: 'Illness Id',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessTypeId',
    text: 'Illness Type Id',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessCode',
    text: 'Illness Code',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessName',
    text: 'Illness Name',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessId2',
    text: 'Illness Id 2',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessTypeId2',
    text: 'Illness Type Id 2',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessCode2',
    text: 'Illness Code 2',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessName2',
    text: 'Illness Name 2',
    align: 'left',
    visible:false
  },
  {
    value: 'illnessDataJson',
    text: 'Illness Data Json',
    align: 'left',
    visible:false
  },
  {
    value: 'examinationUserId',
    text: 'Examination User Id',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingUserId',
    text: 'Reporting User Id',
    align: 'left',
    visible:false
  },
  {
    value: 'secretaryUserId',
    text: 'Secretary User Id',
    align: 'left',
    visible:false
  },
  {
    value: 'performingUserIdCsv',
    text: 'Performing User Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'performingUserCircleIdCsv',
    text: 'Performing User Circle Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'examinationUserIdCsv',
    text: 'Examination User Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'secretaryUserIdCsv',
    text: 'Secretary User Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingUserIdCsv',
    text: 'Reporting User Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'examinationStartedDatetime',
    text: 'Examination Started Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'examinationFinishedDatetime',
    text: 'Examination Finished Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingStartedDatetime',
    text: 'Reporting Started Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingForDictationSentDatetime',
    text: 'Reporting For Dictation Sent Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingDictationStartedDatetime',
    text: 'Reporting Dictation Started Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingApprovalRequestedDatetime',
    text: 'Reporting Approval Requested Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingSecondOpinionRequestedDatetime',
    text: 'Reporting Second Opinion Requested Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingApprovedDatetime',
    text: 'Reporting Approved Datetime',
    align: 'left',
    visible:false
  },
  {
    value: 'examinationUserCircleIdCsv',
    text: 'Examination User Circle Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'secretaryUserCircleIdCsv',
    text: 'Secretary User Circle Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'reportingUserCircleIdCsv',
    text: 'Reporting User Circle Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureResultJsonStatusCode',
    text: 'Appointment Procedure Result Json Status Code',
    align: 'left',
    visible:false
  },
  {
    value: 'isTeleradiology',
    text: 'Is Teleradiology',
    align: 'left',
    visible:false
  },
  {
    value: 'isReportAvailableInClinic',
    text: 'Is Report Available In Clinic',
    align: 'left',
    visible:false
  },
  {
    value: 'isBeingAmended',
    text: 'Is Being Amended',
    align: 'left',
    visible:false
  },
  {
    value: 'requireReview',
    text: 'Require Review',
    align: 'left',
    visible:false
  },
  {
    value: 'requireReviewNote',
    text: 'Require Review Note',
    align: 'left',
    visible:false
  },
  {
    value: 'requireReviewRequestedBy',
    text: 'Require Review Requested By',
    align: 'left',
    visible:false
  },
  {
    value: 'supervisingUserId',
    text: 'Supervising User Id',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureCustom1',
    text: 'Appointment Procedure Custom 1',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureCustom2',
    text: 'Appointment Procedure Custom 2',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureCustom3',
    text: 'Appointment Procedure Custom 3',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureCustom4',
    text: 'Appointment Procedure Custom 4',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentIssueDate',
    text: 'Appointment Document Issue Date',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentValidityDate',
    text: 'Appointment Document Validity Date',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentValidityPeriod',
    text: 'Appointment Document Validity Period',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentSerialNumber',
    text: 'Appointment Document Serial Number',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureResultJsonStatusName',
    text: 'Appointment Procedure Result Json Status Name',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProcedureResultJsonStatusColorCode',
    text: 'Appointment Procedure Result Json Status Color Code',
    align: 'left',
    visible:false
  },
  {
    value: 'structuredReportTemplateId',
    text: 'Structured Report Template Id',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentDeliveredDate',
    text: 'Appointment Document Delivered Date',
    align: 'left',
    visible:false
  },
  {
    value: 'radiationDoseDataJson',
    text: 'Radiation Dose Data Json',
    align: 'left',
    visible:false
  },
  {
    value: 'secondOpinionReportingUserId',
    text: 'Second Opinion Reporting User Id',
    align: 'left',
    visible:false
  },
  {
    value: 'patientArrivalStatusCode',
    text: 'Patient Arrival Status Code',
    align: 'left',
    visible:false
  },
  {
    value: 'secondOpinionReportingUserIdCsv',
    text: 'Second Opinion Reporting User Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'custom30',
    text: 'Custom 30',
    align: 'left',
    visible:false
  },
  {
    value: 'custom31',
    text: 'Custom 31',
    align: 'left',
    visible:false
  },
  {
    value: 'examinationFullName',
    text: 'Examination Full Name',
    align: 'left',
    visible:false
  },
  {
    value: 'registrationFullName',
    text: 'Registration Full Name',
    align: 'left',
    visible:false
  },
  {
    value: 'addendumUserIdCsv',
    text: 'Addendum User Id Csv',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicScanRoomDescription',
    text: 'Clinic Scan Room Description',
    align: 'left',
    visible:false
  },
  {
    value: 'unmannedProcessingJson',
    text: 'Unmanned Processing Json',
    align: 'left',
    visible:false
  },
  {
    value: 'emrWeight',
    text: 'Emr Weight',
    align: 'left',
    visible:false
  },
  {
    value: 'Is Cancel',
    text: 'Is Cancel',
    align: 'left',
    visible:false
  },
  {
    value: 'patient',
    text: 'Patient',
    align: 'left',
    width: 200,
    visible:false
  }
]
