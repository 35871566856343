<template>
  <simple-layout>
    <v-card flat :loading="loading">
      <v-card-text>
        <v-row>
          <v-col sm="12" lg="6" offset-lg="3" class="pa-5" v-for="apt in upcomingAppointments" v-bind:key="apt.id">
            <AppointmentCard :appointment="apt" :upcoming="true"></AppointmentCard>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </simple-layout>

</template>

<script>
import SimpleLayout from '@router/layouts/simple.vue'
import AppointmentCard from '@components/appointment/cards/CancellationCard.vue'

export default {
  name: 'AppointmentGrid',
  components: {
    SimpleLayout,
    AppointmentCard
  },
  data () {
    return {
      tab: null,
      upcomingAppointments: [],
      pastAppointments: [],
      loading: false,
      appointmentCount: 0
    }
  },
  methods: {
    getData () {
      this.loading = true
      this.$_rest.get(`/patients/${this.$store.getters['app/currentUserId']}/appointments?groups=all,appointment_appointment_procedures,appointment_clinic,appointment,appointment_procedure_procedure,appointment_status,appointment_procedure_parent_appointment_procedure&orderBy[date]=desc`, {}, response => {
        response.data.map((apt) => {
          let skip = false;
          if (apt.appointmentProcedures && Array.isArray(apt.appointmentProcedures)) {
            apt.appointmentProcedures.map((ap) => {
              if (ap.parentAppointmentProcedure) {
                this.appointmentCount++;
                skip = true;
                return;
              }
            })
          }

          if (skip) {
            return;
          }

          this.upcomingAppointments.push(apt)
          this.appointmentCount++;
          this.loading = false
        })
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },
  },
  mounted () {
    this.getData()
  }
}
</script>
