<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-autocomplete
          v-model="model"
          :items="items"
          :loading="loading"
          :search-input.sync="search"
          item-text="name"
          item-value="code"
          :label="$_t('Search an examination')"
          :placeholder="$_t('Start typing to search')"
          prepend-inner-icon="mdi-account-search"
          return-object
          clearable
          @keydown="isTyping = true"
          @blur="isTyping = false"
        >
          <template v-slot:no-data>
            <v-list-item v-if="loading">
              Loading...
            </v-list-item>
            <v-list-item v-else-if="isTyping">
              ...
            </v-list-item>
            <v-list-item v-else-if="search && (!items || !items.length)">
              No examinations found
            </v-list-item>
            <v-list-item v-else>
              Start typing to search for examinations
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="`${item.scan && item.scan.nameShort} ${item.name}`"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent, ref, watch,computed, getCurrentInstance } from '@vue/composition-api'
export default defineComponent({
  name: 'ProcedureSearch',
  props: {
    value:{type:Object,default:()=>{}},
    fetchRoute: String,
    scanId: {type: String,default:null}
  },
  setup(props, {emit}) {
    const _vm = getCurrentInstance()
    const items = ref([])
    const search = ref(null)
    const loading = ref(false)
    const isTyping = ref(false)
    let searchTimeout;
    const model = computed({
      get: () => props.value,
      set: newVal => {
        emit('input', newVal)
      }
    })

    const setPatientStore = (res) => {
      _vm.proxy.$store.dispatch('app/setCurrentOrganization', res.data.organization)
      _vm.proxy.$store.dispatch('app/setCurrentPatient', res.data)
      loading.value = false
    }

    const errorPatientEndpoint = () => {
      _vm.proxy.errorMessage = 'Sorry, we couldn\'t retrieve your details.'
      loading.value = false
    }

    const getScanId = computed(()=>{
      if(props.scanId) {
        return {scanId:props.scanId}
      }
      return {}
    })

    watch(search, val => {
      val = (val || '').trim()
      if (val.length < 2) {
        items.value = []
        return
      }
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        const paramsObject = {
           search: search.value,
           ...getScanId.value

        }
        isTyping.value = false
        loading.value = true
        _vm.proxy.$_rest.get(props.fetchRoute, { groups:'all,procedure_scan', ...paramsObject  }, procedures => {
          loading.value = false
          items.value = procedures.data
        }, error => {
          //TODO implement show notifications
        })
      }, 400)
    })

    return {
      items,
      model,
      search,
      loading,
      isTyping
    }
  }
})
</script>
<style scoped></style>
