import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
// @ts-ignore
import NProgress from 'nprogress/nprogress'
// import store from '@state/store'
// @ts-ignore
import auth from '@helpers/auth'
import RestApi from '../plugins/rest-api'
// @ts-ignore
import store from '@state/store'
// @ts-ignore
import routes from './routes'
import config from '../config'
import { base62 } from '../helpers/utils'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // @ts-ignore
  return originalPush.call(this, location).catch(error => {
    if (error.name !== 'NavigationDuplicated') {
      throw error
    }
  })
}

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page'
})
Vue.use(RestApi, {
  config: config,
  store: store,
  prefixRoutesWithOrganizationId: true
})
const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// Before each route evaluates...
router
  .beforeEach((to, from, next) => {
    sessionStorage.removeItem('authType')
    if (to.name === 'Auth') {
      router.app.$store.dispatch('app/setAuthTokenExchangeString', to.query)
      const lastToRoute = sessionStorage.getItem('lastToRoute')
      next(lastToRoute || '/')
    } else if (to.name === 'Default') {
      const lastToRoute = sessionStorage.getItem('lastToRoute')
      next(lastToRoute || '/')
    } else if (to.name === 'SMS Appointment') {
      auth.clearAuthLocalStorage()
      const appointmentId = to.params.appointmentId
      sessionStorage.setItem('patientUsername', appointmentId)
      next({ path: `/appointment/${ appointmentId }` })
    } else if (auth.isPublicRoute(to.name)) {
      next()
    } else if(to.name === 'AppointmentQuestionnaire') {
      sessionStorage.setItem('lastToRoute', to.path)
      if (config.authBasicEndpoint) {
        sessionStorage.setItem('authType', 'basic')
      }
      next()
    } else if(to.name === 'Payment') {
      const paymentAppointmentId = sessionStorage.getItem('paymentAppointmentId')
      if (paymentAppointmentId) {
        next({ path: `/appointment/${ paymentAppointmentId }/payment` })
      } else {
        next({ path: '/'})
      }
    } else {
      sessionStorage.setItem('lastToRoute', to.path)
      if (!router.app.$store.getters['app/authTokenExchangeString'] && !auth.verifySavedToken()) {
        auth.clearAuthLocalStorage()
        auth.redirectToAuth()
        return
      }
      next()
    }
    return
  })

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args: any[]) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((/* routeTo, routeFrom */) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
