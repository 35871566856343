<template>
  <v-container>
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
    >
      <v-col cols="12">
        <h6 class="text-h5">
          {{ $_t('Thank you for completing the questionnaires') }}
        </h6>
        <div class="text-subtitle-1">
          {{ $_t('You have previously received a confirmation email which contains a summary of the requirements for your exam including times and other appointment related information.') }}
        </div>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1">
          {{ $_t('Your appointment details are below.') }}
          <br>{{ $_t('Please note that you are required to arrive in the clinic ahead of your appointment time. The exact required arrival time was indicated in the confirmation email and will also be stated in the reminder SMS message.') }}
        </div>
        <div class="text-subtitle-1 red--text" v-if="$_t('Please note that all patients are required to wear a face mask at all times while in the clinic. Refusal to do so will result in the procedure being cancelled.')">
          {{ $_t('Please note that all patients are required to wear a face mask at all times while in the clinic. Refusal to do so will result in the procedure being cancelled.') }}
        </div>
        <div>
          <appointment-procedures-card
            v-if="appointment"
            :appointment="appointment"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed, onMounted } from '@vue/composition-api'
import AppointmentProceduresCard from '@components/appointment/cards/AppointmentProceduresCard.vue'

export default defineComponent({
  name: 'AppointmentThankYou',
  components: { AppointmentProceduresCard },
  props: {
    options: { type: Object, default: {} }
  },
  setup (props, ctx) {
    const getOptions = computed(() => {
      return props.options
    })

    const appointment = computed(() => ctx.root.$store.getters['appointment/appointment'])
    const nextPage = (page) => {
      ctx.emit('page', page)
    }

    const init = () => {
      ctx.root.$store.commit('app/setAppBarTitle', 'Thank you')
      ctx.root.$store.commit('app/setAppBarButton1', null)
      ctx.root.$store.commit('app/setAppBarButton2', null)
      ctx.root.$store.dispatch('app/appBarShow')
      ctx.root.$emit('scrollToTop')
    }

    init()

    return {
      getOptions,
      nextPage,
      init,
      appointment
    }
  }
})
</script>
