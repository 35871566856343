<template>
  <patient-layout :title="$_t('Settings')">
    <v-card flat>
      <v-card-text>
        <h2>{{$_t('Change password')}}</h2><br/>
        <v-row justify="center">
          <v-col cols="12" md="4" class="pa-4">
            <v-form
              @submit.prevent="submitForm"
              v-model="passwordFormValid"
              lazy-validation
              ref="form"
            >
              <v-text-field
                :rules="[ v => !!v || $_t('Required') ]"
                v-model="currentPassword"
                name="currentPassword"
                :label="$_t('Current password')"
                color="primary"
                outlined
                autocomplete="none"
                type="password"
              ></v-text-field>

              <v-text-field
                :rules="[
                  v => !!v || $_t('Required'),
                  v => v && v.match(passwordRegex) !== null || $_t('Password should be at least 8 characters long and have at least one of each: lower-case & upper-case letter, a number and a special character OR be at least 11 characters in length'),
                  v => v !== currentPassword || $_t('New password cannot be the same as the old password')
                ]"
                v-model="newPassword"
                name="newPassword"
                :label="$_t('New password')"
                color="primary"
                outlined
                autocomplete="none"
                type="password"
              ></v-text-field>

              <v-text-field
                :rules="[
                  v => !!v || $_t('Required'),
                  v => v === newPassword || $_t('Passwords do not match'),
                 ]"
                v-model="confirmNewPassword"
                name="confirmNewPassword"
                :label="$_t('Re-type new password')"
                color="primary"
                outlined
                autocomplete="none"
                type="password"
              ></v-text-field>
              <v-alert color="red" type="error" v-if="!passwordFormValid" class="mt-2">
                {{ $_t('Correct errors before continuing') }}
              </v-alert>
              <v-btn type="submit" color="primary" style="width:100%" :disabled="!passwordFormValid || !currentPassword || !newPassword || !confirmNewPassword || loading">
                {{$_t('Save')}}
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </patient-layout>
</template>

<script>
import PatientLayout from '@router/layouts/patient.vue'
import config from '@config'

export default {
  name: 'PatientSettings',
  components: {
    PatientLayout
  },
  data () {
    return {
      passwordFormValid: null,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_+=/\\}{:;'()~`[\]|?.>,<])(?=.{8,})|(?=.{11,})/,
      loading: false
    }
  },
  mounted () {
  },
  computed: {},
  methods: {
    submitForm() {
      this.loading = true
      //auth request
      fetch(config.authEndpoint + '/password/change/session_user', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          current_password: this.currentPassword,
          new_password: this.newPassword
        })
      }).then(resp => {
        this.loading = false
        if (resp.status === 403) {
          this.$_notify.error(this.$_t('Current password is incorrect'));
        } else if(resp.status === 200) {
          this.$_notify.success(this.$_t('Password changed successfully'));
          this.$refs.form.reset();
        } else {
          this.$_notify.error(this.$_t('Something went wrong, re-login and try again'));
        }
      })
    }
  },
  watch: {
  }
}
</script>
