<template>
  <div scroll>
    <div class="direction-button-wrapper">
      <v-btn
        class="direction-button left rounded-circle"
        @click="onPrev"
        fab
        dark
        color="white"
        :disabled="directionButtonLeftDisabled"
        ><v-icon dark color="black">
          mdi-chevron-left
        </v-icon></v-btn>

      <v-btn
        class="direction-button right rounded-circle"
        @click="onNext"
        fab
        dark
        color="white"
        ><v-icon dark color="black">
          mdi-chevron-right
        </v-icon></v-btn>
    </div>
    <v-sheet color="primary" elevation="1" class="slots-sheet pl-8 pr-8">
        <v-row class="flex-nowrap">
          <v-col v-for="day in days" :key="day.dayIndex">
            <div class="slots-column d-flex flex-column">
              <p class="mb-0 subheading text-center white--text">
                {{ day.monthNameShort }}
              </p>
              <p class="mb-0 font-weight-bold headline text-center white--text">
                {{ day.dayIndex }}
              </p>
              <p class="mb-4 body-1 text-center white--text">
                {{ day.dayNameShort }}
              </p>

              <v-btn
                v-for="slot in slots[day.dateSQL]"
                :key="slot"
                color="black--text"
                class="slot ma-2"
                >{{ slot }}</v-btn>
            </div>
          </v-col>
        </v-row>

        <p class="ma-2 text-center">
          <label class="white--text">Ostatnia aktualizacja: {{ lastUpdateTime }}</label>
          <v-btn color="black--text" class="slot ma-2" @click="getSlots()">Odśwież</v-btn>
        </p>
        <v-row>
            <v-col cols="6" offset="3">
            <v-checkbox color="white" class="white--text" v-model="autoRefreshEnabled">
              <span slot="label" class="white--text">Automatyczne odświeżanie</span>
            </v-checkbox>

            <v-slider hide-details thumb-label="always" v-if="autoRefreshEnabled" min="1" max="60" color="white" v-model="autoRefreshValue" thumb-color="secondary" :thumb-size="20">
              <span slot="label" class="white--text">Interwał odświeżania</span>
            </v-slider>
            </v-col>
        </v-row>
    </v-sheet>
    <v-progress-linear
      v-show="slotsLoading"
      color="primary accent-4"
      indeterminate
      rounded
    ></v-progress-linear>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/es';
import { mapGetters } from 'vuex';

moment.locale('pl');

export default {
  props: ['slotsParams', 'practitionerId', 'organizationId'],
  name: 'Slots',
  data() {
    return {
      slots: [],
      slotsLoading: false,
      startOfWeek: moment().startOf('week'),
      endOfWeek: moment().endOf('week'),
      directionButtonLeftDisabled: true,
      lastUpdateTime: null,
      autoRefresh: false,
      autoRefreshEnabled: false,
      autoRefreshValue: 1
    };
  },
  created () {
    this.initAutoRefresh()
  },
  mounted() {
    this.enableDiableDirectionButtons()
  },
  computed: {
    ...mapGetters('practitioner', [
      'getPractitionerScheduleClinicSlotsPerScan'
    ]),
    ...mapGetters('app', [
      'practitioner'
    ]),
    ...mapGetters('app', [
      'organization'
    ]),
    days() {
      const days = [];
      const startOfWeek = this.startOfWeek.clone();
      const endOfWeek = this.endOfWeek.clone();

      do {
        days.push({
          dateSQL: startOfWeek.format('YYYY-MM-DD'),
          monthNameShort: startOfWeek.format('MMM'),
          dayNameShort: startOfWeek.format('ddd'),
          dayIndex: startOfWeek.format('DD')
        });
      } while (startOfWeek.add(1, 'days').diff(endOfWeek) <= 0);

      return days;
    }
  },
  watch: {
    slotsParams(slotsParams) {
      if (slotsParams) {
        this.getSlots();
      }
    },
    getPractitionerScheduleClinicSlotsPerScan(slotsDataset) {
      this.prepareSlots(slotsDataset);
      this.lastUpdateTime = moment().format('dddd, D MMMM HH:mm')
    },
    autoRefreshEnabled(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        localStorage.setItem('auto_refresh_enabled', 'true');
        this.setAutoRefresh()
      } else {
        localStorage.removeItem('auto_refresh_enabled');
      }
    },
    autoRefreshValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        localStorage.setItem('auto_refresh_interval', newVal);
        this.setAutoRefresh()
      }
    }
  },
  methods: {
    onPrev() {
      this.addWeekToWeekDates(-1);
      this.enableDiableDirectionButtons();
      this.getSlots();
    },
    onNext() {
      this.addWeekToWeekDates(1);
      this.enableDiableDirectionButtons();
      this.getSlots();
    },
    addWeekToWeekDates(howManyWeeks) {
      this.startOfWeek = this.startOfWeek.clone().add(howManyWeeks, 'week');
      this.endOfWeek = this.endOfWeek.clone().add(howManyWeeks, 'week');
    },
    getSlots() {
      this.slotsLoading = true;

      this.$store.dispatch(
        'practitioner/getPractitionerScheduleClinicSlotsPerScan',
        {
          practitionerId: this.practitionerId,
          organizationId: this.organizationId,
          clinicScanRoomId: this.slotsParams.clinicScanRoomId,
          procedureId: this.slotsParams.procedureId,
          slotsOnlyForAvailabilityCategoriesIdsCsv: this.slotsParams
            .slotsOnlyForAvailabilityCategoriesIdsCsv,
          startDateSql: this.startOfWeek.format('YYYY-MM-DD'),
          endDateSql: this.endOfWeek.format('YYYY-MM-DD')
        }
      );
    },
    prepareSlots(slotsDataset) {
      const indexAvailabilityDate = slotsDataset.meta.columns.findIndex(
        (p) => p === 'availabilityDate'
      );
      const indexAvailabilityStartTime = slotsDataset.meta.columns.findIndex(
        (p) => p === 'availabilityStartTime'
      );
      const indexSlotInterval = slotsDataset.meta.columns.findIndex(
        (p) => p === 'slotInterval'
      );
      const indexProcedureDuration = slotsDataset.meta.columns.findIndex(
        (p) => p === 'procedureDuration'
      );

      const slotsPerDay = [];

      for (const index in slotsDataset.data) {
        const slot = slotsDataset.data[index];
        const availabilityDate = slot[indexAvailabilityDate];
        const availabilityStartTime = moment(
          slot[indexAvailabilityStartTime],
          'HH:mm:ss'
        );
        const slotInterval = slot[indexSlotInterval];
        const procedureDuration = slot[indexProcedureDuration];
        const availabilityEndTime = availabilityStartTime
          .clone()
          .add(slotInterval, 'minutes')
          .add((-1)*procedureDuration, 'minutes');

        if (!slotsPerDay[availabilityDate]) {
          slotsPerDay[availabilityDate] = [];
        }

        do {
          slotsPerDay[availabilityDate].push(
            availabilityStartTime.format('HH:mm')
          );
        }
        while (
          availabilityStartTime
            .add(procedureDuration, 'minutes')
            .diff(availabilityEndTime) <= 0
        )
      }

      this.slots = slotsPerDay;
      this.slotsLoading = false;
    },
    enableDiableDirectionButtons() {
      if (moment().diff(this.startOfWeek) >= 0) {
        this.directionButtonLeftDisabled = true;
      } else {
        this.directionButtonLeftDisabled = false;
      }
    },
    setAutoRefresh() {
      if (this.autoRefresh) {
        clearInterval(this.autoRefresh)
      }
      this.autoRefresh = setInterval(() => {
        this.getSlots()
      }, this.autoRefreshValue * 60 * 1000)
    },
    initAutoRefresh() {
      const enabled = localStorage.getItem('auto_refresh_enabled')
      const interval = localStorage.getItem('auto_refresh_interval')
      if (enabled && enabled === 'true') {
        this.autoRefreshEnabled = true
      }
      if (interval) {
        this.autoRefreshValue = interval
      }
    }
  }
};
</script>

<style lang="scss">

@media screen and (max-width: 960px) {
    .slots-sheet {
      overflow-x: scroll;
  }
}

.slots-column {
  width: 85px;
}

.slot {
  align-self: center;
}

.direction-button-wrapper {
  position: relative;
}

.direction-button {
  position: absolute !important;
  &.left {
    top: 20px;
    left: -25px;
  }
  &.right {
    top: 20px;
    right: -25px;
  }
}

</style>
