import {AppInterface} from '@src/interfaces/AppInterface'
import Vue from 'vue'
export interface AppState {
  [key: string]: any;
}
const model: AppInterface = {
  drawer: false,
  settings: {
    minCheckinLettersMatch: 3
  },
  appCode: null,
  patient: null,
  practitioner: null,
  practitionerPractice: null,
  practitionerPractices: [],
  practitionerPracticesLoading: false,
  user: null,
  currentUser: null,
  organization: null,
  appBar: false,
  appBarTitle: '',
  appBarButton1: null,
  appBarButton2: null,
  appBarButton2Loading: false,
  authTokenExchangeString: null,
  patientBanner:false,
  refreshed: false,
  sortColumnsOnGrid: false
}

const state = {
  ...model
}

const getters = {
  getSortColumnsOnGrid:(state: AppState)=>{
    return state.sortColumnsOnGrid
  },
  getRefreshed:(state: AppState)=>{
    return state.refreshed
  },
  columnsAll:(state: AppState) =>{
    return state.currentUser.columns
  },
  columnsDefaultAll:(state: AppState) =>{
    return state.columnsDefault
  },
  columns:(state: AppState) => presetType =>{
    const columns = state.currentUser.columns[presetType]
    if(typeof columns !== 'undefined') {
      return columns
    }
    return []
  },
  roles(state: AppState) {
    return state.currentUser.roles
  },
  patientBanner(state: AppState) {
    return state.patientBanner
  },
  currentUser (state: AppState) {
    return state.currentUser
  },
  patient (state: AppState) {
    return state.patient
  },
  organization (state: AppState) {
    return state.organization
  },
  practitioner (state: AppState) {
    return state.practitioner
  },
  practitionerPractice (state: AppState) {
    return state.practitionerPractice
  },

  practitionerPractices (state: AppState) {
    return state.practitionerPractices
  },
  practitionerPracticesLoading (state: AppState) {
    return state.practitionerPracticesLoading
  },
  currentOrganizationId (state: AppState) {
    return state.currentUser && state.currentUser.organization && state.currentUser.organization.id
  },
  user (state: AppState) {
    return state.user
  },
  currentUserId (state: AppState) {
    return state.currentUser && state.currentUser.id
  },
  authTokenExchangeString (state: AppState) {
    return state.authTokenExchangeString
  }
}

const mutations = {
  setSortColumnsOnGrid(state: AppState,payload: AppInterface) {
    state.sortColumnsOnGrid = payload
  },
  setRefreshed(state: AppState,payload: AppInterface) {
    state.refreshed = payload
  },
  setColumns(state: AppState,payload: AppInterface){
    state.currentUser.columns = payload
  },
  setRoles(state: AppState,payload: AppInterface){
    state.currentUser.roles = payload
  },
  patientBannerShow(state: AppState,payload: AppInterface){
    state.patientBanner = payload
  },
  drawerShow (state: AppState) {
    state.drawer = true
  },
  setAuthTokenExchangeString (state: AppState, authTokenExchangeString: AppInterface) {
    state.authTokenExchangeString = authTokenExchangeString
  },
  drawerHide (state: AppState) {
    state.drawer = false
  },
  drawerToggle (state: AppState) {
    state.drawer = !state.drawer
  },
  drawerSet (state: AppState, payload: AppInterface) {
    state.drawer = payload
  },
  appCodeSet (state: AppState, payload: AppInterface) {
    state.appCode = payload
  },
  setPatient (state: AppState, patient: AppInterface) {
    state.patient = patient
  },
  setPractitioner (state: AppState, practitioner: AppInterface) {
    state.practitioner = practitioner
  },
  setPractitionerPractice (state: AppState, practitionerPractice: AppInterface) {
    state.practitionerPractice = practitionerPractice
  },
  setPractitionerPractices (state: AppState, practitionerPractices: AppInterface) {
    state.practitionerPractices = practitionerPractices
  },
  setPractitionerPracticesLoading (state: AppState, practitionerPracticesLoading: AppInterface) {
    state.practitionerPracticesLoading = practitionerPracticesLoading
  },
  setUser (state: AppState, user: AppInterface) {
    state.user = user
  },
  setCurrentUser (state: AppState, user: AppInterface) {
    state.currentUser = user
  },
  setOrganization (state: AppState, organization: AppInterface) {
    state.organization = organization
  },
  setAppBar (state: AppState, value: AppInterface) {
    state.appBar = value
  },
  setAppBarButton1 (state: AppState, button: AppInterface) {
    state.appBarButton1 = button
  },
  setAppBarButton2 (state: AppState, button: AppInterface) {
    state.appBarButton2 = button
  },
  setAppBarButton2Loading (state: AppState, loading: AppInterface) {
    state.appBarButton2Loading = loading
  },
  setAppBarTitle (state: AppState, title: AppInterface) {
    state.appBarTitle = title
  },
  setColumnsByPresetType (state: AppState, payload: any) {
    //state.currentUser.columns[payload.presetType] = payload.columns
   Vue.set(state.currentUser.columns, payload.presetType.toString(), payload.columns);
  }
}

const actions = {

  setColumnsByPresetType ({ commit }, columnsPreset: Record<string,any>) {
  return new Promise<void>((resolve) => {
      commit('setColumnsByPresetType', columnsPreset)
      resolve()
    })
  },
  setColumns ({ commit }, columns: Record<string,any>) {
    return new Promise<void>((resolve) => {
      commit('setColumns', columns)
    resolve()
    })
  },
  setRoles ({ commit }, roles: string[]) {
    commit('setRoles', roles)
  },
  appCodeSet ({ commit }, appCode: AppInterface) {
    commit('appCodeSet', appCode)
  },
  setAuthTokenExchangeString ({ commit }, authTokenExchangeString: AppInterface) {
    commit('setAuthTokenExchangeString', authTokenExchangeString)
  },
  setPatient ({ commit }, patient: AppInterface) {
    commit('setPatient', patient)
  },
  setPractitioner ({ commit }, practitioner: AppInterface) {
    return new Promise<void>((resolve) => {
      commit('setPractitioner', practitioner)
      resolve()
    })

  },
  setPractitionerPractice ({ commit, getters }, practitionerPractice: AppInterface) {
    commit('setPractitionerPractice', practitionerPractice)
    localStorage.setItem('currentPractitionerPractice_' + getters.currentUser.id, JSON.stringify(practitionerPractice))
  },
  setPractitionerPractices ({ commit }, practitionerPractices: AppInterface) {
    commit('setPractitionerPractices', practitionerPractices)
  },
  setUser ({ commit }, user: AppInterface) {
    commit('setUser', user)
  },
  setCurrentUser ({ commit }, user: AppInterface) {
    commit('setCurrentUser', user)
  },
  setOrganization ({ commit }, organization: AppInterface) {
    commit('setOrganization', organization)
  },
  appBarShow ({ commit }) {
    commit('setAppBar', true)
  },
  appBarHide ({ commit }) {
    commit('setAppBar', false)
    commit('setAppBarTitle', '')
    commit('setAppBarButton1', null)
    commit('setAppBarButton2', null)
    commit('setAppBarButton2Loading', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
