const state = {
  patient: null,
  appointmentCount: 0,
  appointmentFilteredCount: 0,
  appointmentLoading: false
}

const actions = {
  setPatient({commit}, patient) {
    commit('setPatient', patient)
    return true
  }
}

const mutations = {
  setPatient(state, patient) {
    state.patient = patient
  },
  setAppointmentCount(state, count) {
    state.appointmentCount = count
  },
  setAppointmentFilteredCount(state, count) {
    state.appointmentFilteredCount = count
  },
  setAppointmentLoading(state, loading) {
    state.appointmentLoading = loading
  }
}

const getters = {
  patient(state) {
    return state.patient
  },
  patientId(state) {
    return state.patient.id
  },
  patientFullName(state) {
    return state.patient.fullName
  },
  appointmentCount(state) {
    return state.appointmentCount
  },
  appointmentFilteredCount(state) {
    return state.appointmentFilteredCount
  },
  appointmentLoading(state) {
    return state.appointmentLoading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
