<template>
  <v-container
    class="pa-1"
    style="min-height: 460px;"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    />
    <div v-if="!loading">
      <v-expansion-panels
        v-model="panels"
      >
        <v-expansion-panel
          v-for="(form, index) in forms"
          :key="index"
        >
          <v-expansion-panel-header
            class="pa-2"
          >
            <v-row no-gutters>
              <v-col cols="12">
                {{ form.title }}
              </v-col>
              <v-col cols="12">
                <div class="text-subtitle-2">
                  {{ form.description }}
                </div>
              </v-col>
            </v-row>
            <template
              v-slot:actions
              v-if="!form.completed"
            >
              <v-icon color="primary">
                $expand
              </v-icon>
            </template>
            <template
              v-slot:actions
              v-if="form.completed && index !== panels"
            >
              <v-icon color="teal">
                mdi-check
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="px-0 mx-0"
          >
            <appointment-form
              :form="form"
              :hide-header="true"
              :save-route="saveRoute"
              :show-complete="true"
              @saved="saved(form, index)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import AppointmentForm from '@components/appointment/Forms.vue'

export default {
  name: 'AppointmentForms',
  components: { AppointmentForm },
  props: {
    appointment: {
      type: Object,
      default: () => {}
    },
    patient: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      forms: [{name: 'aaa', completed: false }, { name: 'bbb',
        completed: true }],
      panels: 0
    }
  },
  computed: {
    saveRoute () {
      return `patients/${ this.patient.id }/appointments/${this.appointment.id}/documents`
    }
  },
  methods: {
    saved (form, index) {
      form.completed = true
      if (index === this.forms.length - 1) {
        this.panels = null
      }
      else if (typeof this.forms[index + 1] !== 'undefined' && !this.forms[index + 1].completed) {
        this.panels = index + 1
      }
      this.$root.$emit('scrollToTop')
    },
    getUnanswered () {
      return this.form.sections.map(section => section.questions
        .map(question => (typeof question.visible === 'undefined' || question.visible) && question.required && !question.answer && question))
        .flat(2)
        .filter(el => el)
    },
    checkCompletion () {
      this.$emit('update:complete', !this.getUnanswered().length)
      if (!this.getUnanswered().length) {
        this.$emit('complete')
      }
    },
    showUnansweredQuestions () {
      // this.$nextTick(() => {
      //   this.$refs[`question-${this.getUnanswered()[0]?.id}`].scrollTop = 0
      // })

      this.$vuetify.goTo(this.$refs[`question-${this.getUnanswered()[0]?.id}`][0])
    },
    init () {
      this.$store.commit('app/setAppBarTitle', 'Forms')
      this.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          this.$emit('prev')
        }
      })
      this.$store.commit('app/setAppBarButton2', {
        label: 'Next',
        icon: 'mdi-chevron-right',
        click: () => {
          if (this.loading) {
            return
          }

          if (process.env.NODE_ENV === 'production' && this.forms.findIndex(form => !form.completed) > -1) {
            this.$_notify.error(this.$_t('Please complete all required forms'))
            return
          }

          this.$emit('next')
        }
      })
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')

      this.fetchTemplates()
    },
    fetchTemplates () {
      this.loading = true
      this.forms = []
      this.$_rest.get(
        `patients/${ this.patient.id }/appointments/${ this.appointment.id }/templates`,
        { groups: 'all,template' },
        response => {
          if (response.data && Array.isArray(response.data)) {
            response.data.forEach(el => {
              if (typeof el.formJson !== 'undefined') {
                const form = JSON.parse(el.formJson)
                if (form) {
                  form.completed = false
                  form.templateId = el.id
                  this.forms.push(form)
                }
              }
            })
          }
          this.loading = false
        },
        error => {
          this.loading = false
          this.$_notify.error(error)
        })
    },
    completePanel () {

    }
  },
  mounted () {
    this.init()
  },
  watch: {
    loading (value) {
      this.$emit('update:loading', value)
    }
  }
}
</script>
