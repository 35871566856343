<template>
  <practitioner-layout>

  </practitioner-layout>
</template>
<script>
import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import PractitionerLayout from '@layouts/practitioner.vue'

export default defineComponent({
  name: 'PractitionerHome',
  components: { PractitionerLayout },
  props: {
  },
  setup(props, ctx) {

  }
})
</script>