<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-skeleton-loader
      v-if="loading"
      type="article,article,article,article,article,article"
    ></v-skeleton-loader>
    <div v-else>
      <iframe
        v-if="!iframeError"
        :src="reportUrl"
        style="position: absolute; width: 100%; height: calc(100vh - 64px); border: 0;"
      />
      <v-row
        v-else
        justify="center"
      >
        <div
          v-if="iframeError"
          class="text-h5 pt-5"
        >{{ $_t('Unable to load the document for this appointment.') }}
        </div>
      </v-row>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="600"
      persistent
    >
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'AppointmentReportPreview',
  props: {
    fetchRoute: String,
    row: {
      type: Object,
      default: () => {
      }
    },
    actions: [Array, String]
  },
  mounted () {
    this.fetchReportContent()

  },
  data () {
    return {
      reportUrl: '',
      dialog: false,
      iframeError: false,
      radioGroup: [],
      alertRecipients: [],
      loading: true
    }
  },
  computed: {

  },
  methods: {
    fetchReportContent () {
      this.iframeError = false
      this.$_rest.get(this.fetchRoute, {}, response => {
        if (response.status === 202) {
          this.$nextTick(() => {
            this.fetchReportContent();
          });
        } else if (response.status === 200) {
          try {
            response.blob().then(blob => {
              this.reportUrl = window.URL.createObjectURL(blob)
              this.iframeError = false
            })
          } catch (e) {
            this.iframeError = true
          }
          this.loading = false
        } else {
          this.iframeError = true
          this.loading = false
        }
      }, error => {
        this.iframeError = true
      },
      {
        handleResponse: false
      })
    }
  }
}
</script>

<style scoped>

</style>
