<template>
  <div>
    <v-tabs v-model="selectedTabIndex" v-on:change="onTabsChanged()">
      <v-tab v-for="scan in scans" :key="scan.scanCode">{{
        scan.scanName
      }}</v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ScanTypes',
  data() {
    return {
      selectedTabIndex: 0,
      scans: []
    };
  },
  computed: {
    ...mapGetters('practitioner', ['getPractitionerScheduleClinicScans']),
    selectedScan() {
      return this.scans[this.selectedTabIndex ?? 0];
    }
  },
  mounted() {
    this.$store.dispatch('practitioner/getPractitionerScheduleClinicScans');
  },
  watch: {
    getPractitionerScheduleClinicScans(scans) {
      this.scans = scans;
      this.$emit('selectedScanChanged', this.selectedScan.scanCode);
    }
  },
  methods: {
    onTabsChanged() {
      this.$emit('selectedScanChanged', this.selectedScan.scanCode);
    }
  }
};
</script>
