<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col
        cols="12"
        class="mt-6 mb-2 py-0 text-h6"
      >
        {{ deposit ? $_t('Deposit') : $_t('Payment') }}
      </v-col>
      <v-col
        cols="12"
        class="mt-6 mb-2 py-0"
      >
        <v-btn
          color="primary"
          class="mr-1"
          min-width="120px"
          @click="success"
        >
          {{ $_t('Payment successful') }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="mt-0 mb-2 py-0"
      >
        <v-btn
          color="primary"
          class="mr-1"
          min-width="120px"
          @click="fail"
        >
          {{ $_t('Payment failed') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Pay',
  props: {
    appointmentId: {
      type: String,
      default : ''
    },
    deposit: {
      type: Boolean,
      default: false
    }
  },
  components: {  },
  data () {
    return {
      address: null
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    success () {
      fetch('https://ris-ie-test.affidea.com/pay.php?appointmentId=' + this.appointmentId + '&status=S').then(() => {
        this.$emit('input', false)
      })
    },
    fail () {
      fetch('https://ris-ie-test.affidea.com/pay.php?appointmentId=' + this.appointmentId + '&status=F').then(() => {
        this.$emit('input', false)
      })
    }
  }
}
</script>
