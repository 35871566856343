
















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name:'AppointmentPracticeSplitSlot',
    props: {
    item: {
      type: String
      
    }
  },
  setup() {
      const splitData = (dataToSplit: string)=>{
        const set = new Set(dataToSplit.split('<br>'))
          return [...set]
      }

      return {
          splitData
      }
  },
})
