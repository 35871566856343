<template>
  <v-container dark>
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
    >
      <v-col cols="12">
        <h6 class="text-h6">
          {{$_t('Thank you for completing your patient profile. Your referral letter will now be sent for protocolling by a clinical team. Once this has been done you will be contacted by a member of our scheduling team to arrange your appointment.')}}
        </h6>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted, getCurrentInstance } from '@vue/composition-api'

export default defineComponent({
  name: 'AppointmentThankYou',
  props: {
    options: { type: Object, default: {} }
  },
  setup(props, {emit}) {
    const _vm = getCurrentInstance()
    const refResearch = ref(null)
    const refOffers = ref(null)
    const refHistoric = ref(null)
    const noMarketing = ref(false)

    const consent = ref({
      research: '',
      offers: '',
      marketing: { email: 'No', SMS: 'No', phone: 'No' },
      onbehalf: 'No',
      historic: ''
    })
    const getOptions = computed(() => {
      return props.options
    })

    const nextPage = (page) => {
      emit('page', page)
    }

    watch(() => noMarketing.value, (val) => {
      if (val) {
        consent.value.marketing = { email: 'No', SMS: 'No', phone: 'No' }
      }
    })

    const appointment = computed(() => _vm.proxy.$store.getters['appointment/appointment'])
    const currentPatient = computed(() => _vm.proxy.$store.getters['app/currentPatient'])

    const saveConsentData = () => {
      const consentText = `Research: ${consent.value.research} | Offers: ${consent.value.offers} | Marketing: Email: ${consent.value.marketing.email} SMS: ${consent.value.marketing.SMS} Phone: ${consent.value.marketing.phone} | Historic: ${consent.value.historic} | On behalf: ${consent.value.onbehalf}`
      _vm.proxy.$_rest.put(
        `patients/${currentPatient.value.id}/appointments/${appointment.value.id}/custom`,
        {
          custom7: consentText
        }, undefined,
        response => {
        },
        error => {
          _vm.proxy.$_notify.error(error)
        }
      )
    }

    const init = () => {
      _vm.proxy.$store.commit('app/setAppBarTitle', 'Thank you')
      _vm.proxy.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          emit('prev')
        }
      })
      _vm.proxy.$store.commit('app/setAppBarButton2', null)
      _vm.proxy.$store.dispatch('app/appBarShow')
      _vm.proxy.$root.$emit('scrollToTop')
    }

    onMounted(() => {
      init()
    })

    return {
      refResearch,
      refOffers,
      refHistoric,
      getOptions,
      nextPage,
      consent,
      noMarketing,
      init
    }
  }
})
</script>
