export default [
    {
      value: 'patientEpisodeId',
      text: 'Episode Id',
      align: 'left',
      visible: false
    },
    {
      value: 'patientId',
      text: 'Patient Id',
      align: 'left',
      visible: true
    },
    {
      value: 'patientEpisodeStatusCode',
      text: 'Episode Status Code',
      align: 'left',
      visible: false
    },
    {
      value: 'patientEpisodeStatusName',
      text: 'Episode Status Name',
      align: 'left',
      visible: true
    },
    {
      value: 'patientEpisodeStatusColorCode',
      text: 'Episode Status Color Code',
      align: 'left',
      visible: false
    },
    {
      value: 'event',
      text: 'Event',
      align: 'left',
      visible: true
    },
    {
      value: 'patientEpisodeNumber',
      text: 'Episode Number',
      align: 'left',
      visible: true
    },
    {
      value: 'currentHospitalLocationPointOfCare',
      text: 'Patient Location',
      align: 'left',
      visible: true
    },
    {
      value: 'patientLocationJson',
      text: 'Location Json',
      align: 'left',
      visible: false
    },
    {
      value: 'createdDatetime',
      text: 'Created Date',
      align: 'left',
      visible: true
    },
    {
      value: 'dischargeDatetime',
      text: 'Discharge',
      align: 'left',
      visible: false
    },
    {
      value: 'referringPractitionerId',
      text: 'Practitioner Id',
      align: 'left',
      visible: false
    },
    {
      value: 'referringPractitionerFirstName',
      text: 'Practitioner First Name',
      align: 'left',
      visible: false
    },
    {
      value: 'referringPractitionerLastName',
      text: 'Practitioner Last Name',
      align: 'left',
      visible: false
    },
    {
      value: 'referringPractitionerTitle',
      text: 'Practitioner Title',
      align: 'left',
      visible: false
    },
    {
      value: 'referringPractitionerFullName',
      text: 'Practitioner Full Name',
      align: 'left',
      visible: true
    },
    {
      value: 'referringPractitionerIdentificationNumber',
      text: 'Practitioner Id Number',
      align: 'left',
      visible: false
    },
    {
      value: 'consultingPractitionerId',
      text: 'Consulting PractitionerId',
      align: 'left',
      visible: false
    },
    {
      value: 'consultingPractitionerFirstName',
      text: 'Consulting Practitioner First Name',
      align: 'left',
      visible: false
    },
    {
      value: 'consultingPractitionerLastName',
      text: 'Consulting Practitioner Last Name',
      align: 'left',
      visible: false
    },
    {
      value: 'consultingPractitionerFullName',
      text: 'Consulting Practitioner FullName',
      align: 'left',
      visible: true
    },
    {
      value: 'consultingPractitionerTitle',
      text: 'Consulting Practitioner Title',
      align: 'left',
      visible: false
    },
    {
      value: 'consultingPractitionerIdentificationNumber',
      text: 'Consulting Practitioner Id Number',
      align: 'left',
      visible: false
    },
    {
      value: 'isDischarged',
      text: 'Discharged',
      align: 'left',
      visible: false
    },
    {
      value: 'currentHospitalLocationJson',
      text: 'Hospital Location Json',
      align: 'left',
      visible: false
    },
    {
      value: 'episodeAppointmentCategoryId',
      text: 'Appointment Category ID',
      align: 'left',
      visible: false
    },
    {
      value: 'episodeAppointmentCategoryCode',
      text: 'Appoointment Category Code',
      align: 'left',
      visible: false
    },
    {
      value: 'episodeAppointmentCategoryName',
      text: 'Patient Group',
      align: 'left',
      visible: true
    }
  ]
