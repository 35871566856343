<template>
  <v-container fluid>
    <div v-if="list.length > 0 && !loader">
      <v-list subheader two-line>
        <v-subheader><h4>{{$_t('Procedures')}}</h4></v-subheader>
        <v-list-group
          v-for="item in list"
          :key="item.id"
          value="true"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-avatar v-if="item.filteredReports || item.filteredReports.length > 0">
              <v-badge
                color="red"
                offset-x="15"
                offset-y="15"
                :content="(item.study) ? 2 : 1"
                bordered
              >
                <v-icon class="grey lighten-1" dark>
                  mdi-folder
                </v-icon>
              </v-badge>
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
                <v-icon class="grey lighten-1" dark>
                  mdi-folder
                </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.procedure.name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="item.procedure.code"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-list-item v-if="item.filteredReports || item.filteredReports.length > 0">
            <v-list-item-content>
              <v-list-item-title>
                {{$_t('Report')}}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$_t('Approved Date')}}: {{useHumanDateTime(item.filteredReports.approvedDatetime)}}
              </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  @click="$emit('show-report',item.filteredReports.id)"
                  outlined
                  color="primary"
                >
                  <v-icon left color="primary">mdi-eye</v-icon>
                  {{$_t('View')}}
                </v-btn>
              </v-list-item-action>

          </v-list-item>
          <v-list-item v-if="item.study">
            <v-list-item-content>
              <v-list-item-title>
                {{$_t('Examination')}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="generateViewerLink(
                  item.appointmentId,
                  item.id,
                  item.study.id
                )"
                outlined
                color="primary"
              >
                <v-icon left color="primary">mdi-eye</v-icon>
                {{$_t('View')}}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>
    <v-progress-linear
      v-if="loader"
      indeterminate
      color="primary"
    ></v-progress-linear>
  </v-container>
</template>

<script>
import useDateHelpers from '../../helpers/useDateHelpers';
import config from '@src/config'
const { useHumanDateTime } = useDateHelpers()
export default {
  name: 'AppointmentProcedureList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loader:Boolean
  },
  data() {
    return {
      useHumanDateTime: useHumanDateTime,
      viewerLinkLoading: false
    }
  },
  methods: {
    generateViewerLink (appointmentId, appProcId, appointmentProcedureStudyId) {
      this.viewerLinkLoading = true;
      this.$_rest.get(
        `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${appointmentId}/appointment-procedures/${appProcId}/appointment-procedure-studies/${appointmentProcedureStudyId}/viewer-link/${config.pacsViewerCode}`,
        {},
        resp => {
          if (resp.data.viewerUrl) {
            window.open(resp.data.viewerUrl, '_blank');
          } else {
            this.$_notify.error('Could not generate a viewer link');
          }
          this.viewerLinkLoading = false;
        }
      );
    }
  }
};
</script>

<style scoped></style>
