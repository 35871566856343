












import { defineComponent,computed } from '@vue/composition-api'

export default defineComponent({
    props: {
    search: {
      type: String

    },
    label:{
        type:String,
        default:'Search'
    }
  },
  setup() {

  },
})
