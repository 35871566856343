<template>
  <v-container>

  </v-container>
</template>

<script>

export default {
  name: 'DEV',

}
</script>

