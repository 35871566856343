import config from '@src/app.config'
import store from '@state/store'

function getUrl (prefixWithOrganizationId) {
  prefixWithOrganizationId = prefixWithOrganizationId !== false
  return config.urlAPI + prefixWithOrganizationId ? '/' + store.state.app.appCode + '/' : '/'
}

function authHeader () {
  return {}
}

async function handleResponse (response, successHandler, errorHandler) {
  const json = await response.json()

  if (response.status === 200) {
    if (typeof successHandler === 'function') {
      successHandler(json)
    }
  } else if (response.status === 401) {
    // Logout if API returns 401
    // logout();
    // window.location.href = config.authEndpoint+'?response_type=token&client_id='+config.authClientId+'&scope=basic%20email&state=csrftoken';
  } else {
    if (typeof errorHandler === 'function' && typeof json.error !== 'undefined') {
      errorHandler(json.error.message)
    } else {
      console.error('handleResponse failed to process response', response)
    }
  }
}

async function get (endpoint, params, successHandler, errorHandler, prefixWithOrganizationId) {
  try {
    let url = getUrl(prefixWithOrganizationId) + endpoint
    const bodyArr = []

    if (typeof params === 'object') {
      for (const key in params) {
        bodyArr.push([key, params[key]].join('='))
      }
      if (bodyArr.length) {
        url += `?${bodyArr.join('&')}`
      }
    }

    const response = await fetch(url, {
      method: 'GET', // or 'PUT'
      headers: authHeader()
    })

    handleResponse(response, successHandler, errorHandler)
  } catch (error) {
    if (typeof error === 'function') {
      errorHandler(error)
    }
  }
}

async function getById (endpoint, id, successHandler, errorHandler, prefixWithOrganizationId) {
  try {
    const url = getUrl(prefixWithOrganizationId) + endpoint + '/' + id

    const response = await fetch(url, {
      method: 'GET', // or 'PUT'
      headers: authHeader()
    })

    handleResponse(response, successHandler, errorHandler)
  } catch (error) {
    if (typeof error === 'function') {
      errorHandler(error)
    }
  }
}

async function put (endpoint, data, id, successHandler, errorHandler, prefixWithOrganizationId) {
  try {
    let url = getUrl(prefixWithOrganizationId) + endpoint

    if (typeof id !== 'undefined') {
      url += '/' + id
    }

    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' })
    })

    handleResponse(response, successHandler, errorHandler)
  } catch (error) {
    if (typeof error === 'function') {
      errorHandler(error)
    }
  }
}

async function post (endpoint, data, id, successHandler, errorHandler, prefixWithOrganizationId) {
  try {
    const url = getUrl(prefixWithOrganizationId) + endpoint

    if (typeof id !== 'undefined') {
      // POST carries id in body, so no need to duplicate it in path
      // url+= '/' + id;
    }

    if (typeof data !== 'object') {
      data = {}
    }

    // if (typeof data['user_id'] === 'undefined') {
    //     data['user_id'] = store.getters['user/getUserId'];
    // }

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' })
    })

    handleResponse(response, successHandler, errorHandler)
  } catch (error) {
    if (typeof error === 'function') {
      errorHandler(error)
    }
  }
}

async function remove (endpoint, successHandler, errorHandler, prefixWithOrganizationId) {
  try {
    const url = getUrl(prefixWithOrganizationId) + endpoint

    const response = await fetch(url, {
      method: 'DELETE', // or 'PUT'
      headers: authHeader()
    })

    handleResponse(response, successHandler, errorHandler)
  } catch (error) {
    if (typeof error === 'function') {
      errorHandler(error)
    }
  }
}

export default {
  get,
  put,
  post,
  remove,
  handleResponse,
  getById
}
