<template>
  <div>
    <v-app-bar
      v-if="appBar"
      app
      color="primary"
      elevation="6"
    >
      <h2 class="text-h5 white--text">
        {{ $t(appBarTitle) }}
      </h2>
      <v-spacer />
      <v-btn
        v-if="appBarButton1"
        color="white"
        class="text--primary mr-2"
        @click="appBarButton1.click"
        :fab="$vuetify.breakpoint.xs"
        :small="$vuetify.breakpoint.xs"
      >
        <v-icon v-if="$vuetify.breakpoint.xs">mdi-chevron-left</v-icon>
        <span v-else>{{ $t(appBarButton1.label) }}</span>
      </v-btn>
      <v-btn
        v-if="appBarButton2"
        color="white"
        class="text--primary"
        @click="appBarButton2.click"
        :loading="appBarButton2Loading"
        :fab="$vuetify.breakpoint.xs"
        :small="$vuetify.breakpoint.xs"
      >
        <v-icon v-if="$vuetify.breakpoint.xs">mdi-chevron-right</v-icon>
        <span v-else>{{ $t(appBarButton2.label) }}</span>
      </v-btn>
      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            dark
            class="ml-2 icon-hover"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="logOut()"
          >
            <v-list-item-title>{{$_t('Logout')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main ref="main">
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import auth from '@helpers/auth'

export default {
  name: 'MainLayout',
  components: {},
  computed: {
    ...mapState('app', { appBar: 'appBar', appBarTitle: 'appBarTitle', appBarButton1: 'appBarButton1', appBarButton2: 'appBarButton2', appBarButton2Loading: 'appBarButton2Loading' })
  },
  mounted () {
    this.$root.$on('scrollToTop', () => {
      this.$vuetify.goTo(this.$refs['main'])
    })
  },
    methods: {
      logOut() {
        auth.logout();
      }
    }
}
</script>
<style scoped>
  .icon-hover:hover {
    background: rgba(255,255,255,0.25);
  }
</style>
