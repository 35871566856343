<template>
  <patient-layout :title="$_t('My Profile')">
    <div v-if="loading" class="text-center pt-12 pb-12">
      <v-progress-circular
        :size="75"
        color="primary"
        indeterminate
        class="mb-12"
      ></v-progress-circular>
      <br/>
      <span class="subtitle-1">{{ $_t('Initializing...') }}</span>
    </div>
    <div v-else-if="!loading">
      <v-card flat>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="4" class="pa-4">
              <h2 class="mb-2">{{ $_t('My personal details')}}</h2>
              <v-list>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">{{$_t('Name and surname')}}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 font-weight-medium">
                      {{ patient.title ? patient.title + ' ' : ''}}
                      {{ patient.firstName ? patient.firstName + ' ' : ''}}
                      {{ patient.middleName ? patient.middleName + ' ' : ''}}
                      {{ patient.lastName ? patient.lastName : ''}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">{{$_t('Patient number')}}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 font-weight-medium">
                      {{patient.number1 ? patient.number1 : $_t('n/a')}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">{{$_t('Date of birth')}}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 font-weight-medium">
                      {{ patient.dateOfBirth ? useHumanDate(patient.dateOfBirth) : $_t('n/a') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular">{{$_t('Mobile number')}}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 font-weight-medium">
                      {{ patient.mobileNumber1 ? patient.mobileNumber1 : $_t('n/a') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-regular" >{{$_t('Email address')}}</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 font-weight-medium">
                      {{ patient.emailAddress1 ? patient.emailAddress1 : $_t('n/a') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="4" class="pa-4">
              <h2 class="mb-4">{{ $_t('My address')}}</h2>
              <v-form ref="form" v-model="patientFormValid" lazy-validation>
                <v-text-field
                  v-model="patient.address1"
                  :label="$_t('Street name')"
                  :rules="[validationRules.streetName]"
                  outlined
                  color="primary"
                  class="mb-2"
                ></v-text-field>
                <v-text-field
                  v-model="patient.address2"
                  :label="$_t('Street number')"
                  :rules="[validationRules.streetNumber]"
                  outlined
                  color="primary"
                  class="mb-2"
                ></v-text-field>
                <v-text-field
                  v-model="patient.address3"
                  :label="$_t('Apartment number')"
                  :rules="[validationRules.apartmentNumber]"
                  outlined
                  color="primary"
                  class="mb-2"
                ></v-text-field>
                <v-text-field
                  v-model="patient.postCode"
                  :label="$_t('Post code')"
                  :rules="[validationRules.postCode]"
                  outlined
                  color="primary"
                  class="mb-2"
                ></v-text-field>
                <v-text-field
                  v-model="patient.town"
                  :label="$_t('Town')"
                  :rules="[validationRules.town]"
                  outlined
                  color="primary"
                  class="mb-2"
                ></v-text-field>
                <v-btn
                  color="primary"
                  large
                  width="100%"
                  @click="saveChanges"
                >{{$_t('Save changes')}}</v-btn>
              </v-form>
              <v-alert color="red" type="error" v-if="!patientFormValid">
                {{ $_t('Not all mandatory fields have been completed') }}
              </v-alert>
            </v-col>
            <v-col cols="12" md="4" class="pa-4">
              <h2 class="mb-2">{{ $_t('Communication methods')}}</h2>
              <p class="mb-4 grey--text text--darken-1">
                {{ $_t('Communication methods text 1') }}
                <a
                  :href="config.privacyPolicyLink"
                  target="_blank"
                >{{ $_t('Communication methods link') }}</a
                >{{ $_t('Communication methods text 2') }}
                <a :href="'mailto:' + config.communicationsEmail">{{config.communicationsEmail}}</a>.
              </p>
              <v-checkbox
                v-model="termsAndConditions"
                hide-details="auto"
                class="mt-0 mb-4"
                required
              >
                <template v-slot:label>
                  <div>
                    *
                    {{ $_t('I accept') }}
                    <a
                      @click.stop
                      :href="config.termsLink"
                      target="_blank"
                    >{{ $_t('terms and conditions') }}</a>.
                  </div>
                </template>
              </v-checkbox>
              <p class="my-4 grey--text text--darken-2">
                <span class="label">
                  {{
                    $_t('I agree to marketing communications')
                  }}:
                </span>
                <v-menu
                  max-width="460"
                  nudge-top="20"
                  close-delay="100"
                  open-on-hover
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      v-on="on"
                      v-bind="attrs"
                      class="label"
                    >
                      {{ $_t('What it means?') }}
                    </a>
                  </template>
                  <v-alert
                    color="primary accent-4"
                    class="ma-0"
                    colored-border
                  >
                    {{
                      $_t('Communication methods text 3')
                    }}
                    <br/>
                    {{
                      $_t('Communication methods text 4')
                    }}
                  </v-alert>
                </v-menu>
                <v-checkbox
                  v-model="patient.consentEmail"
                  :hide-details="true"
                  class="ma-0 ml-4"
                >
                  <template v-slot:label>
                    {{ $_t('agreement email') }}
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="patient.consentSms"
                  :hide-details="true"
                  class="ma-0 ml-4"
                >
                  <template v-slot:label>
                    {{ $_t('agreement sms/mms') }}
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="patient.consentPhone"
                  :hide-details="true"
                  class="ma-0 ml-4"
                >
                  <template v-slot:label>
                    {{ $_t('agreement phone') }}
                  </template>
                </v-checkbox>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
      v-model="termsAndConditionsDialog"
      persistent
      max-width="500"
    >
      <v-card :loading="termsAndConditionsDialogLoading">
        <v-card-title class="text-h5">
          {{ $_t('Decline terms & conditions?') }}
        </v-card-title>
        <v-card-text>{{ $_t('Are you sure you want to decline terms & conditions? You will be logged out of the system') }}</v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="processTCDialogAnswer(false)"
            :disabled="termsAndConditionsDialogLoading"
          >
            {{$_t('No, go back')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="processTCDialogAnswer(true)"
            :disabled="termsAndConditionsDialogLoading"
          >
            {{$_t('Yes, log me out')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </patient-layout>
</template>

<script>
import PatientLayout from '@router/layouts/patient.vue'
import useDateHelpers from '@src/helpers/useDateHelpers'
import config from '@config'
import auth from '@src/helpers/auth'
const { useHumanDate } = useDateHelpers()

export default {
  name: 'PatientProfile',
  components: {
    PatientLayout
  },
  data () {
    return {
      config: config,
      useHumanDate: useHumanDate,
      loading: false,
      birthdayMenu: false,
      patientFormValid: null,
      patient: {
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        number1: '',
        dateOfBirth: '',
        mobileNumber1: '',
        emailAddress1: '',
        address1: '',
        address2: '',
        address3: '',
        postCode: '',
        town: '',
        consentEmail: false,
        consentSms: false,
        consentPhone: false
      },
      contactAgreement: null,
      termsAndConditions: true,
      termsAndConditionsDialog: false,
      termsAndConditionsDialogLoading: false,
      validationRules: {
        firstName: value =>
          !value || this.$_t('Please enter correct first name.'),
        lastName: value =>
          !value || this.$_t('Please enter correct last name.'),
        patientNumber: value =>
          !value ||
          this.validatePatientNumber(value) ||
          this.$_t('Please enter correct patient number.'),
        streetName: value =>
          !value ||
          (value && value.length <= 100) ||
          this.$_t('Street name must be less than or equal to 100 characters.'),
        streetNumber: value =>
          !value ||
          (value && value.length <= 20) ||
          this.$_t(
            'Street number must be less than or equal to 20 characters.'
          ),
        apartmentNumber: value =>
          !value ||
          (value && value.length <= 20) ||
          this.$_t(
            'Apartment number must be less than or equal to 20 characters.'
          ),
        postCode: value =>
          !value ||
          (value && value.length <= 20) ||
          this.$_t('Post code must be less than or equal to 20 characters.'),
        town: value =>
          !value ||
          (value && value.length <= 20) ||
          this.$_t('Town must be less than or equal to 50 characters.'),
        contactAgreement: value =>
          !!value || this.$_t('Please accept to be contacted.'),
        mobilePhone: value => {
          const pattern = /(^|\W)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/
          return (
            pattern.test(value) ||
            this.$_t('Please enter correct mobile number.')
          )
        },
        emailAddress: value => {
          const pattern = /^$|.+@.+\..+/
          return (
            !this.patient.consentEmail ||
            (pattern.test(value) && value.length <= 50) ||
            this.$_t('Please enter correct e-mail address.')
          )
        },
      }
    }
  },
  mounted () {
    this.getProfile()
  },
  computed: {},
  methods: {
    validatePatientNumber (patientNumber) {
      if (patientNumber.length !== 11) {
        return false
      }
      const weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7]
      let sum = 0

      for (let i = 0; i < weight.length; i++) {
        sum += parseInt(patientNumber.substring(i, i + 1), 10) * weight[i]
      }

      sum = sum % 10

      const digit = parseInt(patientNumber.substring(10, 11), 10)
      return sum === digit
    },
    getProfile () {
      this.loading = true
      this.$_rest.get(
        `/patients/${this.$store.getters['app/currentUserId']}`,
        { groups: 'patient' },
        response => {
          this.setPatientData(response.data)
          this.loading = false
        },
        error => {
          this.loading = false
          this.$_notify.error(error)
        },
        { prefixRoutesWithOrganizationId: false }
      )
    },
    setPatientData(data) {
      for (const key in data) {
        if (key in this.patient) {
          this.patient[key] = data[key]
        }
      }

      if (data.consentEmail) {
        this.patient.consentEmail = data.consentEmail === 1
      }

      if (data.consentSms) {
        this.patient.consentSms = data.consentSms === 1
      }

      if (data.consentPhone) {
        this.patient.consentPhone = data.consentPhone === 1
      }

      if (data.custom8) {
        this.contactAgreement = data.custom8 === '1';
      }
    },
    saveChanges() {
      this.$_rest.put(
        '/patients',
        {
          address1: this.patient.address1 ?? '',
          address2: this.patient.address2 ?? '',
          address3: this.patient.address3 ?? '',
          postCode: this.patient.postCode ?? '',
          town: this.patient.town ?? '',
          custom8: this.contactAgreement ? 1 : 0,
          consentEmail: this.patient.consentEmail ? 1 : 0,
          consentSms: this.patient.consentSms ? 1 : 0,
          consentPhone: this.patient.consentPhone ? 1 : 0,
          groups: 'patient'
        },
        this.$store.getters['app/currentUserId'],
        () => {
          this.$_notify.success(this.$_t('Changes saved'))
        },
        error => {
          this.$_notify.error(error)
        },
        { prefixRoutesWithOrganizationId: false }
      )
    },
    processTCDialogAnswer(answer) {
      if (answer === true) {
        this.termsAndConditionsDialogLoading = true
        fetch(config.authEndpoint + '/tos/decline', {
          method: 'POST',
          credentials: 'include'
        }).then(() => auth.logout())
      } else {
        this.termsAndConditionsDialog = false;
        this.termsAndConditions = true;
      }
    }
  },
  watch: {
    termsAndConditions(val) {
      if (!val) {
        this.termsAndConditionsDialog = true;
      }
    }
  }
}
</script>

<style scoped>
 .label {
   font-size: 16px
 }
</style>
