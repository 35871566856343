<template>
  <div class="d-flex justify-start pa-2">
    <v-select
      v-model="model"
      :items="episodes"
      item-value="patientEpisodeId"
      item-text="patientEpisodeLabel"
      :loading="loading"
    />
  </div>
</template>

<script>
import useDateHelpers from '../../helpers/useDateHelpers';
const { useHumanDateTime } = useDateHelpers()

export default {
  name: 'PatientEpisodeDropdown',
  props: {
    value: {
      type: String
    },
    patientId: {
      type: String
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      episodes: [],
      loading: false,
      useHumanDateTime:useHumanDateTime
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    processItems() {
      const copyEpisodes = [...this.episodes]
      // const filteredEpisodes = copyEpisodes.filter(data => data.patientId === this.patientId)
      copyEpisodes.forEach(episode=>{
        episode.patientEpisodeLabel = `${episode.patientEpisodeNumber} | ${useHumanDateTime(episode.createdDatetime)} | ${episode.currentHospitalLocationPointOfCare} | ${episode.patientEpisodeStatusName}  ${episode.isDischarged ? '| '+this.$_t('Discharged') : ''}`
      })
      this.episodes = [...copyEpisodes]
    },
    getData () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/episodes`, {
        filter: { patientId: {  x: this.patientId, type: 'eq'}}
      }, response => {
        this.loading = false
        this.episodes = this.mapDatasetResponse(response)
        this.processItems()
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },

  }
}
</script>

<style scoped>

</style>
