<template>
  <v-container fluid>
    <div
      style="height: 5px"
    >
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
    </div>

    <v-row>
      <v-col cols="12" lg="3" md="3">
        <v-card-title>
          {{ $_t('Select priority') }}
        </v-card-title>
        <priority-dropdown
          v-model="appointmentPriority"
        />
      </v-col>
      <v-col cols="12" lg="6" md="6">
        <v-card-title>
           {{ $_t('Preferred date') }}
        </v-card-title>
        <scheduling
          v-model="appointment.preferredAppointmentDate"
        />
      </v-col>
    <v-col cols="12" lg="3" md="3">
      <v-card-title>
        <v-row>
        <v-col cols="8"  style="margin-top:-10px;">{{ $_t('Patient Photo') }}</v-col>
        <v-col cols="4" style="margin-top:-25px;">
          <patient-photo-upload @attachments="photoAttachments" :patient-id="patientId" />
        </v-col>
        </v-row>
      </v-card-title>
      <template v-if="attachments.length > 0">
        <div v-for="(pic,i) in attachments" :key="i">
          <v-img
              :src="`data:${pic.file.format}, ${pic.file.base64}`"
              height="125"
              class="grey darken-4"
              contain
            ></v-img>
        </div>
      </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12" md="12" v-if="canBeDisplay('episode')">
        <v-card-title>
          {{ $_t('Select episode') }}
        </v-card-title>
        <PatientEpisodeDropdown
          :patient-id="patientId"
          v-model="patientEpisode"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="3" v-if="canBeDisplay('category')">
        <v-card-title>
          {{ $_t('Patient Group') }}
        </v-card-title>
        <CategoryDropdown
          v-model="appointmentCategory"
        />
      </v-col>
      <v-col cols="12" lg="3" md="3" v-if="canBeDisplay('location')">
        <v-card-title>
          {{ $_t('Select location') }}
        </v-card-title>
        <PatientLocationDropdown
          v-model="patientLocation"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import PriorityDropdown from '@components/appointment/PriorityDropdown.vue'
import CategoryDropdown from '@components/appointment/AppointmentCategoryDropdown.vue'
import PatientLocationDropdown from '@components/appointment/PatientLocationDropdown.vue'
import PatientEpisodeDropdown from '@components/appointment/PatientEpisodeDropdown.vue'

import Scheduling from '@components/appointment/Scheduling.vue'
import PatientPhotoUpload from '@components/common/PatientPhotoUpload.vue'
import moment from 'moment'
import config from '@src/config'

export default {
  name: 'EditNew',
  components: { PriorityDropdown, Scheduling,PatientPhotoUpload, CategoryDropdown, PatientLocationDropdown, PatientEpisodeDropdown },
  props: {
    appointment: {
      type: Object
    },
    complete: {
      type: Boolean
    },
    patientId: {
      type: String
    }
  },
  computed: {
    appointmentPriority: {
      get () {
        return this.appointment.priorityCode
      },
      set (value) {
        this.updateAppointmentPriority(value)
      }
    },
    appointmentCategory: {
      get () {
        return this.appointment.categoryId
      },
      set (value) {
        this.updateAppointmentCategory(value)
      }
    },
    patientLocation: {
      get () {
        return this.appointment.patientLocationId
      },
      set (value) {
        this.updatePatientLocation(value)
      }
    },
    patientEpisode: {
      get () {
        return this.appointment.patientEpisodeId
      },
      set (value) {
        this.updatePatientEpisode(value)
      }
    }
  },
  data () {
    return {
      loading: false,
      attachments:[]
    }
  },
  mounted () {
    this.appointmentPriority  = this.appointment.priorityCode
    this.appointmentCategory  = this.appointment.categoryId
    this.patientLocationId = this.appointment.patientLocationId
    this.patientEpisodeId = this.appointment.patientEpisodeId
    if (!this.appointment.preferredAppointmentDate) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { preferredAppointmentDate: moment(Date.now()).format('YYYY-MM-DD') }))
    }
    if (this.appointment.appointmentPriority && this.appointment.preferredAppointmentDate) {
      this.$emit('update:complete', !!this.appointment.priorityCode)
    }
  },
  methods: {
    canBeDisplay(componentName) {
      return config.additionalDropdownsForNew.includes(componentName) || null
    },
    photoAttachments(attachments) {
      this.attachments = attachments
    },
    updateAppointmentPriority (code) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { priorityCode: code }))
    },
    updateAppointmentCategory (id) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { categoryId: id }))
    },
    updatePatientLocation (id) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { patientLocationId: id }))
    },
    updatePatientEpisode (id) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { patientEpisodeId: id }))
    }
  },
  watch: {
    appointment: {
      handler: function (appointment) {
        this.$emit('update:complete', !!appointment.priorityCode && !!appointment.preferredAppointmentDate)
      },
      deep: true
    },
  },

}
</script>

<style scoped>

</style>
