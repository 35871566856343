const state = {
  appointment: null,
  list:[]
}

const actions = {
  setAppointment ({ commit }, appointment) {
    commit('setAppointment', appointment)
    return true
  },
  setAppointmentsList ({ commit }, list) {
    commit('setAppointmentsList', list)
    return true
  }

}

const mutations = {
  setAppointment (state, appointment) {
    state.appointment = appointment
  },
  setAppointmentsList (state, list) {
    state.list = list
  }

}

const getters = {
  appointment (state) {
    return state.appointment
  },
  list (state) {
    return state.list
  },
  patientFullName (state) {
    return [state.appointment.patient.firstName, state.appointment.patient.middleName, state.appointment.patient.lastName].filter(el => el && el.trim()).join(' ')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
