<template>
  <practitioner-layout v-if="render">
    <router-view></router-view>
    <v-container fluid>
      <grid
        :columns="columns()"
        :data="itemsFiltered"
        :headers-preset-type="headersPresetType"
        :headers-preset="headersPreset"
        :loading="loading"
        :filterName="$_t('Alert status')"
        filterByColumnName="alertStatusName"
        @rowAdd="rowAdd"
        @refresh="getData"
        @rowClick="rowClicked"
      ></grid>
    </v-container>
  </practitioner-layout>
</template>

<script>
import Grid from '@components/common/Grid.vue'
import PractitionerLayout from '@router/layouts/practitioner.vue'
import { mapGetters } from 'vuex'
import alertsHeadersPreset from '@src/data/dataset/gridHeaders/alerts';

export default {
  name: 'AlertGrid',
  components: { Grid, PractitionerLayout },
  data () {
    return {
      changeAcknowledgedStatusObject : { alertStatusName:'Acknowledged', alertStatusCode:'A', alertStatusColorCode:'^3dbf60' },
      changeCancelRequestObject : { isAppointmentCancelRequested: '1' },
      loading: false,
      activeRow: null,
      render:true,
      buttonLoading:false,
      headersPresetType:'alerts',
      headersPreset: alertsHeadersPreset
    }
  },
  computed: {

    ...mapGetters('alerts', {items: 'data' }),
    ...mapGetters({
      alertColumns: 'app/columns'
    }),

    itemsFiltered() {
      return !this.items.length ? [] : this.filterItems()
    },
    refreshed() {
      return this.$store.getters['app/getRefreshed']
    }
  },
  methods: {
    columns() {
      return this.alertColumns(this.headersPresetType)
    },
    filterItems(){
      const newItems = [...this.items].sort((a, b) => {
        return a.createdDatetime <= b.createdDatetime
      })
      this.$store.commit('alerts/setData',newItems)
      return newItems

    },
    getData() {
      this.loading = true
      this.$store.dispatch('alerts/getAlerts').then(() => {
        this.loading = false
      })
    },
    rowAdd () {},
    rowClicked (row) {
      this.activeRow = row
      this.$router.push({ name: 'alert-details', params: { id: row.alertId } })
        // this.alertRightDrawer = true
    },

    processRefreshAlerts(objectToRefresh){
      this.activeRow = {...this.activeRow,...objectToRefresh}
      this.items.forEach((data,index)=>{
        if(data.alertId === this.activeRow.alertId) {
          this.items[index] = this.activeRow
        }
      })
      this.$store.commit('app/setRefreshed',true)


    }
  },
  watch:{
    refreshed(val){
       if(val) {
        this.render = false
        this.$nextTick(()=>{
          this.render = true
          this.$store.commit('app/setRefreshed',false)
        })
      }
    }

  },
  mounted(){
    this.getData()
    this.$root.$on('refresh-alerts-grid',(param)=>{

      switch (param) {
        case 'Acknowledged':
          this.processRefreshAlerts(this.changeAcknowledgedStatusObject)
          break;
        case 'RequestCancel':
           this.processRefreshAlerts(this.changeCancelRequestObject)
          break;
        default:
          break;
      }

    })
  }
}
</script>

<style scoped>

</style>
