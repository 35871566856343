<template>
  <v-container
    style="min-height: 400px;"
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        class="my-0 py-0"
      >
        <div class="mb-6 text-center">
          <h5 class="subtitle-1">
            {{ $_t('Please verify your referrer details are correct') }}:
          </h5>
          <h5 class="subtitle-1">
            {{ practitionerName }}
          </h5>
          <h5 class="subtitle-1">
            {{ practiceName }}
          </h5>
          <h5 class="subtitle-1">
            {{ practiceAddress }}
          </h5>
        </div>
        <div
          v-if="!correction"
          class="mb-6 text-center"
        >
          <v-btn
            color="primary"
            class="mr-1"
            min-width="120px"
            @click="markCorrect"
          >
            {{ $_t('Correct') }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-1"
            min-width="120px"
            @click="showCorrection"
          >
            {{ $_t('Not Correct') }}
          </v-btn>
        </div>
        <div
          v-if="correction"
          class="mb-6 text-center"
        >
          <h5 class="subtitle-1">
            {{ $_t('Please enter your referrer’s name and the clinic where you attended them') }}:
          </h5>
          <v-textarea
            v-model="correctionText"
            auto-grow
            filled
            ref="textarea"
            rows="3"
          />
          <v-row class="text-left mx-0">
            <v-btn
              color="primary"
              class=""
              @click="cancelCorrection"
            >
              {{ $_t('Cancel') }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              class=""
              @click="updatePractitioner"
            >
              {{ $_t('Update') }}
            </v-btn>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentCarouselPractitioner',
  components: { },
  props: {
    appointment: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      correct: false,
      correction: false,
      correctionText: ''
    }
  },
  computed: {
    ...mapGetters('app', { currentPatient: 'currentUser' }),
    practitionerName () {
      return typeof this.appointment.practitionerPractice !== 'undefined' ? this.appointment.practitionerPractice.practitionerName : this.$_t('Referrer not known')
    },
    practiceName () {
      return typeof this.appointment.practitionerPractice !== 'undefined' ? this.appointment.practitionerPractice.practiceName : this.$_t('Practice not known')
    },
    practiceAddress () {
      return typeof this.appointment.practitionerPractice !== 'undefined' ? this.appointment.practitionerPractice.practiceAddress : this.$_t('Practice address not known')
    },
    patientId () {
      return this.currentPatient && this.currentPatient.id ? this.currentPatient.id : ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // TODO this would need to be moved to the parent component
      this.$store.commit('app/setAppBarTitle', 'Referrer')
      this.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          this.$emit('prev')
        }
      })
      this.$store.commit('app/setAppBarButton2', {
        label: 'Next',
        icon: 'mdi-chevron-right',
        click: () => {
          if (!this.correct && !this.correction) {
            this.$_notify.error(this.$_t('Please indicate whether your referrer details are correct.'))
          } else {
            this.updatePractitioner()
          }
        }
      })
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')
    },
    markCorrect () {
      this.correct = true
      this.$emit('next')
    },
    showCorrection () {
      this.correction = true
      this.$nextTick(() => {
        this.$refs.textarea.focus()
      })
    },
    cancelCorrection () {
      this.correctionText = ''
      this.correction = false
    },
    updatePractitioner () {
      if (!this.correctionText || !this.correctionText.trim() || this.correctionText.trim().length < 5) {
        this.$_notify.error('Please provide correct referrer details')
        return false
      }
      this.$_rest.put(`/appointments/${ this.appointment.id }/patient`, { practitioner: this.correctionText }, undefined, undefined, error => {
          this.$_notify.error(error.errors[0].message)
        },
        { prefixRoutesWithPatientId: true }
      )
      this.$emit('next')
    }
  },
  watch: {}
}
</script>
