<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="mt-0 mb-2 py-0"
      >
        <div class="subtitle-1 text-center">
          Please verify your address and update, if necessary
        </div>
      </v-col>
    </v-row>
    <appointment-address-fields v-model="address" />
  </v-container>
</template>

<script>
import AppointmentAddressFields from '@components/appointment/carousel/AddressFields.vue'

export default {
  name: 'AppointmentAddress',
  props: {
    appointment: {
      type: Object,
      default: () => {}
    }
  },
  components: { AppointmentAddressFields },
  data () {
    return {
      address: null
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$store.commit('app/setAppBarTitle', 'Address')
      this.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          this.$emit('prev')
        }
      })
      this.$store.commit('app/setAppBarButton2', {
        label: 'Next',
        icon: 'mdi-chevron-right',
        click: () => {
          if (this.address && (!this.address.address1.trim() || !this.address.town.trim())) {
            this.$_notify.error('Please fill in all required fields')
            return
          } else if (this.address) {
            this.savePatientAddress()
          }
          this.$emit('next')
        }
      })
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')
    },
    savePatientAddress () {
      // check if changed
      if (this.appointment.patient.address1 !== this.address.address1 ||
          this.appointment.patient.address2 !== this.address.address2 ||
          this.appointment.patient.postCode !== this.address.postCode ||
          this.appointment.patient.town !== this.address.town) {
        this.$_rest.put(`/appointments/${this.appointment.id}/patient`, this.address, undefined, undefined, error => {
            this.$_notify.error(error.errors[0].message)
          },
          {prefixRoutesWithPatientId: true}
        )
      }
    }
  }
}
</script>
