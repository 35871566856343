import Vue from 'vue'
import { mapMutations } from 'vuex'
import { mapDatasetResponse,toBoolean } from './helper'

Vue.mixin({
  data () {
    return {
      formatDate: 'DD/MM/YYYY',
      formatTime: 'HH:mm',
      formatDateTime: 'DD/MM/YYYY HH:mm',
      formatDateTimeSec: 'DD/MM/YYYY HH:mm:ss',
    }
  },
  computed:{
    isMobile(){
      return this.isMobileCheck()
    }
  },
  methods: {
    ...mapMutations('app', ['drawerHide']),
    mapDatasetResponse,
    toBoolean,
    getDatasetGridHeaders (data) {
      let str = '['
      data.meta.columns.map(column => {
        str += `{value: '${column}', text: '${column.replace(/([A-Z])/g, ' $1')
          .replace(/^./, function(str){ return str.toUpperCase(); })}', align: 'left'},`
      })
      str += ']'
    },
    isMobileCheck(){
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md':
          return true
        default:
          return false
      }
    },
  }
})
