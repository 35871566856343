<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-container class="py-0">
        <v-card :loading="clinicsLoading" class="mx-auto">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              indeterminate
            ></v-progress-linear>
          </template>
        </v-card>
        <div v-if="!clinicsLoading && bookingPossible">
          <v-row v-if="getClinic">
            <v-col lg="6">
              <patient-appointment-booking-call-me-back-info :clinic="getClinic"></patient-appointment-booking-call-me-back-info>
            </v-col>
            <v-col lg="6">
              <patient-appointment-booking-call-me-back></patient-appointment-booking-call-me-back>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title class="px-6">
                  <span class="primary--text">Filter</span>
                </v-card-title>
                <v-card-text>
                  <div class="pa-2">
                    <label
                      class="d-inline-flex mb-2 home-input-label font-weight-bold"
                      >Postcode or town</label
                    >
                    <v-row>
                      <v-col cols="5">
                        <div style="position: relative;">
                          <vue-google-autocomplete
                            v-if="googleMapsApiKeyLoaded"
                            id="filter-google-autocomplete"
                            ref="fga"
                            classname="google-autocomplete"
                            placeholder=""
                            v-on:placechanged="onGoogleAutocompletePlaceChanged"
                            v-on:inputChange="onGoogleAutocompleteInputChanged"
                            country="uk"
                            types="(regions)"
                          >
                          </vue-google-autocomplete>
                          <v-btn
                            v-if="showIconCurrentLocation"
                            @click="onIconCurrentLocationClicked"
                            color="primary"
                            class="icon__current-location"
                            fab
                            x-small
                            icon
                            outlined
                          >
                            <v-icon>mdi-map-marker-radius</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="showIconClearLocation"
                            @click="onIconClearLocationClicked"
                            color="primary"
                            class="icon__clear-location"
                            fab
                            x-small
                            icon
                            outlined
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          v-model="distance"
                          :items="distances"
                          item-text="label"
                          item-value="value"
                          dense
                          solo
                          hide-details
                        >
                          <template #item="{item}">
                            <span class="notranslate">{{ item.label }}</span>
                          </template></v-select
                        >
                      </v-col>
                      <v-col lg="8">
                        <label
                          for="filter-clinic-names"
                          class="d-inline-flex mb-2 home-input-label font-weight-bold"
                          >Find clinic by name</label
                        >
                        <v-autocomplete
                          v-model="filteredClinicIdsByName"
                          :items="clinics"
                          :item-value="item => item.clinic_id"
                          :item-text="item => item.clinic_name"
                          id="filter-clinic-names"
                          multiple
                          outlined
                          dense
                          chips
                          small-chips
                          hide-details
                        >
                          <template v-slot:no-data>
                            <p class="ma-0 py-3 px-2">
                              No clinics found.
                            </p>
                          </template>
                          <template v-slot:selection="data">
                            <v-chip
                              v-if="filteredClinicIdsByName.length == 1"
                              v-bind="data.attrs"
                              :input-value="data.item.clinic_id"
                              @click="data.select"
                              @click:close="removeClinicName(data.item)"
                              close
                            >
                              {{ data.item.clinic_name }}
                            </v-chip>
                            <span
                              v-if="
                                filteredClinicIdsByName.length > 1 &&
                                  data.index === 0
                              "
                            >
                              {{ filteredClinicIdsByName.length }} selected
                            </span>
                          </template></v-autocomplete
                        >
                      </v-col>
                      <v-col lg="8">
                        <label
                          for="filter-clinic-towns"
                          class="d-inline-flex mb-2 home-input-label font-weight-bold"
                          >Select county</label
                        >
                        <div
                          v-for="clinicRegion in clinicRegions"
                          :key="clinicRegion"
                        >
                          <v-checkbox
                            v-model="filteredClinicRegions"
                            :label="clinicRegion"
                            :value="clinicRegion"
                            :ripple="false"
                            color="#006078"
                            class="my-0 black--text"
                            hide-details
                          ></v-checkbox>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto mb-8">
                <v-card-title class="px-6">
                  <span class="primary--text">Select clinic</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <div v-if="clinicsFiltered && clinicsFiltered.length">
                        <v-radio-group
                          v-model="selectedClinic"
                          v-on:change="onRadioGroupClicked"
                          class="ma-0"
                        >
                          <v-radio
                            v-for="clinic in clinicsFiltered"
                            :key="clinic.clinic_id"
                            :value="clinic"
                            v-on:keyup.enter="
                              selectedClinic = clinic;
                              onRadioGroupClicked();
                            "
                            color="primary"
                            class="mb-4 py-2 px-2 black--text"
                            :ripple="false"
                          >
                            <template slot="label">
                              <div class="flex-row">
                                <p class="ma-0 font-weight-bold">
                                  {{ clinic.clinic_name }}
                                </p>
                                <p class="ma-0 home-text-2">
                                  {{ clinic.clinic_address }}
                                </p>
                              </div>
                            </template></v-radio
                          >
                        </v-radio-group>
                      </div>
                      <div v-else>
                        <p class="py-2 px-4 text-h6">
                          No clinics found. Please change selected filters.
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="6" class="d-none d-lg-flex">
                      <div
                        class="d-flex static-map__container"
                        v-if="clinicsFiltered && clinicsFiltered.length"
                        v-bind:style="{
                          'background-size': 'contain',
                          'background-position': 'center center',
                          'background-image': 'url(' + clinicsMapSrc + ')'
                        }"
                        @click="openGoogleMaps"
                        aria-label="Vaccination clinics map. Click here to open interactive map within the modal window."
                      ></div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <div class="text-right">
                <v-btn
                  @click="onAfterContinueClicked"
                  v-if="clinics"
                  class="v-btn--primary"
                  color="primary"
                  :disabled="!selectedClinic"
                >
                  Continue
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mb-8" v-if="!clinicsLoading && (!bookingPossible)">
          <v-row>
            <v-col cols="12">
              <h3 class="text-center text-subtitle-1">
                We are sorry, however, we do not have any slots available to book online at this time.<br>
                Our team will be in contact to schedule your appointment.<br>
                Please note we are currently experiencing a high volume of appointment requests.
              </h3>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <v-dialog v-model="googleMapsDialog" max-width="800" max-height="600">
        <v-card>
          <v-container id="google-map__container" class="fill-height">
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#006078" text @click="googleMapsDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import config from '@src/config';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { calculateDistance } from '@helpers/distanceCalculation.ts';
import PatientAppointmentBookingCallMeBack from '@components/appointment/booking/callMeBack/callMeBack.vue'
import PatientAppointmentBookingCallMeBackInfo from '@components/appointment/booking/callMeBack/callMeBackInfo.vue'

const distances = [
  { label: '5 miles', value: 5 },
  { label: '10 miles', value: 10 },
  { label: '15 miles', value: 15 },
  { label: '20 miles', value: 20 },
  { label: '25 miles', value: 25 },
  { label: '30+ miles', value: 0 }
];

export default Vue.extend({
  components: {
    VueGoogleAutocomplete,
    PatientAppointmentBookingCallMeBack,
    PatientAppointmentBookingCallMeBackInfo,
  },
  name: 'BookingClinics',
  props: {
    accessCode: {
      type: String,
      default: ''
    },
    appointment: {
      type: Object,
      default: () => {}
    }, 
    visible: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    googleMapsApiKeyLoaded: false,
    distances: distances,
    clinicsLoading: true,
    clinics: null,
    clinicsMapImageName: null,
    filteredClinicIdsByName: [],
    filteredClinicRegions: [],
    selectedPlace: null,
    selectedClinic: null,
    distance: 0,
    showIconCurrentLocation: true,
    showIconClearLocation: false,
    googleMapsDialog: false,
    googleMaps: null,
    googleMapsMarkers: []
  }),
  mounted: function() {
    this.fetchAppointment();
    this.loadGoogleMapsScript();
  },
  computed: {
    ...mapGetters('booking', ['getAppointment']),
    ...mapGetters('booking', {getClinic: 'getClinic'}),
    bookingPossible () {
      return this.clinics && this.clinics.length
    },
    callbackEnabled () {
      return config.standaloneWebBookingCallbackEnabled
    },
    appointmentAccessCode() {
      return this.accessCode || this.$route.params.appointmentAccessCode.replace(/\W/g, '');
    },
    clinicsMapSrc() {
      if (!this.clinicsMapImageName) {
        return null;
      }
      return (
        config.standaloneWebBookingUrl +
        '/init/?type=portal_schedule' +
        '&hash=' +
        config.webBookingOrgHash +
        '&appId=' +
        this.getAppointment.appointmentId +
        '&imageName=' +
        this.clinicsMapImageName +
        '&action=getMap'
      );
    },
    clinicsFiltered() {
      if (!this.clinics) {
        return null;
      }

      let clinicsFiltered = [...this.clinics];

      if (this.filteredClinicIdsByName.length) {
        clinicsFiltered = clinicsFiltered.filter(c =>
          this.filteredClinicIdsByName.includes(c.clinic_id)
        );
      }
      if (this.filteredClinicRegions.length) {
        clinicsFiltered = clinicsFiltered.filter(c =>
          this.filteredClinicRegions.includes(c.region_name)
        );
      }
      if (this.selectedPlace) {
        clinicsFiltered = clinicsFiltered.filter(c => {
          const distance = calculateDistance(
            c.clinic_lat,
            c.clinic_lng,
            this.selectedPlace.latitude,
            this.selectedPlace.longitude,
            'M'
          );
          if (this.distance === 0 || distance < this.distance) {
            return true;
          } else {
            return false;
          }
        });
      }

      this.getClinicsGStaticGMapImageName(clinicsFiltered);

      return clinicsFiltered;
    },
    clinicRegions() {
      if (!this.clinics) {
        return null;
      }

      let clinicRegions = this.clinics.map(c => c.region_name);
      clinicRegions = [...new Set(clinicRegions)];
      clinicRegions = clinicRegions.sort();
      return clinicRegions;
    }
  },
  methods: {
    removeClinicName(clinic) {
      const index = this.filteredClinicIdsByName.indexOf(clinic.clinic_id);
      if (index >= 0) this.filteredClinicIdsByName.splice(index, 1);
    },
    initGoogleMaps() {
      const google = window.google;
      this.googleMaps = new google.maps.Map(
        document.getElementById('google-map__container'),
        {
          center: { lat: 54.6626011, lng: -7.9231074 },
          zoom: 8
        }
      );
    },
    openGoogleMaps() {
      this.googleMapsDialog = true;
      this.$nextTick(() => {
        window.setTimeout(() => {
          this.initGoogleMaps();
          this.addClinicsToGoogleMaps();
        }, 100); //wait until dialog is fully opened
      });
    },
    addClinicsToGoogleMaps() {
      const clinicsArr = this.clinicsFiltered;
      for (let i = 0; i < clinicsArr.length; i++) {
        const google = window.google;
        const clinic = clinicsArr[i];
        const coordinates = this.getClinicLocation(clinic);

        if (coordinates) {
          const marker = new google.maps.Marker({
            position: coordinates.center,
            map: this.googleMaps
          });

          const infoWindow = new google.maps.InfoWindow({
            content: clinic.clinic_name + '  '
          });

          (function(marker, infoWindow) {
            google.maps.event.addListener(marker, 'click', function() {
              infoWindow.open(this.googleMaps, marker);
            });
          })(marker, infoWindow);

          this.googleMapsMarkers.push(marker);
        }
      }
      this.fitClinicsInGoogleMaps();
    },
    getClinicLocation(clinic) {
      const latitude = clinic.clinic_lat;
      const longitude = clinic.clinic_lng;
      if (!latitude || !longitude) {
        return null;
      }
      const google = window.google;
      const latLng = new google.maps.LatLng(latitude, longitude);

      return { center: latLng, zoom: 15 };
    },
    fitClinicsInGoogleMaps() {
      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < this.googleMapsMarkers.length; i++) {
        bounds.extend(this.googleMapsMarkers[i].getPosition());
      }
      this.googleMaps.fitBounds(bounds);
    },
    loadGoogleMapsScript() {
      this.$loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsAPIKey}&libraries=places`
      ).then(() => {
        this.googleMapsApiKeyLoaded = true;
      });
    },
    onGoogleAutocompletePlaceChanged(place) {
      this.selectedPlace = place;
    },
    onGoogleAutocompleteInputChanged(input) {
      if (!input.newVal) {
        this.selectedPlace = null;
        this.showIconCurrentLocation = true;
        this.showIconClearLocation = false;
      } else {
        this.showIconCurrentLocation = false;
        this.showIconClearLocation = true;
      }
    },
    onIconCurrentLocationClicked() {
      navigator.geolocation.getCurrentPosition(
        s => {
          this.$refs.fga.update('Your current location');
          this.selectedPlace = s.coords;
        },
        e => {
        }
      );
    },
    onIconClearLocationClicked() {
      this.$refs.fga.clear();
    },
    onRadioGroupClicked() {
      this.$store.commit('booking/setClinic', this.selectedClinic);
    },
    onAfterContinueClicked() {
      this.$emit('continue')
    },
    fetchAppointment() {
      this.clinicsLoading = true;
      this.$_rest.get(
        `/appointments/${this.appointment.id}`,
        {
          groups:
            'all,appointment_status,appointment_procedure_status,clinic_scan_room,appointment_procedure_procedure,appointment_appointment_procedures,appointment_patient,procedure_scan,procedure_referral_template,appointment_clinic,appointment_preferred_clinic'
        },
        appointmentResponse => {
          this.clinicsLoading = false;

          appointmentResponse.data.appointmentProcedures = appointmentResponse.data.appointmentProcedures
            .filter(ap => ap.statusCode !== 'X' && ap.statusCode !== 'XR');
          
          this.$store.commit('booking/setAppointment', appointmentResponse.data);

          this.fetchClinics();
        },
        () => {
          //error
        },
        { prefixRoutesWithPatientId: true }
      );
    },
    fetchClinics() {
      this.clinicsLoading = true;
      fetch(
        config.standaloneWebBookingUrl +
          '/init/?type=portal_schedule' +
          '&hash=' +
          config.webBookingOrgHash +
          '&appId=' +
          this.getAppointment.id +
          '&regionId=' +
          config.standaloneWebBookingRegionId +
          '&action=getClinics',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          }
        }
      )
        .then(response => response.json())
        .then(response => {
          this.clinicsLoading = false;
          this.clinics = Object.values(response.response.clinics);

          if (this.clinics.length && !this.visible) {
            const preferredClinic = this.clinics
              .find(c => c.clinic_id === this.getAppointment.preferredClinic.id);
            if (preferredClinic) {
              this.$store.commit('booking/setClinic', preferredClinic);
            }
            if (config.webBookingSkipClinicSelection) {
              this.$emit('continue')
            }
          }
        })
        .catch(e => {});
    },
    getClinicsGStaticGMapImageName(clinics) {
      const clinicIdsCSV = clinics.map(c => c.clinic_id).join(',');
      this.clinicsMapImageName = null;
      fetch(
        config.standaloneWebBookingUrl +
          '/init/?type=portal_schedule' +
          '&hash=' +
          config.webBookingOrgHash +
          '&appId=' +
          this.getAppointment.id +
          '&regionId=' +
          config.standaloneWebBookingRegionId +
          '&action=getClinics',
        {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          body: JSON.stringify({ clinicIdsCSV })
        }
      )
        .then(response => response.json())
        .then(response => {
          this.clinicsMapImageName =
            response.response.clinicsGStaticGMapImageName;
        })
        .catch(e => {});
    }
  }
});
</script>


<style scoped>
.google-autocomplete {
  width: 100% !important;
  height: 40px !important;
  padding: 3px 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 4px !important;
  text-indent: 10px;
}

.google-autocomplete:hover,
.google-autocomplete:focus-visible {
  padding: 3px 0 !important;
  outline: none;
}

.icon__current-location,
.icon__clear-location {
  position: absolute;
  top: 4px;
  right: 10px;
  z-index: 2;
}

.static-map__container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-position: center top !important;
}

#google-map__container {
  max-width: 900px;
  height: 600px;
}
</style>
