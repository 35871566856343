<template>
  <simple-layout>
    <div class="schedule">
      <h2 class="mt-8 mb-4 text-center">
        <span class="text-no-wrap">Dostępne terminy badań</span>
        <span> | </span>
        <span class="text-no-wrap">Affidea Wroclaw 1</span>
      </h2>
      <scan-types
        v-on:selectedScanChanged="onScanChanged"
        class="mb-4"
      ></scan-types>
      <slots
        v-if="practitioner && organization"
        :slotsParams="slotsParams"
        :practitionerId="this.practitioner.id"
        :organizationId="this.organization.id"
      ></slots>
    </div>
  </simple-layout>
</template>

<script>
import ScanTypes from '@views/practitioner/schedule/_scanTypes.vue';
import Slots from '@views/practitioner/schedule/_slots.vue';
import { mapGetters } from 'vuex';
import SimpleLayout from '@layouts/simple.vue';

const SLOTS_PARAMS_FOR_WROCLAW_INTEGRATION = {
  MR: {
    clinicScanRoomId: 112,
    procedureId: 2339,
    slotsOnlyForAvailabilityCategoriesIdsCsv: 8
  },
  TK: {
    clinicScanRoomId: 111,
    procedureId: 2340,
    slotsOnlyForAvailabilityCategoriesIdsCsv: 8
  }
};

export default {
  name: 'Schedule',
  components: { SimpleLayout, ScanTypes, Slots },
  data() {
    return {
      slotsParams: null
    };
  },
  computed: {
    ...mapGetters('app', ['practitioner']),
    ...mapGetters('app', ['organization']),
    days() {
      const days = [];
      const startOfWeek = this.startOfWeek.clone();
      const endOfWeek = this.endOfWeek.clone();

      do {
        days.push({
          dateSQL: startOfWeek.format('YYYY-MM-DD'),
          monthNameShort: startOfWeek.format('MMM'),
          dayNameShort: startOfWeek.format('ddd'),
          dayIndex: startOfWeek.format('DD')
        });
      } while (startOfWeek.add(1, 'days').diff(endOfWeek) <= 0);

      return days;
    }
  },
  methods: {
    onScanChanged(selectedScanCode) {
      this.slotsParams = SLOTS_PARAMS_FOR_WROCLAW_INTEGRATION[selectedScanCode];
    }
  }
};
</script>

<style lang="scss">
.schedule {
  max-width: 800px;
  margin: 0 auto;
}
</style>
