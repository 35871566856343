export enum AppMode {
  practitioner = 'practitioner',
  practitionerSchedule = 'practitioner_schedule',
  patient = 'patient',
  patientCancellation = 'patient_cancellation'
}

export enum AuthType {
  practitioner = 'practitioner',
  patient = 'patient',
  user = 'user'
}
