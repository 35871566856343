<template>
  <v-card tile :loading="loading" elevation="10" class="text-h2 pa-3" v-if="!loading">
    <v-list>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-left pa-1">
          <span class="headline pa-5 font-weight-light"
                :style="'border-radius:5px; background-color:' + appointment.status.colorCode.replace('^', '#')">
            {{ (appointment.statusCode === 'FRNOT') ? 'Completed' : appointment.status.name }}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <br/><br/>
          <span class="text-h6">{{ (appointment.appointmentProcedures.length) ? appointment.appointmentProcedures[0].procedure.name : 'n/a'}}</span>
          <br/><br/>
          <v-divider></v-divider>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-list class="transparent">
            <v-list-item class="row">
              <v-list-item-title class="col-md-12">
                <div class="font-weight-bold" style="float:left;width: 150px;">Location:</div>
                <span v-if="appointment.clinic && appointment.clinic.name">
                  <v-icon left>
                    mdi-map-marker
                  </v-icon>
                  {{ appointment.clinic.name }}
                </span>
                <span v-else>
                  <v-icon left>
                    mdi-map-marker-off
                  </v-icon>
                  Not available
                </span>
              </v-list-item-title>
              <v-list-item-title class="col-md-12">
                <div class="font-weight-bold" style="float:left;width: 150px;">Date and Time:</div>
                <span v-if="appointment.date">
                  <v-icon left>
                    mdi-calendar-check
                  </v-icon>
                  {{ formatDateToString(appointment.date) }}&nbsp;
                  {{ (appointment.appointmentProcedures.length && appointment.appointmentProcedures[0].startTime) ? appointment.appointmentProcedures[0].startTime.slice(11, 16) : 'n/a' }}
                </span>
                <span v-else>
                  <v-icon left>
                    mdi-calendar-remove
                  </v-icon>
                  Not scheduled
                </span>
              </v-list-item-title>
              <v-list-item-title class="col-md-12">
                <div class="font-weight-bold" style="float:left;width: 150px;">Appointment ID:</div>
                <span v-if="appointment.appointmentProcedures.length && appointment.appointmentProcedures[0].idHis">
                  {{appointment.appointmentProcedures[0].idHis}}
                </span>
                <span v-else>
                  n/a
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    </v-list>
    <v-card-text>
      <div v-if="appointment.statusCode === 'X' && linked">
        To reschedule your appointment, please contact us on one of the numbers shown under <a href="https://vaccinations.covid-19.hscni.net/info" target="_blank">https://vaccinations.covid-19.hscni.net/info</a>
      </div>
      <div v-if="cancelAllowed()">
        <span class="text-h6 pr-5">Actions:</span>
        <v-btn outlined tile color="error" @click="dialog = true"
               :loading="loading">Cancel
        </v-btn>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600"
        >
          <v-card>
            <v-card-title class="headline">
              <span style="word-break: break-word" v-if="!linked">Are you sure you wish to cancel your appointment?</span>
              <span style="word-break: break-word" v-else>Are you sure you wish to cancel your follow-up appointment?</span>
            </v-card-title>
            <v-card-text><span class="font-weight-bold" style="color:red">Important!&nbsp;</span>
              <span style="word-break: break-word" v-if="!linked">
                You will need to book new appointments at <a href="https://vaccinations.covid-19.hscni.net" target="_blank">https://vaccinations.covid-19.hscni.net</a>
              </span>
              <span v-else>
                Please contact us by phone to reschedule your appointment. Contact details will be shown after cancellation.
              </span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  outlined
                  tile
                  @click="dialog = false"
              >
                No, go back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  outlined
                  tile
                  :disabled="cancelled"
                  @click="cancelAppointment(appointment.id)"
              >
                Yes, cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!--   Linked appointments   -->
      <div v-if="childProcedures.length">
        <br/><br/>
        <span class="headline">Linked appointments<br/><br></span>
        <v-divider></v-divider>
        <div class="pa-5">
          <div v-if="childProcedures.length">
            <br/><br/>
            <AppointmentCardComponent
                v-for="p in childProcedures"
                :linked="true"
                :key="p.id"
                :appointment="p.appointment"
                :parent-appointment-status-code="appointment.statusCode">
            </AppointmentCardComponent>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'AppointmentCardComponent',
  props: {
    appointment: Object,
    upcoming: Boolean,
    linked: {
      type: Boolean,
      default: false
    },
    parentAppointmentStatusCode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      cancelled: false,
      childProcedures: [],
      dialog: false
    }
  },
  computed: {},
  methods: {
    cancelAllowed () {
      if (this.parentAppointmentStatusCode && this.parentAppointmentStatusCode === 'C') {
        return false;
      }

      let allowed = false;
      if (['C'].includes(this.appointment.statusCode)) {
        allowed = true;
      }

      if (this.appointment.date && this.appointment.appointmentProcedures.length) {
        const today = new Date();
        const appointmentDate = new Date(this.appointment.date.replace(/-/g, '/').slice(0,19).replace('T', ' '));
        const procedureTime = new Date(this.appointment.appointmentProcedures[0].startTime.replace(/-/g, '/').slice(0,19).replace('T', ' '));
        appointmentDate.setHours(procedureTime.getHours(), procedureTime.getMinutes());
        if (appointmentDate < today) {
          allowed = false;
        }
      }

      return allowed;
    },
    formatDateToString (date) {
      return date.slice(0,10)
    },
    cancelAppointment (id) {
      this.dialog = false
      this.loading = true
      this.$_rest.post(`/patients/${this.$store.getters['app/currentUserId']}/appointments/${id}/cancel`, {},
          response => {
            this.cancelled = true
            this.$_notify.info(response.meta)
            setTimeout(() => {
              this.$router.go(this.$router.currentRoute);
            }, 1500);
          }, error => {
            this.$_notify.error(error)
            this.loading = false
          })
    },

    mapProcedure (ap) {
      const apCopy = ap
      const childProc = {}
      childProc.appointment = ap.appointment
      delete apCopy.appointment
      childProc.appointment.appointmentProcedures = [apCopy]
      return childProc
    },

    mapAppointmentStatus(appointment) {
      if (!['C', 'XR', 'FRNOT', 'X'].includes(appointment.statusCode)) {
        appointment.statusCode = 'C';
      }
      if (appointment.statusCode === 'C') {
        appointment.status.colorCode = '^8eff93';
        appointment.status.name = 'Confirmed';
      }
    },

    async getChildProcedures (appointmentProcedureId) {
      this.$_rest.get(`/patients/${this.$store.getters['app/currentUserId']}/appointment-procedures`, {
        'parentAppointmentProcedure': appointmentProcedureId,
        'groups': 'all,appointment_procedure_parent_appointment_procedure,appointment_procedure_procedure,appointment_procedure_appointment,appointment_status,appointment_clinic'
      }, response => {
        response.data.map((ap) => {
          const childProc = this.mapProcedure(ap)
          this.mapAppointmentStatus(childProc.appointment);
          this.childProcedures.push(childProc)
        })
        return response.data
      }, error => {
        this.$_notify.error(error)
      })
    },

    async initAppointment (appointment) {
        await Promise.all(appointment.appointmentProcedures.map(async (ap) => {
          return this.getChildProcedures(ap.id)
        }))
    }
  },
  mounted () {
    this.mapAppointmentStatus(this.appointment)
    this.initAppointment(this.appointment).then(() => {
      this.loading = false
    });
  },
  created () {
  }
}
</script>
