import { Notification } from '@src/interfaces/NotificationInterface'

export interface NotificationState {
  [key: string]: any;
}

const model: Notification = {
  snackbar: null,
  color: 'success',
  text: '',
  timeout: 0
}

const state: NotificationState = {
  ...model
}

const getters = {}

const actions = {}

const mutations = {
  showNotification (state: NotificationState, payload: Notification) {
    if (typeof payload === 'string') {
      payload = { color: 'success', text: payload }
    }
    state.color = payload.color
    state.text = payload.text
    if (typeof payload.timeout !== 'undefined') {
      state.timeout = payload.timeout
    } else {
      state.timeout = 7000
    }
    state.snackbar = true
  },
  hideNotification (state: NotificationState) {
    state.snackbar = false
    state.text = ''
    state.timeout = 7000
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
