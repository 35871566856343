<template>
  <v-container v-if="model">
    <v-expand-transition>
      <v-list>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="4"
            class="py-0"
            v-for="(label, field) in fieldsAllowed"
            :key="field"
          >
            <v-list-item class="pa-0">
              <v-list-item-content class="pa-0">
                <v-list-item-title v-text="getFieldValue(field, label)"></v-list-item-title>
                <v-list-item-subtitle v-text="typeof label === 'object' ? label.caption : label"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

      </v-list>
    </v-expand-transition>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PatientSearchDetails',
  props: {
    value: {
      type: Object
    },
    showSelectedPatientDetails: {
      type: Boolean
    }
  },
  data () {
    return {
      fieldsAllowed:
        {
          number1: 'Patient number', firstName: 'First name', middleName: 'Middle name', lastName: 'Last name',
          dateOfBirth: { caption: 'Date of birth', fn: (val) => this.moment(val).format(this.formatDate) },
          phoneNumber1: 'Phone 1', phoneNumber2: 'Phone 2',
          address1: 'Address 1', address2: 'Address 2', address3: 'Address 3', postCode: 'Post code', town: 'Town'
        }

    }
  },
  mounted () {

  },
  methods: {
    getFieldValue (field, label) {
      if (!this.model[field]) return '-'
      if (typeof label === 'object') {
        return label.fn(this.model[field])
      }
      return this.model[field]
    },
  },
  computed: {
    moment () {
      return moment
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  }
}
</script>
