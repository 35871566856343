<template>
  <div class="text-center">
    <p>You are booking your appointment at:</p>
    <p>
      <span><strong>{{ clinic.clinic_name }}</strong></span><br>
      <span v-if="clinic.clinic_address">{{ clinic.clinic_address }}</span>
    </p>
    <p class="subtitle-2">If you want to book in another clinic, please click on "Call me back to help book my appointment" below. Affidea will be in contact to schedule your appointment. Please note we are currently experiencing a high volume of appointment requests.</p>
  </div>
</template>

<style scoped>
</style>

<script>
import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  name: 'BookingClinics',
  props: {
    clinic: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
  }),
});
</script>
