
import fullscreen from 'vue-fullscreen'
import Vue from 'vue'

import router from './router'
import store from './state/store'
import App from './app.vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSignaturePad from 'vue-signature-pad'
import VueResource from 'vue-resource'
import vuetify from './plugins/vuetify'
import notify from './plugins/notify'
import translate from './plugins/translate'
import i18n from './i18n'
import VueTimeago from 'vue-timeago'
// import '@babel/polyfill'
import '@helpers/mixin'
import VueDateFns from 'vue-date-fns';
import LoadScript from 'vue-plugin-load-script/index';
import moment from 'moment/moment';
moment.locale('en')

// @ts-ignore
Vue.use(LoadScript);
Vue.use(translate)
Vue.use(VueDateFns, 'dd/MM/yyyy');
Vue.use(VueCompositionAPI)
Vue.use(VueSignaturePad)
Vue.use(VueResource)
Vue.use(fullscreen)
Vue.use(notify, {store})
Vue.use(translate)
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en'
})

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  // @ts-ignore
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  // @ts-ignore
  window.__app__ = app
}
