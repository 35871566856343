export default [
  {
    code: 'C',
    name: 'Confirmed',
    patientText: 'Scheduled',
    icon: 'mdi-calendar-account',
    color: 'light-green lighten-2',
    textColor: 'black',
    order: 2,
    showOnWaitlist: true
  },
  {
    code: 'A',
    name: 'Arrived',
    patientText: 'Please wait to be called',
    icon: 'mdi-timer-sand',
    color: 'blue lighten-4',
    textColor: 'black',
    order: 1,
    showOnWaitlist: true
  },
  {
    code: 'G',
    name: 'Called',
    patientText: 'Proceed to ',
    icon: 'mdi-walk',
    color: 'light-blue',
    textColor: 'white',
    order: 3,
    showOnWaitlist: true
  },
  {
    code: 'O',
    name: 'On the way',
    patientText: 'I\'m on my way',
    icon: 'mdi-walk',
    color: 'light-blue darken-3',
    textColor: 'white',
    order: 4,
    showOnWaitlist: true
  },
  {
    code: 'IP',
    name: 'In Progress',
    patientText: 'In progress',
    icon: 'mdi-progress-clock',
    color: 'amber lighten-3',
    textColor: 'black',
    order: 5,
    showOnWaitlist: true
  },
  {
    code: 'F',
    name: 'Finished',
    patientText: 'Finished',
    icon: 'mdi-check-circle-outline',
    color: 'light-green darken-4',
    textColor: 'white',
    order: 6,
    showOnWaitlist: true
  }
]
