<template>
  <v-container>
    <v-row
      justify="center"
      align="center"
      class=""
    >
      <v-col cols="12">
        <div class="text-h3 mb-6 text-center font-weight-light">
          <p>{{ $_t('Hello') }} <span v-if="config.showPatientDetails">{{ patientFullName }}</span></p>
        </div>
        <div class="mb-6 text-center">
          <h5 class="text-h5 mb-2">
            {{ $_t('Welcome to the patient registration and booking portal.') }}
          </h5>
          <p>
            {{ $_t('Please fill out all of the required information. Please ensure all details entered here are correct as any inaccuracy can result in a delay in receiving your appointment.') }}
          </p>
          <p v-if="$_t('Welcome paragraph 2')">
            {{ $_t('Welcome paragraph 2') }}
          </p>
          <p v-if="$_t('Welcome paragraph 3')">
            {{ $_t('Welcome paragraph 3') }}
          </p>
          <p v-if="!config.patientEmailHide">
            {{ $_t('A valid email address is required to complete the registration process. Please enter or update your email address below:') }}
          </p>
          <p v-if="!config.patientEmailHide">
            <v-text-field
              v-model="email"
              :label="$_t('Email address')"
              type="email"
              outlined
              :rules="[ v => !v || emailPattern.test(v) || $_t('Invalid Email address') ]"
              :error="emailError"
              @change="updateEmailAddress"
            />
          </p>
          <p>
            {{ $_t('By clicking Next you agree to our') }}
            <a
              :href="config.privacyPolicyLink"
              target="_blank"
            >{{ $_t('privacy policy') }}</a>{{ config.termsLink ? ' ' + $_t('and_tos') : '' }}&nbsp;<a
              v-if="config.termsLink"
              :href="config.termsLink"
              target="_blank"
            >{{ $_t('terms of service') }}</a>.
          </p>
          <p>
            {{ $_t('Please note that we do not use any Cookies other than necessary ones to perform the registration process.') }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import config from '@src/config'

export default defineComponent({
  name: 'AppointmentWelcome',
  props: {
    options: { type: Object, default: {} }
  },
  setup (props, ctx) {
    const appointment = computed(() => ctx.root.$store.getters['appointment/appointment'])
    const email = ref(appointment.value.patient.emailAddress1)
    const emailError = ref(false)
    const emailPattern = ref(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    const init = () => {
      ctx.root.$store.commit('app/setAppBarTitle', 'Welcome')
      ctx.root.$store.commit('app/setAppBarButton1', null)
      ctx.root.$store.commit('app/setAppBarButton2', {
        label: 'Next',
        icon: 'mdi-chevron-right',
        click: () => {
          if (config.patientEmailMandatory) {
            if (!email.value || !email.value.trim() || !email.value.trim().match(emailPattern.value)) {
              emailError.value = true
            ctx.root.$_notify.error('Please provide a valid email address')
              return
            }
          }
          emailError.value = false
          ctx.emit('next')
        }
      })
      ctx.root.$store.dispatch('app/appBarShow')
      ctx.root.$emit('scrollToTop')
    }

    const updateEmailAddress = () => {
      if (email.value.trim() && email.value.match(emailPattern)) {
        ctx.root.$_rest.put(`/appointments/${appointment.value.id}/patient`, { 'emailAddress1': email.value }, undefined, undefined, error => {
          ctx.root.$_notify.error(error.errors[0].message)
        }, { prefixRoutesWithPatientId: true })
      }
    }

    const patientFullName = computed(() => {
      return ctx.root.$store.getters['appointment/patientFullName']
    })

    const getOptions = computed(() => {
      return props.options
    })

    init()

    return {
      email,
      emailError,
      emailPattern,
      patientFullName,
      getOptions,
      updateEmailAddress,
      init,
      config
    }
  }
})
</script>
