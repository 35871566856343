export default [
  {
    value: 'appointmentId',
    text: 'Appointment ID',
    align: 'left',
    visible:false

  },
  {
    value: 'patientId',
    text: 'Patient ID',
    align: 'left',
    visible:false

  },
  {
    value: 'organizationId',
    text: 'Organization Id',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicNameShort',
    text: 'Site Name',
    align: 'left',
    visible:true

  },
  {
    value: 'appointmentDate',
    text: 'Appointment Date',
    align: 'left',
    visible:true
  },
  {
    value: 'appointmentDateCreated',
    text: 'Appointment Date Created',
    align: 'left',
    visible:false
  },
  {
    value: 'cancelReason',
    text: 'Cancel Reason',
    align: 'left',
    visible:true
  },
  {
    value: 'appointmentSpecialRequestSummary',
    text: 'Special Request',
    align: 'left',
    visible:false
  },
  {
    value: 'procedureName',
    text: 'Procedure Name',
    align: 'left',
    visible:true
  },
  {
    value: 'insurerName',
    text: 'Insurer Name',
    align: 'left',
    visible:true
  },
  {
    value: 'clinicScanRoomCode',
    text: 'Scan Room Code',
    align: 'left',
    visible:false
  },
  {
    value: 'duration',
    text: 'Duration',
    align: 'left',
    visible:false
  },
  {
    value: 'startTime',
    text: 'Start Time',
    align: 'left',
    visible:true
  },

  {
    value: 'appointmentStatusName',
    text: 'Status Name',
    align: 'left',
    visible:true

  },
  {
    value: 'appointmentDocumentStatusName',
    text: 'Document Status Name',
    align: 'left',
    visible:false

  },
  {
    value: 'appointmentSourceName',
    text: 'Source Name',
    align: 'left',
    visible:false

  },
  {
    value: 'appointmentProtocollingStatusName',
    text: 'Protocolling Status Name',
    align: 'left',
    visible:false

  },
  {
    value: 'appointmentCategoryName',
    text: 'Category Name',
    align: 'left',
    visible:false

  },
  {
    value: 'appointmentPriorityName',
    text: 'Priority Name',
    align: 'left',
    visible:false

  },
  {
    value: 'appointmentStatusColorCode',
    text: 'Status Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentStatusColorCode',
    text: 'Document Status Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentSourceColorCode',
    text: 'Source Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProtocollingStatusColorCode',
    text: 'Protocolling StatusName Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentCategoryColorCode',
    text: 'Category Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentPriorityColorCode',
    text: 'Priority Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'patientDateOfBirth',
    text: 'Date Of Birth',
    align: 'left',
    visible:true

  },
  {
    value: 'patientFullName',
    text: 'Patient Name',
    align: 'left',
    visible:true

  },
  {
    value: 'patientIdExternal',
    text: 'BHR No',
    align: 'left',
    visible:true

  },
  {
    value: 'patientNumber1',
    text: 'NHS No',
    align: 'left',
    visible:true

  },
];
