export function mapDatasetResponse (data) {
  const mappedData = []
  data.data.map(row => {
    const mappedRow = {}
    row.map((entry, index) => {
      mappedRow[data.meta.columns[index]] = entry
    })
    mappedData.push(mappedRow)
  })
  return mappedData
}

export function toBoolean(value) {
  switch ((value || '').toString().toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case 'on':
    case '1':
      return true

    case 'false':
    case 'no':
    case 'off':
    case '0':
    case null:
      return false
    default:
      return Boolean(value)
  }
}
