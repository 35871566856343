<template>
  <v-container>
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        item-text="fullName"
        item-value="id"
        :label="$_t('Search a patient')"
        :placeholder="$_t('Start typing to search')"
        prepend-inner-icon="mdi-account-search"
        return-object
        clearable
        @keyup="searchPatients"
        @keydown="isTyping = true"
        @blur="isTyping = false"
      >
      <template v-slot:no-data>
        <v-list-item v-if="isLoading">
          Loading...
        </v-list-item>
        <v-list-item v-else-if="isTyping">
          ...
        </v-list-item>
        <v-list-item v-else-if="searchVal && (!items || !items.length)">
          No patients found
        </v-list-item>
        <v-list-item v-else>
          Start typing to search for patients
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">

        <v-list-item-action>
          <v-icon>mdi-account</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="`${item.fullName || ''}${item.number1 ? ' (' +item.number1+')' : ''}, born: ${ item.dateOfBirth ? moment(item.dateOfBirth).format(formatDate) : '' }`"></v-list-item-title>
          <v-list-item-subtitle v-text="`${item.fullAddress || ''} ${item.fullPhoneNumbers || ''}`"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <div v-if="showSelectedPatientDetails">
      <v-divider></v-divider>
      <v-expand-transition>
        <v-list
          v-if="model"
        >
          <v-list-item
            v-for="(label, field) in fieldsAllowed"
            :key="field"
          >
            <v-list-item-content>
              <v-list-item-title v-text="getFieldValue(field, label)"></v-list-item-title>
              <v-list-item-subtitle v-text="typeof label === 'object' ? label.caption : label"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expand-transition>
      <v-card-actions>
        <v-btn
          :disabled="!timesSearched"
          color="primary"
        >
          <v-icon right>
            mdi-plus
          </v-icon>
          {{ $_t('Add Patient') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!model"
          color="primary"
        >
          {{ $_t('Proceed') }}
          <v-icon right>
            mdi-arrow-right-circle
          </v-icon>
        </v-btn>
      </v-card-actions>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PatientSearch',
  props: {
    value: {
      type: Object
    },
    showSelectedPatientDetails: {
      type: Boolean
    }
  },
  data () {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      search: null,
      searchTimeout: null,
      searchVal: '',
      timesSearched: 0,
      isTyping: false,
      fieldsAllowed:
        {
          number1: 'Patient number', firstName: 'First name', middleName: 'Middle name', lastName: 'Last name',
          dateOfBirth: { caption: 'Date of birth', fn: (val) => this.moment(val).format(this.formatDate) },
          phoneNumber1: 'Phone 1', phoneNumber2: 'Phone 2',
          address1: 'Address 1', address2: 'Address 2', address3: 'Address 3', postCode: 'Post code', town: 'Town'
        }

    }
  },
  mounted () {

  },
  methods: {
    getFieldValue (field, label) {
      if (!this.model[field]) return '-'
      if (typeof label === 'object') {
        return label.fn(this.model[field])
      }
      return this.model[field]
    },
    searchPatients () {
      if (!this.searchVal || this.searchVal.length < 2) {
        return
      }
      window.clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.isTyping = false
        if (this.isLoading) {
          return
        }
        this.isLoading = true

        this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/patients`, {
          groups: 'all,test',
          search: this.searchVal
        }, data => {
          this.isLoading = false
          this.timesSearched++
          this.$emit('search', { searchVal: this.searchVal, data: data.data})
          this.entries = data.data
        }, error => {
          this.isLoading = false
          this.$_notify.error(error)
        })
      }, 300)
    }
  },
  computed: {
    moment () {
      return moment
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('clear-storage')
      }
    },
    items () {
      return this.entries.map(entry => {
        const fullName = [entry.title, entry.firstName, entry.lastName].filter(el => el).join(' ')
        const fullAddress = [entry.address1, entry.address2, entry.address3, entry.postCode, entry.town].filter(el => el)
          .join(', ')
        const phoneNumbers = [entry.phoneNumber1, entry.phoneNumber2].filter(el => el)
        const fullPhoneNumbers = phoneNumbers.length ? '| phone: ' + phoneNumbers.join(', ') : ''
        return Object.assign({}, entry, { fullName, fullAddress, fullPhoneNumbers })
      })
    }
  },

  watch: {
    search (val) {
      this.searchVal = val
    }
  }
}
</script>
