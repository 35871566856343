<template>
  <v-container fluid>
    <practitioner-layout
    >
      <v-app-bar
        app
        clipped-left
        color="primary"
        dark
      >
        <v-toolbar-title class="">
          Live status
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col
            cols="12"
            sm="6"

          >
        <v-menu

          v-model="menuDate"
          :close-on-content-click="false"
          max-width="290"
          class="mr-6 mt-4 float-right"
        >
          <template v-slot:activator="{ on, attrs }">

            <v-text-field
            style="width:200px;"
             class="mr-6 mt-4 float-right"
              :value="computedDateFormatted"
              label="Date"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>

          </template>
          <v-date-picker
            v-model="date"
            @change="menuDate = false"
          ></v-date-picker>
        </v-menu>
        </v-col>

        <v-switch
         class="mt-6"
      v-model="patientDetails"
      label="Show patient details"
    ></v-switch>
    <v-btn
      class="ml-6"
      fab
      dark
      small
      color="primary"
      @click="toggleFullScreen"
    >
      <v-icon dark>
       mdi-overscan
      </v-icon>
    </v-btn>


      </v-app-bar>

      <v-layout
        class="mt-12 fullScreen"
        v-if="items"
      >
        <v-row>
          <v-col cols="3">
            <div class="text-h4">New</div>
            <v-col
              cols="12"
              v-for="(item, index) in listNew"
              :key="index"
            >
              <v-card
                elevation="8"
                :color="item.appointmentStatusColorCode.replace('^','#')"
              >
                <v-card-title class="mt-0">
                  {{ item.patientNumber1 }}
                </v-card-title>
                <v-card-subtitle v-if="getPatientDetails">
                  <div class="text-h5">{{`${item.title ? item.title : ''} ${item.firstName} ${item.lastName}`}}</div>

                </v-card-subtitle>
                <v-card-text>
                  <div class="text-h6">
                   {{ item.procedureName }}
                  </div>
                  <div class="text-h6">
                    {{ item.appointmentStatusName }}
                  </div>
                  <div class="text-h6">
                    Ordered: <timeago :datetime="item.appointmentCreatedDatetime"></timeago>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
          <v-col cols="3">
            <div class="text-h4">In Progress</div>
            <v-col
              cols="12"
              v-for="(item, index) in listIP"
              :key="index"
            >
              <v-card
                elevation="8"
                :color="item.appointmentStatusColorCode.replace('^','#')"
              >
                <v-card-title>
                  {{ item.patientNumber1 }}
                </v-card-title>
                <v-card-subtitle v-if="getPatientDetails">
                   <div class="text-h5">{{`${item.title ? item.title : ''} ${item.firstName} ${item.lastName}`}}</div>
                </v-card-subtitle>
                <v-card-text>
                  <div class="text-h6">
                    {{ item.procedureName }}
                  </div>
                  <div class="text-h6">
                    {{ item.appointmentStatusName }}
                  </div>
                  <div class="text-h6">
                    Ordered: <timeago :datetime="item.appointmentCreatedDatetime"></timeago>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
          <v-col cols="3">
            <div class="text-h4">Performed</div>
            <v-col
              cols="12"
              v-for="(item, index) in listPerformed"
              :key="index"
            >
              <v-card
                elevation="8"
                :color="item.appointmentStatusColorCode.replace('^','#')"
              >
                <v-card-title>
                  {{ item.patientNumber1 }}
                </v-card-title>
                <v-card-subtitle v-if="getPatientDetails">
                  <div class="text-h5">{{`${item.title ? item.title : ''} ${item.firstName} ${item.lastName}`}}</div>
                </v-card-subtitle>
                <v-card-text>
                  <div class="text-h6">
                   {{ item.procedureName }}
                  </div>
                  <div class="text-h6">
                    {{ item.appointmentStatusName }}
                  </div>
                  <div class="text-h6">
                    Ordered: <timeago :datetime="item.appointmentCreatedDatetime"></timeago>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
          <v-col cols="3">
            <div class="text-h4">Reported</div>
            <v-col
              cols="12"
              v-for="(item, index) in listRep"
              :key="index"
            >
              <v-card
                elevation="8"
                :color="item.appointmentStatusColorCode.replace('^','#')"
              >
                <v-card-title>
                  {{ item.patientNumber1 }}
                </v-card-title>
                <v-card-subtitle v-if="getPatientDetails">
                  <div class="text-h5">{{`${item.title ? item.title : ''} ${item.firstName} ${item.lastName}`}}</div>
                </v-card-subtitle>
                <v-card-text>
                  <div class="text-h6">
                   {{ item.procedureName }}
                  </div>
                  <div class="text-h6">
                    {{ item.appointmentStatusName }}
                    <br>
                    Ordered: <timeago :datetime="item.appointmentCreatedDatetime"></timeago>
                  </div>
                  <div class="text-h6">

                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
        </v-row>
      </v-layout>

    </practitioner-layout>
  </v-container>
</template>
<script>

import PractitionerLayout from '@layouts/practitioner.vue'
import moment from 'moment'
export default {
  name: 'PractitionerTiles',
  components: { PractitionerLayout },
  computed: {
    listNew () {
      return this.items.filter(el => el.appointmentStatusCode === 'N')
    },
    listIP () {
      return this.items.filter(el => ['P', 'PC', 'C', 'S', 'AAP', 'A', 'AIP'].includes(el.appointmentStatusCode))
    },
    listPerformed () {
      return this.items.filter(el => ['IP', 'F'].includes(el.appointmentStatusCode))
    },
    listRep () {
      return this.items.filter(el => ['FIP', 'FAP'].includes(el.appointmentStatusCode))
    },
    list () {
      if (!this.items) {
        return []
      }

      return this.items
    },
    getPatientDetails() {
      return this.patientDetails
    },
    computedDateFormatted () {
        return this.date ? moment(this.date).format('YYYY-MM-DD') : ''
      },

  },
  data () {
    return {
      items: [],
      loading: false,
      to: null,
      patientDetails:false,
      fullscreen: false,
      date: moment(new Date().toISOString()).format('YYYY-MM-DD'),
      menuDate: false,
    }
  },
  methods: {
    toggleFullScreen () {
      this.$fullscreen.toggle(this.$el.querySelector('.fullScreen'), {
        wrap: true,
        exitOnClickWrapper:true,
        support:true,
        background:'#FFF',
        callback: this.fullscreenChange
      })
    },
      fullscreenChange (fullscreen) {
        this.fullscreen = fullscreen
      },
    getData () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/appointments`, {
        filter: { practitionerPracticeId: this.$store.getters['app/practitionerPractice'].id,
        createdDateFrom: { x: this.computedDateFormatted, type: 'gt' } ,
        createdDateTo: { x: moment(this.computedDateFormatted).add(1,'days').format('YYYY-MM-DD'), type: 'lt' } ,
        }
      }, response => {

        this.loading = false
        this.items = this.mapDatasetResponse(response)
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },
    init() {
      this.getData()
      this.to = setInterval(() => {
      this.getData()
    }, 10000)
    }
  },
  watch:{
    date(dateValue){
      clearInterval(this.to)
      this.init();
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    clearInterval(this.to)
  }
}
</script>
