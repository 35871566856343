<template>
  <v-container fluid>
    <div v-if="value">
      <v-list subheader>
        <div
          v-for="(procedure, index) in value"
          :key="index"
        >
          <v-list-item>
            <v-list-item-title>
              <b>{{ procedure.procedure.scan && procedure.procedure.scan.nameShort }}</b> - {{ procedure.procedure.name }}
            </v-list-item-title>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    dark
                    small
                    color="primary"
                    @click="$emit('procedureRemove', procedure.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Remove examination</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="index + 1 < value.length"
            :key="index"
          />
        </div>
      </v-list>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ProcedureList',
  props: {
    value: {
      type: Array
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>

</style>