import _Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'

export interface NotifyInterface {
  info: (msg: string) => void;
  error: (msg: string) => void;
  critical: (msg: string) => void;
  log: (msg: string) => void;
}

const notify = {
  install (Vue: typeof _Vue, {store}) {
    Vue.prototype.$_notify = {
      info (msg) {
        store.commit('notification/showNotification', { color: 'info', text: msg })
      },
      success (msg) {
        store.commit('notification/showNotification', { color: 'success', text: msg })
      },
      error (msg) {
        store.commit('notification/showNotification', { color: 'error', text: msg })
      },
      critical (msg) {
        console.error(msg)
      },
      log (msg) {
        console.warn(msg)
      }
    } as NotifyInterface
  }
}

export default notify
