<template>
  <RouteDialog>

    <v-container fluid >
       <v-row>
            <v-col cols="12" class="ml-6">
                <v-btn class="float-left" :to="`/practitioner/appointment/new/patientId/${patientId}`">
                    {{ $_t('New order') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <grid
            :data="getPatientAppointments"
            :loading="loading"
            :showAddRow="false"
            :headers-preset-type="headersPresetType"
            :headers-preset="headersPreset"
            @refresh="fetchPatientAppointments"
        ></grid>
          </v-col>
        </v-row>
      </v-container>

  </RouteDialog>
</template>
<script>
import RouteDialog from '@components/core/RouteDialog.vue'
import Grid from '@components/common/Grid.vue'
import appointmentsHeadersPreset from '@src/data/dataset/gridHeaders/appointments';


export default {
name:'PatientEpisodeGridDetails',
  components: { RouteDialog,Grid },
  data () {
    return {
      patientId:null,
      headersPresetType:'appointments',
      headersPreset:appointmentsHeadersPreset,
      loading:false,
      patientAppointments:[],

    }
  },
  props:{
    id:{
      type:String,
      default:null
    }
  },
  computed: {
    getPatientAppointments() {
      return this.patientAppointments
    }
  },
  methods:{
    setParams(){
      this.patientId = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : this.id
      return this.patientId ? true : false
    },
    processRouteParam() {
      if(this.setParams()){
        this.fetchPatientAppointments()
      } else {
        this.$_notify.error(this.$_t('Wrong params'))
        this.loading = false
      }

    },
    fetchPatientAppointments () {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/patient-appointments`, {
        filter: {
          patientId: { x: this.patientId, type: 'eq' }
        }
      }, (data) => {
          this.loading = false
          this.patientAppointments = this.mapDatasetResponse(data)

        }, error => {
          reject(error)
          this.loading = false

        })
      })
    },

  },
  mounted(){
    this.processRouteParam()
  },
}
</script>
