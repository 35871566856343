export enum PractitionerRoles {
  // Superadmin
  superAdmin = 'ROLE_FRONTEND_SUPERADMIN',
  home = 'ROLE_FRONTEND_PRACTITIONER_HOME',
  new = 'ROLE_FRONTEND_PRACTITIONER_NEW',
  orders = 'ROLE_FRONTEND_PRACTITIONER_ORDERS',
  alerts = 'ROLE_FRONTEND_PRACTITIONER_ALERTS',
  episodes = 'ROLE_FRONTEND_PRACTITIONER_EPISODES',
  tiles = 'ROLE_FRONTEND_PRACTITIONER_TILES',

  systemAdmin = 'ROLE_FRONTEND_PRACTITIONER_SYSTEMADMIN'
}
