<template>
  <RouteDialog>
    <v-container>
        <v-row>
          <v-col cols="12">
            <v-textarea v-if="checkRequiredNote"
            counter
            :label="`${$_t('Add note')} *`"
            v-model="note"
          ></v-textarea>
          <v-row>
            <v-col cols="8" class="pa-2">
            <v-btn v-if="checkAlertStatus"
            class="mr-2"
              @click="saveAlert"
              color="primary"
              :loading="buttonLoading"
              :disabled="!note && checkRequiredNote"
            >
              <v-icon
                left
              >
                mdi-check
              </v-icon>
              {{ checkRequiredNote ? $_t('Acknowledge and Reply') : $_t('Acknowledge')}}
            </v-btn>


            <v-btn v-if="checkAppointmentStatus"
              @click="saveCallQueue"
              color="secondary"
              :loading="buttonLoading"
              :disabled="!note && checkRequiredNote"
            >
              <v-icon
                left
              >
                mdi-check
              </v-icon>
              {{ $_t('Request Cancellation') }}
            </v-btn>
            </v-col>
          </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <grid
              vertical
              :data="activeRowData"
              :headers-preset-type="headersPresetType"
              :headers-preset="headersPreset"
              hide-filters
              hide-search
              :loading="loading"
            ></grid>
          </v-col>
        </v-row>
      </v-container>
  </RouteDialog>
</template>
<script>
import RouteDialog from '@components/core/RouteDialog.vue'
import { mapGetters } from 'vuex'
import alertsHeadersPreset from '@src/data/dataset/gridHeaders/alerts';
import Grid from '@components/common/Grid.vue'
import config from '@config'

const alertStatuses = ['A']
const appointmentStatuses = ['N','RI','AC']


export default {
  name:'AlertGridDetails',
  components: { RouteDialog,Grid },
  data () {
    return {
      dialog:true,
      headersPresetType:'alerts',
      headersPreset: alertsHeadersPreset,
      activeRow:null,
      buttonLoading:false,
      alertId:null,
      loading:false,
      note:null
    }
  },
  props:{
    id:{
      type:String,
      default:null
    }
  },
  computed: {
    ...mapGetters('alerts', {items: 'data' }),
    ...mapGetters({
      alertColumns: 'app/columns'
    }),
    activeRowData() {
      if (this.activeRow) {
        return [this.activeRow]
      }
      return []
    },
    checkAlertStatus(){
      return this.activeRow && !alertStatuses.includes(this.activeRow.alertStatusCode)
    },
    checkAppointmentStatus(){
      if(config.callPurposeApponitmentCancelRequestId != '' && config.callSourceApponitmentCancelRequestId != '') {
        return this.activeRow && appointmentStatuses.includes(this.activeRow.appointmentStatusCode) && this.toBoolean(this.activeRow.isAppointmentCancelRequested == false)
      }
      return false
    },
    checkRequiredNote(){
      if(this.checkAppointmentStatus && this.activeRow && this.toBoolean(this.activeRow.isNoteRequired)) {
        return true
      } else if(this.checkAlertStatus && this.activeRow && this.toBoolean(this.activeRow.isNoteRequired)) {
        return true
      } else {
        return false
      }
    },

  },
  methods:{
    getNote() {
       if(this.note) {
        return {note:this.note,appointmentId:this.activeRow.appointmentId}
      }
      return {}
    },

    saveCallQueue() {
      this.loading = true
      this.buttonLoading = true
      this.$_rest.put(
          `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.activeRow.appointmentId }/request-cancel`,
          {
            callPurposeId: config.callPurposeApponitmentCancelRequestId,
            callSourceId: config.callSourceApponitmentCancelRequestId,
            alertRecipientId: this.activeRow.alertRecipientId
          },
          undefined,
          () => {
            this.buttonLoading = false
            this.loading = false
            this.$_notify.success(this.$_t('Cancellation request was successfully sent'))
            this.$root.$emit('refresh-alerts-grid','RequestCancel')
            if(this.checkRequiredNote) {
              this.saveAlert()
              return
            }
            this.$router.back()
          },
          error => {
            this.$_notify(error)
            this.buttonLoading = false
            this.loading = false
          }
        )

      setTimeout(() => {
        this.buttonLoading = false
      }, 2000);
    },
    saveAlert(){
      this.buttonLoading = true
      this.loading = true
      this.$_rest.put(
          `/practitioners/${ this.$store.getters['app/practitioner'].id }/alert-recipients`,
          {
            isRead: 1,
            practitionerPractice:this.$store.getters['app/practitionerPractice']?.id,
            ...this.getNote()
          },
          this.activeRow.alertRecipientId,
          (res) => {
           this.advanceAppointment().then(() => {
              this.buttonLoading = false
              this.loading = false
              Object.keys(this.getNote()).length === 0 ? this.$_notify.success(this.$_t('Alert has been successfully acknowledged')) : this.$_notify.success(this.$_t('Alert acknowledgement and note has been successfully sent'))
              this.$root.$emit('refresh-alerts-grid','Acknowledged')
              this.$router.back()
          }).catch(error=>{
            this.buttonLoading = false
            this.loading = false
          })


          },
          error => {
            this.$_notify(error)
            this.buttonLoading = false
            this.loading = false
          }
        )
    },
    advanceAppointment () {
      return new Promise((resolve, reject) => {
        this.$_rest.put(
          `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.activeRow.appointmentId }/advance`,
          {},
          undefined,
          data => resolve(data),
          error => reject(error)
        )
      })
    },
    setParams(){
      this.alertId = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : this.id
      return this.alertId ? true : false
    },
    processRouteParam() {
      if(this.setParams()){
        this.activeRow = this.items.find(item=>item.alertId === this.alertId)
        this.buttonLoading = false
        this.loading = false
      } else {
        this.$_notify.error(this.$_t('Wrong params'))
        this.loading = false
      }
    }
  },
  watch:{
    items(val){
      this.loading = true
       if(val.length) {
        this.processRouteParam()
      }
    }

  },
  mounted(){
    this.processRouteParam()
  },
  created(){
    this.loading = true
  }
}
</script>
