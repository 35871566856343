<template>
  <patient-layout>
    <patient-appointment-booking-slots-within-hour></patient-appointment-booking-slots-within-hour>
  </patient-layout>
</template>

<script>
import PatientLayout from '@router/layouts/patient.vue'
import PatientAppointmentBookingSlotsWithinHour from '@components/appointment/booking/slots/SlotsWithinHour.vue'

export default {
  components: {
    PatientLayout,
    PatientAppointmentBookingSlotsWithinHour
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped></style>
