


























































import { computed, defineComponent, ref } from '@vue/composition-api'
import useCommonHelpers from '../../helpers/useCommonHelpers'

export default defineComponent({
  name: 'UserProfile',

  setup () {
    const { getCurrentPractitioner, getCurrentUser, logout } = useCommonHelpers()
    const menu = ref(false)
    const user = computed(() => {
      return getCurrentPractitioner()
    })
    const currentUser = computed(() => {
      return getCurrentUser()
    })

    const userFullName = computed(() => {
      return [user.value.firstName, user.value.lastName].join(' ')
    })

    const userLogout = () => {
      logout()
    }

    return {
      menu,
      userFullName,
      user,
      userLogout,
      currentUser
    }
  }
})
