import { getCurrentInstance } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import auth from '@src/helpers/auth'

export default function () {
  const vm = getCurrentInstance() as any
  const getCurrentUser = () => {
    const { useGetters } = createNamespacedHelpers(vm?.proxy.$store, 'app')
    const { currentUser } = useGetters(['currentUser'])
    return currentUser.value
  }

  const getCurrentPractitioner = () => {
    const { useGetters } = createNamespacedHelpers(vm?.proxy.$store, 'app')
    const { practitioner } = useGetters(['practitioner'])
    return practitioner.value
  }


  const getCurrentPatient = () => {
    const { useGetters } = createNamespacedHelpers(vm?.proxy.$store, 'app')
    const { patient } = useGetters(['patient'])
    return patient.value
  }

  const getUser = () => {
    const { useGetters } = createNamespacedHelpers(vm?.proxy.$store, 'app')
    const { user } = useGetters(['user'])
    const userObject = user.value.data
    return {...userObject , ...getCurrentUser()}
  }

  const getPractitioner = () => {
    const { useGetters } = createNamespacedHelpers(vm?.proxy.$store, 'app')
    const { practitioner } = useGetters(['practitioner'])
    const practitionerObject = practitioner.value
    const practitionerAll = {...practitionerObject , ...getCurrentUser()}
    return practitionerAll
  }

  const getPractitionerId = () => {
    return getPractitioner().id
  }

  const getPatient = () => {
    const { useGetters } = createNamespacedHelpers(vm?.proxy.$store, 'app')
    const { patient } = useGetters(['patient'])
    const patientObject = patient.value
    const patientAll = {...patientObject , ...getCurrentUser()}
    return patientAll
  }

  const getAuthType = () => {
    const currentUser  = getCurrentUser()
    return currentUser.authType
  }

  const logout = () => {
      auth.logout()
  }

  const toBoolean = (value: boolean | string | 0 | 1): boolean =>{
    switch ((value || '').toString().toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case 'on':
      case '1':
        return true

      case 'false':
      case 'no':
      case 'off':
      case '0':
      case null:
        return false
      default:
        return Boolean(value)
    }
  }

  const isGrantedAny = (roles: string|string[]): boolean => {
    const currentRoles = getCurrentUser().roles
    if (Array.isArray(roles)) {
      return currentRoles.some(el => roles.includes(el))
    } else {
      return currentRoles.includes(roles)
    }
  }

  return {
    getCurrentUser,
    getUser,
    logout,
    getCurrentPractitioner,
    getCurrentPatient,
    getPractitionerId,
    isGrantedAny,
    toBoolean
  }
}
