import api from '@services/php-api.service'

export default function (route) {
  return {
    getList: (filters, success, error) => {
      api.get(route, filters, success, error)
    },
    getById: (id, success, error) => {
      api.getById(route, id, success, error)
    },
    save: (data, success, error) => {
      if (typeof data.new === 'undefined') {
        api.put(route, data, data.objectId, success, error)
      } else {
        delete data.new
        api.post(route, data, success, error)
      }
    },
    remove: (id, success, error) => {
      api.remove(route, id, success, error)
    }
  }
}
