<template>
  <v-container
    class="ma-0 pa-0"
    ref="top"
  >
    <v-carousel
      v-model="carouselPage"
      touchless
      hide-delimiters
      :show-arrows="false"
      height="auto"
      light
    >
      <v-carousel-item
        v-for="(component, i) in componentsList"
        :key="i"
        class="pa-2"
      >
        <component
          :is="component.name"
          ref="components"
          :options="component"
          :appointment="appointment"
          :patient="patient"
          @next="nextPage"
          @prev="prevPage"
        />
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import SafetyQuestions from '@components/appointment/carousel/SafetyQuestions.vue'
import Welcome from '@components/appointment/carousel/Welcome.vue'
import Insurance from '@components/appointment/Insurance.vue'
import ThankYou from '@components/appointment/ThankYou.vue'
import ThankYouForms from '@components/appointment/ThankYouForms.vue'
import Forms from '@components/appointment/carousel/Forms.vue'
import Address from '@components/appointment/carousel/Address.vue'
import Privacy from '@components/appointment/Privacy.vue'
import Booking from '@components/appointment/carousel/Booking.vue'
import ThankYouBooking from '@components/appointment/carousel/ThankYouBooking.vue'
import Payment from '@components/appointment/carousel/Payment.vue'
import Practitioner from '@components/appointment/carousel/Practitioner.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentCarousel',
  props: {
    componentsList: {
      type: Array
    },
    overrideCarouselPage: {
      type: Number,
      default: 0
    }
  },
  components: {
    SafetyQuestions,
    Welcome,
    Insurance,
    ThankYou,
    Forms,
    ThankYouForms,
    Address,
    Privacy,
    Booking,
    ThankYouBooking,
    Payment,
    Practitioner
  },
  data () {
    return {
      carouselPage: 0
    }
  },
  computed: {
  ...mapGetters('appointment', { appointment: 'appointment' }),
  ...mapGetters('app', { patient: 'currentUser' }),
  },
  mounted () {
    this.carouselPage = this.overrideCarouselPage
  },
  methods: {
    nextPage () {
      if (this.carouselPage + 1 >= this.componentsList.length) {
        this.closeCarousel()
      }
      this.carouselPage++
      this.triggerInit()
    },
    prevPage () {
      if (this.carouselPage === 0) {
        return
      }
      this.carouselPage--
      this.triggerInit()
    },
    triggerInit () {
      if (typeof this.$refs.components[this.carouselPage] !== 'undefined') {

        if (
          typeof this.$refs.components[this.carouselPage].init === 'function'
        ) {
          this.$refs.components[this.carouselPage].init()
        }
      }
    },
    closeCarousel () {
      this.$store.dispatch('app/appBarHide', '')
      this.$emit('close')
    }
  }
}
</script>
