import { mapDatasetResponse } from '@src/helpers/helper'
import { Alerts } from '@src/interfaces/AlertsInterface'

export interface AlertsState {
  [key: string]: any;
}

const model: Alerts = {
  count: 0,
  countSet: false,
  data: [],
  pollInterval: 0,
  getAlertsTimeout: undefined
}

const state: AlertsState = {
  ...model
}

const actions = {
  getAlerts ({ state, dispatch, commit, getters, rootState, rootGetters }) {
    if (state.getAlertsTimeout) {
      commit('setGetAlertsTimeout', null)
    }
    // @ts-ignore
    (this as any)._vm.$_rest.get(`/practitioners/${ rootGetters['app/currentUserId'] }/datasets/alerts`, {
      filter: { practitionerPracticeId: rootGetters['app/practitionerPractice']?.id }
    }, response => {
      commit('setData', mapDatasetResponse(response))
      // @ts-ignore
      commit('setCount', response.data.filter(el => el[3] === 'C').length)
      if (state.pollInterval > 0) {
        commit('setGetAlertsTimeout', setTimeout(() => {
            dispatch('getAlerts')
          }, state.pollInterval)
        )
      }
    }, error => {
    })
  },
  pollAlerts({ dispatch, commit }, interval: Alerts) {
    commit('setPollInterval', interval)
    dispatch('getAlerts')
  }
}

const mutations = {
  setCount (state: AlertsState, count: Alerts) {
    state.count = count
    state.countSet = true
  },
  minusOne (state: AlertsState) {
    state.count = state.count - 1
  },
  setPollInterval (state: AlertsState, value: Alerts) {
    state.pollInterval = value
  },
  setData(state: AlertsState, data: Alerts) {
    state.data = data
  },
  setGetAlertsTimeout(state: AlertsState, timeout: Alerts) {
    clearTimeout(state.getAlertsTimeout)
    if (timeout) {
      state.getAlertsTimeout = timeout
    }
  }
}

const getters = {
  count (state: AlertsState) {
    return state.count
  },
  countSet (state: AlertsState) {
    return state.countSet
  },
  data (state: AlertsState) {
    return state.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
