<template>
  <v-container dark>
    <v-row
      justify="center"
      align="center"
      class="pa-2 text-center"
    >
      <v-col cols="12">
        <div class="text-h6">
          Our Data Protection Promise
        </div>
        <p class="text-justify">
          Affidea is committed to respect your rights under data protection laws. Keeping your personal and health data safe is very
          important to us. Our <a href="https://www.affidea.ie/privacy-policy/" target="_blank">Data Privacy Notice for Patients</a> ("Notice") contains the essential information about our data processing
          when providing your medical services. We kindly ask you to take a moment to read the Notice so that you are clear about why
          and how we process your personal data. In the Notice you can find information on the following: (a) the identity and the
          contact details of the data controller; (b) the contact details of the data protection officer; (c) the purposes and legal basis for
          processing; (d) the sources of your personal data; (e) the recipients of your personal data; (f) the period for which your personal
          data will be stored; (g) your rights under data protection laws; (h) international data transfers.
        </p>
        <p class="text-justify">
          <b>I. Your Acknowledgement</b> (see clause 1. 7 of the Notice)<br />I, the
          undersigned, by signing this statement acknowledge that I have
          received the Affidea Data Privacy Notice and its Annex providing
          information about how Affidea will process my personal data for the
          purpose of performing medical diagnosis and/or providing medical
          treatment.
        </p>
        <p class="text-justify">
          <b>ll. Additional authorization(s)</b> - optional (see clause
          1.3. of the Notice)
            <br />I understand that by ticking the boxes below I
          can voluntarily authorize Affidea to perform additional processing
          of my personal data (section 1.3 of the Notice provides more
          details). I also understand that if I do not consent, this will not
          have any impact on the medical services provided to me. I further
          understand that I can withdraw my consent at any time.
        </p>
        <br />
        <p class="font-weight-medium text-justify">
          I consent to my personal information, including my health data,
          being de-identified so that Affidea may use it and share it for
          research and development, educational, statistical and commercial
          purposes. The set of personal information subject to the
          de-identification includes my personal information as collected by
          Affidea in the past (i.e. before I provided this consent) and as
          may be collected by Affidea in the future if I make use of
          Affidea's services.
        </p>
        <v-row class="dark--text">
          <v-col cols="12">
            <v-radio-group
              ref="refResearch"
              v-model="consent.research"
              row
            >
              <v-radio
                label="Yes, I consent"
                value="Yes"
              />
              <v-radio
                label="No, I don't consent"
                value="No"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <p class="font-weight-medium text-justify">
          I consent to be informed of Affidea's latest offering in medical
          services via e-mail (e-marketing).
        </p>
        <v-row class="light--text">
          <v-col cols="12">
            <v-radio-group
              ref="refOffers"
              v-model="consent.offers"
              row
            >
              <v-radio
                label="Yes, I consent"
                value="Yes"
              />
              <v-radio
                label="No, I don't consent"
                value="No"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <p class="font-weight-medium text-justify">
          I consent to be contacted for personalized marketing purposes through the following means:
        </p>
        <v-row
          ref="marketing"
          class="light--text"
        >
          <v-col cols="6">
            <v-checkbox
              v-model="consent.marketing.email"
              :disabled="noMarketing"
              label="e-mail"
              value="Yes"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="consent.marketing.phone"
              :disabled="noMarketing"
              label="phone"
              value="Yes"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="consent.marketing.SMS"
              :disabled="noMarketing"
              label="SMS"
              value="Yes"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="noMarketing"
                label="Please do not contact me"
            />
          </v-col>
        </v-row>
        <p class="font-weight-medium text-justify">
          Ill. Collection of historic medical reports within Affidea's
          group of companies
        </p>
        <br />
        <p class="text-justify">
          I understand that the availability of my historic medical records
          may facilitate the medical diagnosis and/or medical treatment I
          request from Affidea. I authorize Affidea to collect my relevant
          historic medical records from (an)other data controller(s) belonging
          to Affidea's group of companies in Ireland if needed.
        </p>
        <v-row class="light--text">
          <v-col cols="12">
            <v-radio-group
              ref="refHistoric"
              v-model="consent.historic"
              row
            >
              <v-radio
                label="Yes, I consent"
                value="Yes"
              />
              <v-radio
                label="No, I don't consent"
                value="No"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ref="refSignature1"
            cols="12"
            class="text-left"
          >
            <p class="text-left">
              Signature of the Patient:
            </p>
            <div style="max-width: 600px">
              <div class="text-subtitle-2">
                Please use your mouse, touchpad or touchscreen of your device to draw your signature in the box below.
              </div>
              <div style="border: 1px solid black">
                <VueSignaturePad
                    ref="signature1"
                    height="150px"
                    :options="{ onEnd: () => { saveSignature1() } }"
                />
              </div>
              <v-btn @click="undoSignature1">
                {{ $_t('testQuestions.undo') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <p class="text-justify">
          If the patient cannot make a statement and/or sign on their own
          behalf, please provide us with the reason and the identity of the
          representative (optional):
        </p>
        <v-row class="light--text">
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
                v-model="checkbox1"
                label="Vulnerable adult"
                value="Vulnerable adult"
                hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
                v-model="checkbox2"
                label="Child under 16"
                value="Child under 16"
                hide-details
            />
          </v-col>
          <v-col
              cols="12"
              md="4"
          >
            <v-checkbox
                v-model="checkbox3"
                label="Other"
                value="Other"
                hide-details
            />
          </v-col>
          <v-col
              ref="refOther"
              v-if="checkbox3"
              cols="12"
          >
            <v-text-field
                v-model="checkboxOther"
                label="Please specify"
                outlined
            />
          </v-col>
        </v-row>
        <v-row
          v-if="checkbox1 || checkbox2 || checkbox3"
          class="text-left">
          <v-col
              ref="refOnbehalfName"
              cols="12"
              md="6"
          >
            <v-text-field
              v-model="consent.onbehalfName"
              label="Full name of the representative"
              outlined
            />
          </v-col>
          <v-col
              ref="refSignature2"
              cols="12"
          >
            <p class="text-left">
              Signature of the representative:
            </p>
            <div style="max-width: 600px">
              <div class="text-subtitle-2">
                Please use your mouse, touchpad or touchscreen of your device to draw your signature in the box below.
              </div>
              <div style="border: 1px solid black">
                <VueSignaturePad
                    ref="signature2"
                    height="150px"
                    :options="{ onEnd: () => { saveSignature2() } }"
                />
              </div>
              <v-btn @click="undoSignature2">
                {{ $_t('testQuestions.undo') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'AppointmentPrivacy',
  props: {
    options: { type: Object, default: {} }
  },
  setup(props, ctx) {
    const refResearch = ref(null)
    const refOffers = ref(null)
    const refHistoric = ref(null)
    const refSignature1 = ref()
    const refSignature2 = ref()
    const refOther = ref()
    const refOnbehalfName = ref()
    const noMarketing = ref(false)
    const checkbox1 = ref('')
    const checkbox2 = ref('')
    const checkbox3 = ref('')
    const checkboxOther = ref('')
    const signature1 = ref()
    const signature2 = ref()
    let formEdited = false

    const consent = ref({
      research: '',
      offers: '',
      marketing: { email: 'No', SMS: 'No', phone: 'No' },
      onbehalf: '',
      onbehalfName: '',
      historic: '',
      signature1: '',
      signature2: '',
      repName: ''
    })
    const getOptions = computed(() => {
      return props.options
    })

    const nextPage = (page) => {
      ctx.emit('page', page)
    }

    watch(() => noMarketing.value, (val) => {
      if (val) {
        consent.value.marketing = { email: 'No', SMS: 'No', phone: 'No' }
      }
      formEdited = true
    })

    watch(() => checkbox1.value, (val) => {
      if (val) {
        consent.value.onbehalf = val
        checkbox2.value = false
        checkbox3.value = false
      }
      else if (val === null) {
        consent.value.onbehalf = ''
      }
      formEdited = true
    })

    watch(() => checkbox2.value, (val) => {
      if (val) {
        consent.value.onbehalf = val
        checkbox1.value = false
        checkbox3.value = false
      }
      else if (val === null) {
        consent.value.onbehalf = ''
      }
      formEdited = true
    })

    watch(() => checkbox3.value, (val) => {
      if (val) {
        consent.value.onbehalf = val
        checkbox1.value = false
        checkbox2.value = false
      }
      else if (val === null) {
        consent.value.onbehalf = ''
      }
    })

    watch(() => checkboxOther.value, (val) => {
      consent.value.onbehalf = 'Other: ' + val
      formEdited = true
    })

    const appointment = computed(() => ctx.root.$store.getters['appointment/appointment'])

    const saveSignature1 = () => {
      signature1.value.saveSignature()
      const { isEmpty, data } = signature1.value.saveSignature()
      if (!isEmpty) {
        formEdited = true
        consent.value.signature1 = data
      }
      else {
        consent.value.signature1 = ''
      }
      formEdited = true
    }

    const saveSignature2 = () => {
      signature2.value.saveSignature()
      const { isEmpty, data } = signature2.value.saveSignature()
      if (!isEmpty) {
        formEdited = true
        consent.value.signature2 = data
      }
      else {
        consent.value.signature2 = ''
      }
      formEdited = true
    }

    const saveConsentData = () => {
      const consentText = `Research: ${consent.value.research} | Offers: ${consent.value.offers} | Marketing: Email: ${consent.value.marketing.email} SMS: ${consent.value.marketing.SMS} Phone: ${consent.value.marketing.phone} | Historic: ${consent.value.historic} | On behalf: ${consent.value.onbehalf}`
      ctx.root.$_rest.put(
        `/appointments/${appointment.value.id}/custom`,
        {
          custom7: consentText
        }, undefined,
        response => {
        },
        error => {
          ctx.root.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )

      const data = {
        research: consent.value.research,
        offers: consent.value.offers,
        marketing: `Email: ${consent.value.marketing.email} | SMS: ${consent.value.marketing.SMS} | Phone:  ${consent.value.marketing.phone}`,
        onbehalf: consent.value.onbehalf,
        onbehalfName: consent.value.onbehalfName,
        historic: consent.value.historic,
        signature1: consent.value.signature1,
        signature2: consent.value.signature2,
      }

      ctx.root.$_rest.post(
        `/appointments/${appointment.value.id}/documents`,
        {
          documentCategoryCode: 'DPN',
          documentName: 'Data Protection and Consent',
          dataJson: {templateId: 24, data: data},
          templateId: 24
        },
        response => {
        },
        error => {
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )
    }

    const init = () => {
      ctx.root.$store.commit('app/setAppBarTitle', 'Data protection')
      ctx.root.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          ctx.emit('prev')
        }
      })
      ctx.root.$store.commit('app/setAppBarButton2', {
        label: 'Finish',
        icon: 'mdi-chevron-right',
        click: () => {
          let stop = false
          if (!consent.value.research) {
            ctx.root.$vuetify.goTo(refResearch.value)
            stop = true
          }
          else if (!consent.value.offers) {
            ctx.root.$vuetify.goTo(refOffers.value)
            stop = true
          }
          else if (!consent.value.historic) {
            ctx.root.$vuetify.goTo(refHistoric.value)
            stop = true
          }

          if (consent.value.onbehalf) {
            if (consent.value.onbehalf.substr(0, 5) === 'Other' && !checkboxOther.value.trim()) {
              ctx.root.$vuetify.goTo(refOther.value)
              stop = true
            }
            else if (!consent.value.onbehalfName || !consent.value.onbehalfName.trim()) {
              ctx.root.$vuetify.goTo(refOnbehalfName.value)
              stop = true
            }
            else if (!consent.value.signature2) {
              ctx.root.$vuetify.goTo(refSignature2.value)
              stop = true
            }
          }
          else {
            if (!consent.value.signature1) {
              ctx.root.$vuetify.goTo(refSignature1.value)
              stop = true
            }
          }

          if (stop) {
            ctx.root.$_notify.error('Please provide all required answers.')
            return
          }

          if (formEdited) {
            saveConsentData()
          }
          formEdited = false
          ctx.emit('next')
        }
      })
      ctx.root.$store.dispatch('app/appBarShow')
      ctx.root.$root.$emit('scrollToTop')
    }

    const undoSignature1 = () => {
      signature1.value.undoSignature()
      const { isEmpty, data } = signature1.value.saveSignature()
      consent.value.signature1 = isEmpty ? '' : data
      formEdited = true
    }

    const undoSignature2 = () => {
      signature2.value.undoSignature()
      const { isEmpty, data } = signature2.value.saveSignature()
      consent.value.signature2 = isEmpty ? '' : data
      formEdited = true
    }

    init()

    return {
      refResearch,
      refOffers,
      refHistoric,
      refSignature1,
      refSignature2,
      refOther,
      refOnbehalfName,
      getOptions,
      nextPage,
      consent,
      noMarketing,
      checkbox1,
      checkbox2,
      checkbox3,
      checkboxOther,
      signature1,
      signature2,
      saveSignature1,
      saveSignature2,
      undoSignature1,
      undoSignature2,
      init
    }
  }
})
</script>
