<template>
  <div>
    <div
      v-if="appointment.clinic || appointment.preferredClinic"
      class="my-3"
    >
      <table class="appointment text-left">
        <tbody>
        <tr>
          <td class="text-h6 pr-2">
            {{ $_t('Clinic') }}:
          </td>
          <td class="text-h6" v-if="appointment.clinic">
            {{ appointment.clinic ? appointment.clinic.name : $_t('Not specified') }}
          </td>
          <td class="text-h6" v-else-if="appointment.preferredClinic">
            {{ appointment.preferredClinic ? appointment.preferredClinic.name : $_t('Not specified') }}
          </td>
        </tr>
        <tr>
          <td class="text-h6 pr-2">{{ $_t('Date') }}:
          </td>
          <td class="text-h6">
            {{ appointment.date ? getDateText(appointment.date) : $_t('Not yet scheduled') }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <template v-for="(appointmentProcedure, index) in appointmentProceduresSorted">
        <v-row :key="index">
          <v-col cols="12" class="py-0 my-0">
            <v-alert
              three-line
              border="bottom"
              colored-border
              elevation="4"
              color="primary"
              class="text-left"
            >
              <h3>
                {{ appointmentProcedure.procedure.name }}
              </h3>
              <h5 class="text-subtitle-1">
                <span class="font-weight-bold">{{ $_t('Time') }}:&nbsp;{{ getTimeText(appointmentProcedure.startTime) }}</span>
              </h5>
            </v-alert>
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import moment from 'moment'

export default defineComponent({
  name: 'AppointmentProceduresCard',
  props: {
    appointment: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, ctx) {
    const appointmentNotEmpty = computed(() => {
      return !!Object.keys(props.appointment).length
    })

    const appointmentProceduresSorted = computed(() => {
      return appointmentNotEmpty && props.appointment.appointmentProcedures ? [...props.appointment.appointmentProcedures].sort((a, b) => {
        if (a.startTime < b.startTime) {
          return -1;
        }
        if (a.startTime > b.startTime) {
          return 1;
        }
        return 0;
      }) : []
    })

    const getTimeText = (time) => {
      if (!time) {
        return ''
      }
      return time.substr(11,5)
    }

    const getDateText = (date) => {
      const momentObj = moment(date.substr(0, 10), 'YYYY-MM-DD');
      const dayName = ctx.root.$_t(momentObj.format('dddd'));
      const monthName = ctx.root.$_t(momentObj.format('MMMM'));
      return `${dayName}, ${momentObj.format('D')} ${monthName} ${momentObj.format('YYYY')}`;
      //return moment(date.substr(0, 10), 'YYYY-MM-DD').format('dddd, D MMMM YYYY')
    }

    return {
      moment,
      appointmentNotEmpty,
      appointmentProceduresSorted,
      getTimeText,
      getDateText
    }
  }
})
</script>
<style scoped lang="css">
table.appointment {
  border: 0;
  border-collapse: collapse;
}
table.appointment td {
  text-align: left;
}
</style>
