<template>
  <v-container fluid>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            {{$_t('Select')}}
          </th>
          <th class="text-left">
           {{$_t('Name')}}
          </th>
          <th
            v-for="appointmentProcedure in appointment.appointmentProcedures"
            :key="appointmentProcedure.id"
            class="text-center"
          >
            {{ getProcedureName(appointmentProcedure) }}
          </th>
        </tr>
        <tr>
          <td colspan="999" style="height: 15px">
            <v-progress-linear
              v-if="loading"
              indeterminate
            />
          </td>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="clinic in clinics"
          :key="clinic.id"
        >
          <td>
            <v-checkbox
              v-model="selectedModel"
              :value="clinic.id"
              :disabled="clinicSupportsAnyProcedure(clinic)"
              @change="setCheckbox(clinic.id)"
            />
          </td>
          <td>
            {{ clinic.name }}
          </td>
          <td
            v-for="appointmentProcedure in appointment.appointmentProcedures"
            :key="appointmentProcedure.id"
            :class="clinicSupportsProcedure(clinic, appointmentProcedure.procedure) ? 'green accent-1' : 'red accent-1'"
            class="text-center"
          >
            <v-icon>{{ clinicSupportsProcedure(clinic, appointmentProcedure.procedure) ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>

export default {
  name: 'ClinicAvailability',
  props: {
    appointment: {
      type: Object
    },
    complete: {
      type: Boolean
    },
    appointmentProcedures: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    headers () {
      const headers = [{ value: 'select', text: 'Select', color: 'red-line'}, { value: 'name', text: 'Clinic' }]
      this.appointment.appointmentProcedures.map(procedure => {
        headers.push({ align: 'center', value: 'proc_' + procedure?.procedure?.id, text: procedure?.procedure?.scan?.nameShort+ ' ' + procedure?.procedure?.name })
      })
      return headers
    }
  },
  data () {
    return {
      loading: false,
      clinics: [],
      selected: [],
      selectedModel: []
    }
  },
  mounted () {
    this.$emit('update:complete', !!this.appointment.preferredClinicId)

    if (this.appointment.preferredClinicId) {
      this.selectedModel = [this.appointment.preferredClinic.id]
    } else {
      this.getSavedAppointment()
    }
    this.getClinics()
  },
  methods: {
    getProcedureName (appointmentProcedure) {
      const procedure = appointmentProcedure.procedure || {}
      const scan = procedure?.scan || {}
      return `${ scan.nameShort || '-' } ${ procedure.name || '-' }`
    },
    setCheckbox (clinicId) {
      this.updateAppointmentClinic(clinicId)
      this.selectedModel = [clinicId]
    },
    itemRowBackground (item) {
      return 'style-1'
    },
    rowClicked (row) {

    },
    clinicSupportsAnyProcedure (clinic) {
      return !this.appointment.appointmentProcedures.some(appointmentProcedure => this.clinicSupportsProcedure(clinic, appointmentProcedure.procedure))
    },
    clinicSupportsProcedure (clinic, procedure) {
      return clinic.clinicScanRooms.some(clinicScanRoom => {
        return clinicScanRoom?.scan?.id === procedure?.scan?.id
      })
    },
    getClinics () {
      this.loading = true

      this.appointmentProcedures.forEach((ap) => {
        ap.procedure.procedureClinics.forEach((pc) => {
          if (pc.isSelectable === 1 && !this.clinics.includes(pc.clinic)) {
            if(this.toBoolean(pc.clinic.isActive)) {
              this.clinics.push(pc.clinic)
            }
          }
        })
      })

      this.loading = false
    },
    updateAppointmentClinic (id) {
      this.loading = true
      this.$_rest.put(`practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}/preferred-clinics?groups=all,appointment_preferred_clinic`, {
        clinicId: id
      },undefined, response => {
        this.loading = false
        this.$emit('update:appointment', Object.assign({}, this.appointment, {
          preferredClinicId: response.data.preferredClinicId,
          preferredClinic: response.data.preferredClinic
        }))
        this.selectedModel = [response.data.preferredClinic.id]
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },
    getSavedAppointment () {
      this.loading = true
      this.$_rest.get(`practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}`, {
        groups: 'all,'
      }, response => {
        this.loading = false
        this.selectedModel = [response.data.preferredClinicId]
      }, error => {
        this.$_notify.error(error)
        this.loading = false
      })
    }
  },
  watch: {
    selectedModel: {
      handler: function (selected) {

        this.$emit('update:complete', !!selected.length)
      },
      deep: true
    },
  },

}
</script>

<style scoped>
/deep/ .reeed{
  background: #FFCDD2 !important;
}
.style-1 {
  background-color: rgb(215,215,44)
}
</style>
