<template>
  <RouteDialog>
    <appointment-report-overlay
      v-if="dialogReport"
      v-model="dialogReport"
      :report-route="reportRoute"
      :row.sync="activeRowReport"
      :actions="[]"
    />
    <v-container>
        <v-row>
          <v-col cols="6" class="text-left text-h5">{{$_t('Appointment')}}</v-col>
        </v-row>
        <v-row>
          <v-col>
            <AppointmentProcedureList
            :loader="appointmentProcedureLoader"
            :list="getProcedureList"
            @show-report="showReportOverlay"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <grid
                  vertical
                  :data="getActiveRow"
                  hide-filters
                  hide-search
                  :headers-preset-type="headersPresetType"
                  :headers-preset="headersPreset"
                ></grid>
          </v-col>
        </v-row>
      </v-container>
  </RouteDialog>
</template>
<script>
import RouteDialog from '@components/core/RouteDialog.vue'
import Grid from '@components/common/Grid.vue'
import appointmentsPracticesHeaders from '@src/data/dataset/gridHeaders/appointmentPractices'
import config from '@src/config'
import AppointmentReportOverlay from '@components/common/AppointmentReport.vue'
import AppointmentProcedureList from '@components/procedure/AppointmentProcedureList.vue'

export default {
  name:'AppointmentGridDetails',
  components: { RouteDialog,Grid,AppointmentReportOverlay,AppointmentProcedureList  },
  data () {
    return {
      searchResult: {},
      loading: false,
      rightDrawerModel:false,
      activeRow:null,
      dialogReport: false,
      reportRoute: '',
      activeRowReport: {},
      appointmentProcedureList:[],
      appointmentProcedureLoader:false,
      headersPresetType:'appointmentPractices',
      headersPreset:appointmentsPracticesHeaders,
      config,
      render:true,
      appointmentId:null
    }
  },
  props:{
    id:{
      type:String,
      default:null
    }
  },
  computed:{
    items() {
      return this.$store.getters['appointment/list']
    },
    getActiveRow(){
      if (this.activeRow) {
        return [this.activeRow]
      }
      return []
    },
    proceduresFetchRoute () {

      return `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}`
    },
    getProcedureList () {
      return this.appointmentProcedureList
    }
  },
  methods:{
    showReportOverlay (reportId) {
      const row = this.getActiveRow[0]
      this.dialogReport = true
      this.activeRowReport = row
      this.reportRoute = `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/reports/${reportId}/file`

    },
     procedureStudiesFetchRoute (appointmentProcedureId) {
      return `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/appointment-procedures/${appointmentProcedureId}/appointment-procedure-studies?status=S&orderBy[id]=desc&limit=1`
    },
    async getProcedureStudies (resolve) {
      let count = 0;
      this.appointmentProcedureLoader = true
      this.appointmentProcedureList.forEach((apProc) => {
        this.$_rest.get(this.procedureStudiesFetchRoute(apProc.id), {}, response => {
          count++;
          if (response.data.length === 1) {
            apProc.study = response.data[0]
            apProc.appointmentId = this.appointmentId
          } else {
            apProc.study = null
          }
          if (count === this.appointmentProcedureList.length) {
            resolve()
          }
        }, error => {
          this.appointmentProcedureLoader = false
          this.$_notify.error(error)
        })
      })
    },
    getProcedures () {
      this.appointmentProcedureLoader = true
      this.$_rest.get(this.proceduresFetchRoute, {
        groups: ['all', 'appointment_practitioner_practice', 'appointment_appointment_procedures', 'appointment_procedure_procedure', 'appointment_procedure_appointment_filtered_procedure_reports'].join(',')

      }, async response => {
        this.appointmentProcedureList = []
        response.data.appointmentProcedures.forEach((ap) => {
          if (!['NA', 'X', 'XR'].includes(ap.statusCode)) {
            this.appointmentProcedureList.push(ap)
          }
        })
        // if viewer enabled
        if (this.config.practitionerExaminationImagesEnabled && this.appointmentProcedureList.length > 0) {
          await this.getProcedureStudies(() => {
            this.appointmentProcedureLoader = false
          })
        } else {
          this.appointmentProcedureLoader = false
        }
      }, error => {
        this.appointmentProcedureLoader = false
        this.$_notify.error(error)
      })
    },

    setParams(){
      this.appointmentId = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : this.id
      return this.appointmentId ? true : false
    },

    processRouteParam() {
      if(this.setParams()){
        this.activeRow = this.items.find(item=>item.appointmentId === this.appointmentId)
        this.loading = false
        this.getProcedures()
      } else {
        this.$_notify.error(this.$_t('Wrong params'))
        this.loading = false
      }
    }
  },
  watch:{
    items(val){
      this.loading = true
       if(val.length) {
        this.processRouteParam()
      }
    }

  },
  mounted(){
    this.processRouteParam()
  }


}
</script>
