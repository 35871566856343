<template>
  <div>
    <v-row align="center" v-for="hour in hours" :key="hour">
      <v-col
        ><p class="mb-4 text-center hours__slot">{{ getHoursSlot(hour) }}</p></v-col
      >
      <v-col v-for="(slots, date) in availabilityModel" :key="date">
        <div class="mb-4 text-center">
          <availability-button
            v-bind:available="ifSlotsAvailableInHour(hour, slots)"
            v-bind:date="getDate(date)"
            v-bind:hour="hour"
            v-bind:hoursSlotLabel="getHoursSlotLabel(hour)"
          ></availability-button>
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>
  </div>
</template>

<style scoped>
.slots__contaniner {
  max-height: 400px;
  overflow-y: auto;
}
.hours__slot {
  font-style: normal;
  font-weight: normal;
  
  line-height: 17px;
  color: var(--v-primary-base);
}
</style>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import AvailabilityButton from '@components/appointment/booking/slots/_AvailabilityButton.vue';

export default Vue.extend({
  components: { AvailabilityButton },
  name: 'BookingSlots',
  props: ['hours', 'availabilityModel'],
  data: () => ({}),
  computed: {
    ...mapGetters([])
  },
  methods: {
    getDate(date) {
      return moment(date);
    },
    getHoursSlot(hour) {
      return (
        moment(hour, 'H')
          .format('HH:mm')
          .toUpperCase() +
        '-' +
        moment(hour + 1, 'H')
          .format('HH:mm')
          .toUpperCase()
      );
    },
    getHoursSlotLabel(hour) {
      return (
        moment(hour, 'H')
          .format('HH:mm A')
          .toUpperCase() +
        ' and ' +
        moment(hour + 1, 'H')
          .format('HH:mm A')
          .toUpperCase()
      );
    },
    ifSlotsAvailableInHour(hour, slots) {
      if (!slots || !Object.keys(slots).length) {
        return false;
      }
      const slotsWithinHour = Object.values(slots).filter((slot) => {
        const slotHour = moment(slot.slot_start, 'HH:mm:ss').format('H');
        if (Number(slotHour) === Number(hour)) {
          return true;
        } else {
          return false;
        }
      });
      return slotsWithinHour.length;
    }
  }
});
</script>
