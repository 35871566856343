<template>
  <v-container style="min-height: 460px;">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    />
    <v-row
      v-if="!loading"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <div class="text-center text-subtitle-1">
          {{$_t('Please select one of the following payment types')}}:

        </div>
        <v-radio-group
          v-model="panel"
        >
          <v-radio
            :value="0"
            label="Cash/Card"
          ></v-radio>
          <v-radio
            :value="1"
            label="Private insurance"
          ></v-radio>
          <v-radio
            v-if="insurerMedicalCard"
            :value="2"
            label="HSE funded (including GP Direct Access scheme)"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-slide-y-transition mode="out-in">
        <v-col
          cols="12"
          v-if="panel === 1"
          class="py-0"
        >
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="insurer"
                    label="Insurer"
                    :items="selectItems"
                    :loading="insurerLoading"
                    outlined
                    @change="updateInsurer"
                  />
                </v-col>
                <v-col cols="12" v-if="insurer" class="mt-0 pt-0">
                  <div class="text-subtitle-1 mt-0 pt-0 text-justify">
                    Please note that some private health insurance plans carry an excess or do not fully cover all scans.
                    Affidea would advise that you check with your insurer in advance that you are fully covered for this procedure at our clinic.
                    Please note that you will be required to pay in full on the day and claim the relevant amount back from you insurer directly if your plan does not qualify for direct settlement.
                    If your insurer rejects the claim and will not cover the cost of your treatment then Affidea will charge you as a patient directly using the credit card details provided.
                  </div>
                  <v-checkbox
                    v-model="insurerCheckbox"
                    ref="insurerCheckbox"
                    :error="insurerCheckboxError"
                    label="Please tick the box to acknowledge your acceptance of same."
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model="insuranceNumber"
                    label="Insurance membership number"
                    outlined
                    @change="updateInsuranceData"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="insuranceMonthDialog"
                    :return-value.sync="insuranceMonth"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="insuranceMonth"
                        label="Next renewal month"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="insuranceMonth"
                      type="month"
                      scrollable
                      :min="now"
                      @input="$refs.dialog.save(insuranceMonth);updateInsuranceData();insuranceMonthDialog=false"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-slide-y-transition>
      <v-slide-y-transition mode="out-in">
        <v-col
          v-if="panel===2"
          cols="12"
          class="py-0"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model="medicalCard"
                label="Medical card number"
                outlined
                @change="updateInsuranceData"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-slide-y-transition>
      <v-slide-y-transition mode="out-in">
        <v-col
          v-if="insurer && insurers[insurer].noteWeb"
          cols="12"
          class="py-0"
        >
          <v-card>
            <v-card-text>
              <h6 class="subtitle-1 pa-4 text-center">
                {{ insurers[insurer].noteWeb }}
              </h6>
            </v-card-text>
          </v-card>
        </v-col>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentInsurance',
  props: {},
  data () {
    return {
      insurer: null,
      insurerCheckbox: false,
      insurerCheckboxError: false,
      insurerLoading: false,
      insurers: {},
      insuranceNumber: '',
      insuranceMonth: '',
      medicalCard: '',
      insuranceMonthDialog: false,
      panel: null,
      insurerCash: null,
      insurerMedicalCard: null,
      loading: true,
    }
  },
  computed: {
    ...mapGetters('app', { currentUser: 'currentUser' }),
    ...mapGetters('appointment', { appointment: 'appointment' }),
    now () {
      const date = new Date()
      return date.toISOString()
    },
    selectItems () {
      const ret = Object.values(this.insurers).filter(el => el.codeRis !== 'CASH' && el.codeRis !== 'MEDCARD').map(el => {
        return {
          value: el.id,
          text: el.nameWeb
        }
      })
      return ret
    }
  },
  mounted () {
    this.init()
    this.insurerLoading = true
    this.$_rest.get(`patients/${this.currentUser.id}/insurers`,
      { groups: 'insurer' },
      response => {
        if (response.data.length) {
          this.insurers = {}
          response.data.forEach(el => {
              if (el.codeRis === 'CASH') {
                this.insurerCash = el.id
              }
              if (el.codeRis === 'MEDCARD') {
                this.insurerMedicalCard = el.id
              }
              this.insurers[el.id] = el
          })
        }
        this.insurerLoading = false
        this.loading = false
      },
      error => {
        this.insurerLoading = false
        this.loading = false
        this.$_notify.error(error)
      }
    )
  },
  watch: {
    panel (val) {
      if (val === 0) {
        this.insurer = this.insurerCash
      } else if (val === 1) {
        this.insurer = null
      } else if (val === 2) {
        this.insurer = this.insurerMedicalCard
      }
    }
  },
  methods: {
    init () {
      this.$store.commit('app/setAppBarTitle', 'Payment type')
      this.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          this.$emit('prev')
        }
      })
      this.$store.commit('app/setAppBarButton2', {
        label: 'Next',
        icon: 'mdi-chevron-right',
        click: () => {
          this.insurerCheckboxError = false

          if (this.panel === null || this.panel === undefined) {
            this.$_notify.error('Please select your payment type')
            return
          } else if (this.panel === 1 && !this.insurer) {
            this.$_notify.error('Please select your insurer')
            return
          } else if (this.panel === 1 && !this.insurerCheckbox) {
            this.$vuetify.goTo(this.$refs.insurerCheckbox)
            this.$_notify.error('Please tick the box')
            this.insurerCheckboxError = true
            return
          } else if (this.panel === 1 && !this.insuranceNumber) {
            this.$_notify.error('Please enter your insurance membership number')
            return
          } else if (this.panel === 1 && !this.insuranceMonth) {
            this.$_notify.error('Please select your next renewal month')
            return
          }

          if (this.panel === 0) {
            if (this.panel === 0 && this.insurerCash) {
              this.insurer = this.insurerCash
              this.updateInsurer()
            } else if (this.panel === 2 && this.insurerMedicalCard) {
              this.insurer = this.insurerMedicalCard
              this.updateInsurer()
            } else {
              this.$_notify.error('Please select your payment type')
              return
            }
          }
          sessionStorage.setItem('patientInsurerId', this.insurer)
          this.$emit('next')
        }
      })
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')
    },
    updateInsurer () {
      this.insurerLoading = true
      this.$_rest.put(`/appointments/${this.appointment.id}/insurer`, { insurerId: this.insurer }, undefined,
        response => {
          this.insurerLoading = false
        },
        error => {
          this.insurerLoading = false
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )
    },
    updateInsuranceData () {
      this.$_rest.put(
        `/appointments/${this.appointment.id}/custom`,
        {
          custom3: this.insuranceNumber,
          custom5: this.insuranceMonth,
          custom6: this.medicalCard
        }, undefined,
        response => {
        },
        error => {
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )
    }
  }
}
</script>
