<template>
  <v-container
    style="min-height: 460px;"
    class="mt-0 pt-0"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    />
    <div v-if="!loading && !book">
      <v-row>
        <v-col cols="12" class="text-h5 text-center">
          {{ $_t('Thank you for completing your patient profile') }}
          <v-divider class="mt-6"/>
        </v-col>
      </v-row>
    </div>
    <div v-if="!loading && isBookingAllowed">
      <v-row
        v-if="!book"
        class="text-center"
      >
        <v-col cols="12" class="text-h5 text-center">
          {{ $_t('You can now proceed to book your appointment') }}
        </v-col>
        <v-col cols="12" class="text-subtitle-1 text-center">
          {{ $_t('If you cannot find a suitable slot or you do not complete the booking, our team will be in contact to schedule your appointment.') }}<br>
          {{ $_t('Please note that we are currently experiencing a high volume of appointment requests.') }}
        </v-col>
        <v-col cols="12" class="mt-2 text-center">
          <v-btn
            color="primary"
            class=""
            min-width="120px"
            @click="bookNow"
          >
            {{ $_t('Book Now') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="book & !slots"
        class="row"
      >
        <patient-appointment-booking-clinics
          access-code="accessCode"
          :appointment="appointment"
          :visible="!config.webBookingSkipClinicSelection"
          @continue="showSlots"
        />
      </v-row>
      <v-row
        v-if="book & slots"
        class="row"
      >
        <patient-appointment-booking-slots @continue="showSlots2" />
      </v-row>
    </div>
    <div v-if="!loading && !isBookingAllowed">
      <v-row>
        <v-col cols="12" class="text-h5 text-center">
          <div class="text-subtitle-1">
            {{ $_t('You will be contacted by a member of our scheduling team to arrange your appointment.') }}<br>
            {{ $_t('Please note that we are currently experiencing a high volume of appointment requests.') }}
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-if="slots2"
      v-model="slots2"
      width="90vw"
      style="min-height: 90vh;"
    >
      <v-card>
        <v-card-text>
          <patient-appointment-booking-slots-within-hour
            @confirm="confirm"
            @cancel="cancel"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PatientAppointmentBookingClinics from '@components/appointment/booking/clinics/Clinics.vue'
import PatientAppointmentBookingSlots from '@components/appointment/booking/slots/Slots.vue'
import PatientAppointmentBookingSlotsWithinHour from '@components/appointment/booking/slots/SlotsWithinHour.vue'
import { mapGetters } from 'vuex';
import config from '@src/config'

export default {
  name: 'CarouselAppointmentBooking',
  props: {
    appointment: {
      type: Object,
      default: () => {}
    }
  },
  components: { PatientAppointmentBookingClinics, PatientAppointmentBookingSlots, PatientAppointmentBookingSlotsWithinHour },
  data () {
    return {
      config: config,
      address: null,
      book: false,
      slots: false,
      slots2: false,
      loading: true,
      isBookingAllowed: false
    }
  },
  computed: {
    ...mapGetters('booking', { availabilitySlot: 'getAvailabilitySlot' }),
    accessCode () {
      return this.$route.params.appointmentId || ''
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    availabilitySlot (val) {
      if (val) {
        this.slots2 = true
      }
    }
  },
  methods: {
    bookNow () {
      this.book = true
    },
    showSlots () {
      this.slots = true
    },
    showSlots2 () {
      this.slots2 = true
    },
    confirm () {
      this.slots2 = false
      this.$emit('next')
    },
    cancel () {
      this.slots2 = false
    },
    checkBookingAllowed () {
      this.loading = true
      let params = {}
      if (sessionStorage.getItem('patientInsurerId')) {
        params = {
          insurerId: sessionStorage.getItem('patientInsurerId')
        }
      }

      this.$_rest.get(`appointments/${ this.appointment.id }/is-booking-allowed`, params,
        response => {
          if (response.data && typeof response.data.isBookingAllowed !== 'undefined' && response.data.isBookingAllowed) {
            this.isBookingAllowed = true
            this.$store.commit('app/setAppBarButton1', {
              label: 'Back',
              icon: 'mdi-chevron-left',
              click: () => {
                if (this.book && this.slots && this.slots2) {
                  this.slots2 = false
                } else if (this.book && this.slots && !this.slots2) {
                  this.slots = false
                } else if (this.book && !this.slots && !this.slots2) {
                  this.book = false
                }
              }
            })
          } else {
            this.isBookingAllowed = false
          }
          this.loading = false
        },
        error => {
          this.loading = false
          this.isBookingAllowed = true
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true })
    },
    init () {
      this.$store.commit('app/setAppBarTitle', 'Booking')
      this.$store.commit('app/setAppBarButton1', null)
      this.$store.commit('app/setAppBarButton2', null)
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')
      this.checkBookingAllowed()
    }
  }
}
</script>
