<template>
  <v-container fluid class="mt-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-btn v-if="!vertical" v-on:click="$emit('refresh')" fab small top right absolute>
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
          <v-btn v-if="!vertical && showGridColumns"  @click.stop="gridColumnsDialogShow(true)" fab small top left absolute>
            <v-icon> mdi-cog </v-icon>
          </v-btn>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="6" v-if="!hideSearch">
                <GridSearch :search.sync="search" :label="$_t('Search')"></GridSearch>

              </v-col>
              <v-col cols="12" md="6" class="mt-md-1" v-if="!hideFilters">
                <v-select
                  v-model="filters"
                  :items="filterItems"
                  prepend-icon="mdi-filter-menu"
                  attach
                  dense
                  chips
                  :label="$_t(filterName)"
                  multiple
                  @input="inputFilter"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              ref="table"
              :headers="headers"
              :items="innerDataFiltered"
              item-key="value"
              :sort-desc="[true, false]"
              multi-sort
              :disable-pagination="disablePagination"
              :hide-default-footer="hideDefaultFooter"
              :items-per-page="itemsPerPage"
              :loading="loading"
              class="elevation-1"
              :search="search"
              :mobile-breakpoint="vertical ? 9999 : 500"
              :disable-sort="vertical || disableSort"
              @click:row="rowClicked"
              :fixed-header="fixedHeader"
              :height="fixedHeader ? '100vh' : ''"
              :options.sync="gridOptions"
              :server-items-length="serverItemsLength"
              :footer-props="{
                'items-per-page-options':[15, 30, 50, 100],
                'items-per-page-text':$_t('Rows per page')
              }"
              :loading-text="$_t('Loading items...')"
               v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
               :key="anIncreasingNumber"
            >
              <template v-slot:item.isActive="{ item }">
                {{ item.isActive ? "Yes" : "No" }}
              </template>
              <template v-slot:item.appointmentId="{ item }">
                A{{ item.appointmentId.substr(item.appointmentId.length - 11) }}
              </template>
              <template v-slot:item.patientId="{ item }">
                <span v-if="item.patientId">
                  P{{ item.patientId.substr(item.patientId.length - 11) }}
                </span>
              </template>
              <template v-slot:item.appointmentStatusName="{ item }">
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: (item.appointmentStatusColorCodeWeb || item.appointmentStatusColorCode).replace(
                        '^',
                        '#'
                      ),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.appointmentStatusNameWeb || item.appointmentStatusName }}
              </template>
              <template v-slot:item.appointmentProcedureStatusName="{ item }">
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                  v-if="item.appointmentProcedureStatusName"
                >
                  <v-avatar
                    :style="{
                      background: '#' + item.appointmentProcedureStatusName.split('^').pop(),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.appointmentProcedureStatusName ? item.appointmentProcedureStatusName.split('^').shift() : '' }}
              </template>


              <template v-slot:item.procedureName="{ item }">
                <AppointmentPracticeSplitSlot :item="item.procedureName" />
              </template>
               <template v-slot:item.duration="{ item }">
                 <AppointmentPracticeSplitSlot :item="item.duration" />
                </template>
              <template v-slot:item.insurerName="{ item }">
                <AppointmentPracticeSplitSlot :item="item.insurerName" />
              </template>

              <template v-slot:item.clinicScanRoomCode="{ item }">
                <AppointmentPracticeSplitSlot :item="item.clinicScanRoomCode" />
              </template>
              <template v-slot:item.cancelReason="{ item }">
                <AppointmentPracticeSplitSlot :item="item.cancelReason" />
              </template>
              <template
                v-slot:item.appointmentProtocollingStatusName="{ item }"
              >
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: item.appointmentProtocollingStatusColorCode.replace(
                        '^',
                        '#'
                      ),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.appointmentProtocollingStatusName }}
              </template>
              <template
                v-slot:item.appointmentDocumentStatusName="{ item }"
              >
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: item.appointmentDocumentStatusColorCode.replace(
                        '^',
                        '#'
                      ),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.appointmentProtocollingStatusName }}
              </template>
              <template
                v-slot:item.appointmentSourceName="{ item }"
              >
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: item.appointmentSourceColorCode.replace(
                        '^',
                        '#'
                      ),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.appointmentProtocollingStatusName }}
              </template>
               <template
                v-slot:item.appointmentCategoryName="{ item }"
              >
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: item.appointmentCategoryColorCode.replace(
                        '^',
                        '#'
                      ),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.appointmentProtocollingStatusName }}
              </template>
              <template
                v-slot:item.appointmentPriorityName="{ item }"
              >
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: item.appointmentPriorityColorCode.replace(
                        '^',
                        '#'
                      ),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.appointmentProtocollingStatusName }}
              </template>
              <template v-slot:item.alertStatusName="{ item }">
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: (
                        item.alertStatusColorCode || '^555555'
                      ).replace('^', '#'),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.alertStatusName }}
              </template>
              <template v-slot:item.alertTypeName="{ item }">
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar
                    :style="{
                      background: (
                        item.alertTypeColorCode || '^555555'
                      ).replace('^', '#'),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.alertTypeName }}
              </template>
              <template v-slot:item.appointmentDate="{ item }">
                {{
                  item.appointmentDate
                    ? useHumanDate(item.appointmentDate)
                    : ""
                }}
              </template>
              <template v-slot:item.dateOfBirth="{ item }">
                {{
                  item.dateOfBirth
                    ? useHumanDate(item.dateOfBirth)
                    : ""
                }}
              </template>

              <template v-slot:item.startTime="{ item }" >
                <div v-if="item.startTime">
                  <div v-if="!item.startTime.includes('<br>')">
                    {{item.startTime}}
                  </div>
                  <div v-else>
                    <div v-for="split in splitData(item.startTime)" :key="split">
                      <div>{{(split || "").replace(/([0-9]{2}\:[0-9]{2}).*/, "$1")}}</div>
                    </div>
                  </div>
                </div>

              </template>

              <template v-slot:item.patient="{ item }">
                {{
                  [item.title, item.firstName, item.lastName].filter((el) => el && el.trim()).join(' ')
                }}
              </template>
              <template v-slot:item.createdDatetime="{ item }">
                {{
                  item.createdDatetime ? useHumanDateTime(item.createdDatetime) : ''
                }}
              </template>
              <template v-slot:item.sex="{ item }">
                {{
                  item.sex === 'M' ? 'Male' : (item.sex === 'F' ? 'Female' : item.sex)
                }}
              </template>

              <template v-slot:item.patientDateOfBirth="{ item }">
                {{
                  item.patientDateOfBirth ? useHumanDate(item.patientDateOfBirth) : ''
                }}
              </template>
              <template v-slot:item.patientFullName="{ item }">
                {{
                  item.patientFullName
                }}
              </template>
              <template v-slot:item.patientIdExternal="{ item }">
                {{
                  item.patientIdExternal
                }}
              </template>
              <template v-slot:item.patientNumber1="{ item }">
                {{
                  item.patientNumber1
                }}
              </template>
              <template v-slot:item.referringPractitionerFullName="{ item }">
                <span v-if="item.referringPractitionerFullName" v-text="`${item.referringPractitionerTitle ? item.referringPractitionerTitle : ''} ${item.referringPractitionerFullName}`"></span>
              </template>
              <template v-slot:item.consultingPractitionerFullName="{ item }">
                <span v-if="item.consultingPractitionerFullName" v-text="`${item.consultingPractitionerTitle ? item.consultingPractitionerTitle : ''} ${item.consultingPractitionerFullName}`"></span>
              </template>
              <template v-slot:item.patientId="{ item }">
                  <span v-text="`P ${item.patientId.substr(item.patientId.length - 11)}`"></span>
              </template>
              <template v-slot:item.patientEpisodeStatusName="{ item }">
                <v-avatar
                  :style="{ border: '1px solid black' }"
                  rounded
                  size="28"
                  class="mr-3"
                >
                  <v-avatar v-if="item.patientEpisodeStatusColorCode"
                    :style="{
                      background: item.patientEpisodeStatusColorCode.replace(
                        '^',
                        '#'
                      ),
                    }"
                    rounded
                    size="22"
                  >
                  </v-avatar>
                </v-avatar>
                {{ item.patientEpisodeStatusName }}
              </template>
              <template v-slot:item.createdDatetime="{ item }">
                {{
                  item.createdDatetime
                    ? useHumanDateTime(item.createdDatetime)
                    : ""
                }}
              </template>
              <template v-slot:item.isAppointmentCancelRequested="{ item }">
                <v-checkbox
                  color="primary"
                  :input-value="toBoolean(item.isAppointmentCancelRequested)"
                  disabled
            ></v-checkbox>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <GridColumns
      :all-columns="getAllHeadersGridColumns"
      :dialog="gridColumnsDialog"
      :selected-columns.sync="gridColumnsSelected"
      :render="render"
      @hide="gridColumnsDialogShow"
      @save="saveGridColumns"
      @drop="drop"
    >
    </GridColumns>
  </v-container>
</template>

<script>
import GridSearch from '@src/components/common/grid-elements/GridSearch.vue'
import AppointmentPracticeSplitSlot from '@/src/components/common/grid-elements/AppointmentPracticeSplitSlot.vue'
import useDateHelpers from '../../helpers/useDateHelpers';
import GridColumns from '@src/components/common/grid-elements/GridColumns.vue'
import Sortable from 'sortablejs'
import vue from 'vue'
import config from '@src/config'
const { useHumanDateTime, useHumanDate } = useDateHelpers()
function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const currentClassState = mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add('sortHandle');
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}
export default {
  components:{GridSearch,AppointmentPracticeSplitSlot,GridColumns},
  value: 'AppointmentGrid',
  props: {
    headersPresetType: {
      type: String,
      default: ''
    },
    headersPreset: {
      type: Array,
      default: ()=>[]
    },
    columns: {
      type: Array,
      default: ()=>[]
    },
    data: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String
    },
    loading: {
      type: Boolean
    },
    showAddRow: {
      type: Boolean,
      default: true
    },
    vertical: {
      type: Boolean,
      default: false
    },
    hideFilters: {
      type: Boolean,
      default: false
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    filterByColumnName: {
      type: String,
      default: 'appointmentStatusName'
    },
    filterName: {
      type: String,
      default: 'Appointment Status'
    },
    fixedHeader:{
      type:Boolean,
      default:false
    },
    gridCode: {
      type: String,
      default: null
    },
    showGridColumns: {
      type: Boolean,
      default: true
    },
    disablePagination: {
      type: Boolean,
      default: true
    },
    disableSort: {
      type: Boolean,
      default: true
    },
    itemsPerPage: {
      type: Number,
      default: 50
    },
    hideDefaultFooter: {
      type: Boolean,
      default: true
    },
    serverItemsLength: {
      type: Number,
      default: -1
    },
    searchValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      useHumanDateTime: useHumanDateTime,
      useHumanDate: useHumanDate,
      innerData:[],
      filterItems: [],
      filters: [],
      // headersPreset: {
      //   appointments: appointmentsHeadersPreset,
      //   alerts: alertsHeadersPreset,
      //   appointmentPractices:appointmentsPracticesHeadersPreset,
      //   episodes: episodesHeadersPreset,
      // },
      gridColumnsDialog:false,
      gridColumnsSelected: [],
      render: true,
      gridOptions: {},
      anIncreasingNumber: 1
    };
  },
  mounted() {
    this.innerData = [...this.data]
    this.gridColumnsSelected = [...this.storedColumns]
  },
  methods: {
    sortTheHeadersAndUpdateTheKey(evt) {
      if(this.sortColumnsOnGrid) {
        const newArray = []
        const oldIndex = evt.oldIndex;
        const newIndex = evt.newIndex;
        this.headers.splice(newIndex, 0,this.headers.splice(oldIndex, 1)[0])
        this.headers.forEach(el=>{
          newArray.push(el.value)
        })
        const toSave = newArray.filter(el=>typeof el !== 'undefined')
        this.gridColumnsSelected = toSave;
        this.anIncreasingNumber += 1;
        this.saveGridColumns()
      }

    },
    gridColumnsDialogShow (param) {
      this.gridColumnsDialog = param
    },
    splitData(dataToSplit){

       const set = new Set(dataToSplit.split('<br>'))
          return [...set]

    },
    inputFilter(val){
    if(val.length > 0 ) {
          this.innerData = this.data.filter(data=>{
            if(val.includes(data[this.filterByColumnName])) {
              return data
            }
          })
      } else {
        this.innerData = [...this.data]
      }
      this.processFilters()

    },
    processFilters() {
      const filterArray = [];
      this.data.filter(data => {
        if(data[this.filterByColumnName]) {
          filterArray.push(data[this.filterByColumnName]);
        }
      });
      this.filterItems = [
        ...new Set(filterArray)
      ];
      return this.filterItems
    },
    rowClicked(row) {
      this.$emit('rowClick', row)
    },

    drop(columns){
      this.gridColumnsSelected = [...columns]
    },

    saveGridColumns(){
      this.$store.dispatch('app/setColumnsByPresetType',{presetType:this.headersPresetType,columns:this.gridColumnsSelected}).then(()=>{
        this.$store.commit('app/setRefreshed',true)
      })

      this.gridColumnsDialogShow(false)
    }
  },
  computed: {
    storedColumns() {
      return this.$store.getters['app/columns'](this.headersPresetType)
    },
    sortColumnsOnGrid() {
      return this.$store.getters['app/getSortColumnsOnGrid']
    },
    getAllHeadersGridColumns() {
      const columns = [...this.storedColumns]
      let headerColumnsVisible = this.headersPreset.filter(column=>column.visible)
      if(typeof config['defaultColumnsGrid_'+this.headersPresetType] !== 'undefined' && config['defaultColumnsGrid_'+this.headersPresetType].length) {
        const configColumns = config['defaultColumnsGrid_'+this.headersPresetType]
        headerColumnsVisible = this.headersPreset.filter(column=>column.visible && configColumns.includes(column.value))
      }
      headerColumnsVisible.forEach((el)=>{
        el.selected = !!columns.find(column=>column === el.value)
        if(this.gridColumnsSelected.length) {
          el.selected = !!this.gridColumnsSelected.find(column=>column === el.value)
        }
      })

      return headerColumnsVisible
    },

    getInnerData() {
      return this.innerData
    },
    headers() {
      const columnNames =  this.storedColumns.map(column => {
        return this.headersPreset.find(
          header => header.value === column
        );
      });
      for(const item of columnNames) {
        item.text = this.$_t(item.text).toString()
      }
      return columnNames
    },
    innerDataFiltered() {
      if(!this.hideFilters)  this.processFilters()
      return Array.isArray(this.innerData) ? this.innerData.filter(item => {
        return item
      }) : []
    },
    search: {
      get() {
        return this.searchValue;
      },
      set(val) {
        this.$emit('update:searchValue', val);
      }
    }
  },
  watch: {
    gridColumnsSelected: {
      handler:function(val){
      this.render = false
      this.$nextTick(()=>{
        this.render = true
      })
      },
      deep: true
    },
    data: {
      handler: function (val) {
        if (val.length > 0){
          this.innerData = [...val]
        } else {
          this.innerData = []
        }
      },
      deep: true
    },
    gridOptions(val) {
        this.$emit('grid-options-changed', val)
    },
    searchValue(val) {
      this.$emit('grid-search', val)
    }
  },
  directives: {
    'sortable-table': {

      inserted: (el, binding,vnode) => {

        el.querySelectorAll('th').forEach((draggableEl) => {
          watchClass(draggableEl, 'sortHandle');
          draggableEl.classList.add('sortHandle');
        });
          if(vnode.context.sortColumnsOnGrid) {
           Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {});
          }
      },
    },
  }
};
</script>
