<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-container>
        <v-row v-if="getClinic" align="center" justify="center">
          <patient-appointment-booking-call-me-back-info :clinic="getClinic"></patient-appointment-booking-call-me-back-info>
          <patient-appointment-booking-call-me-back class="mb-8"></patient-appointment-booking-call-me-back>
        </v-row>
        <v-card :loading="slotsLoading" class="mx-auto">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              indeterminate
            ></v-progress-linear>
          </template>
        </v-card>
        <slots-header
          v-if="!slotsLoading"
          v-bind:dates="availabilityDates"
          @prevDate="onHeaderPrevDate()"
          @nextDate="onHeaderNextDate()"
          class="mb-6"
        >
        </slots-header>
        <div v-if="!slotsLoading">
          <div v-if="availabilityFlattened.length">
            <div class="mb-4 py-4 px-8 slots__time--header">MORNING</div>
            <availability-row
              v-if="availabilityInHours(getMorningHours)"
              v-bind:hours="getMorningHours"
              v-bind:availabilityModel="availability"
            ></availability-row>
            <slots-not-available v-else class="mb-4"></slots-not-available>
            <div class="mb-4 py-4 px-8 slots__time--header">AFTERNOON</div>
            <availability-row
              v-if="availabilityInHours(getAfternoonHours)"
              v-bind:hours="getAfternoonHours"
              v-bind:availabilityModel="availability"
            ></availability-row>
            <slots-not-available v-else class="mb-4"></slots-not-available>
            <div class="mb-4 py-4 px-8 slots__time--header">EVENING</div>
            <availability-row
              v-if="availabilityInHours(getEveningHours)"
              v-bind:hours="getEveningHours"
              v-bind:availabilityModel="availability"
            ></availability-row>
            <slots-not-available v-else class="mb-4"></slots-not-available>
          </div>
          <slots-not-available v-else></slots-not-available>
        </div>
        <v-alert v-if="showError" color="#CD1041" class="mb-10">
          <div class="px-12 py-10 white--text" v-html="errorMessage"></div>
        </v-alert>
        <div class="text-right">
          <v-btn
            @click="onAfterContinueClicked"
            v-if="!slotsLoading && getAvailability && false"
            ref="goToAvailabilityWithinHour"
            color="primary"
            class="white--text v-btn--primary"
            :disabled="!getAvailabilitySlot"
            :loading="schedulingAppointment"
          >
            Continue
          </v-btn>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped>
.slots__time--header {
  border-radius: 8px;
  background: rgba(0, 126, 147, 0.05);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: var(--v-primary-base);
}
</style>

<script>
import config from '@src/config';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import PatientAppointmentBookingCallMeBack from '@components/appointment/booking/callMeBack/callMeBack.vue'
import PatientAppointmentBookingCallMeBackInfo from '@components/appointment/booking/callMeBack/callMeBackInfo.vue'
import SlotsHeader from '@components/appointment/booking/slots/_Header.vue';
import SlotsNotAvailable from '@components/appointment/booking/slots/_SlotsNotAvailable.vue';
import AvailabilityRow from '@components/appointment/booking/slots/_AvailabilityRow.vue';

const DAYS_IN_WEEK_DESKTOP = 5;
const DAYS_IN_WEEK_MOBILE = 2;

export default Vue.extend({
  components: {
    PatientAppointmentBookingCallMeBack,
    PatientAppointmentBookingCallMeBackInfo,
    SlotsHeader,
    SlotsNotAvailable,
    AvailabilityRow
  },
  name: 'BookingSlots',
  data: () => ({
    showError: false,
    errorMessage: '',
    slotsLoading: true,
    schedulingAppointment: false,
    getAvailabilityStartDate: null,
    getAvailabilityStartTime: null,
    availabilityStartDate: null
  }),
  computed: {
    ...mapGetters('booking', [
      'getAppointment',
      'getAvailability',
      'getAvailabilitySlot'
    ]),
    ...mapGetters('booking', {getClinic: 'getClinic'}),
    MOBILE() {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
    DAYS_IN_WEEK() {
      return this.MOBILE ? DAYS_IN_WEEK_MOBILE : DAYS_IN_WEEK_DESKTOP;
    },
    availability() {
      const availability = this.getAvailability;
      if (!availability) {
        return {};
      }
      const availabilityKeys = Object.keys(availability);
      const startDayKey = this.availabilityStartDate.format('YYYY-MM-DD');
      const startDayIndex = availabilityKeys.indexOf(startDayKey);
      const result = {};
      if (startDayIndex === -1) {
        return {};
      }
      for (let d = startDayIndex; d < startDayIndex + this.DAYS_IN_WEEK; d++) {
        const key = availabilityKeys[d];
        if (key in availability) {
          result[key] = availability[key];
        }
      }
      return result;
    },
    availabilityFlattened() {
      const availabilityArr = Object.values(this.availability);
      const slotsArr = availabilityArr.map(a => (a ? Object.values(a) : []));
      const slots = [].concat(...slotsArr);
      return slots;
    },
    availabilityDates() {
      const dates = Object.keys(this.availability);
      return dates;
    },
    getMinHour() {
      const hours = this.availabilityFlattened.map(a =>
        moment(a.slot_start, 'HH:mm:ss').format('H')
      );
      return Math.min(...hours);
    },
    getMaxHour() {
      const hours = this.availabilityFlattened.map(a =>
        moment(a.slot_start, 'HH:mm:ss').format('H')
      );
      return Math.max(...hours);
    },
    getMorningHours() {
      const morningHours = [];
      const maxHour = Math.min(11, this.getMaxHour);
      for (let i = this.getMinHour; i <= maxHour; i++) {
        morningHours.push(i);
      }
      return morningHours;
    },
    getAfternoonHours() {
      const afternoonHours = [];
      const minHour = Math.max(12, this.getMinHour);
      const maxHour = Math.min(17, this.getMaxHour);
      for (let i = minHour; i <= maxHour; i++) {
        afternoonHours.push(i);
      }
      return afternoonHours;
    },
    getEveningHours() {
      const eveningHours = [];
      const minHour = Math.max(18, this.getMinHour);
      for (let i = minHour; i <= this.getMaxHour; i++) {
        eveningHours.push(i);
      }
      return eveningHours;
    },
    getClinicAvailabilityStartDate() {
      return this.getClinic ? moment(this.getClinic.availability_date) : '';
    },
    getClinicAvailabilityStartTime() {
      return this.getClinic ? moment(this.getClinic.availability_start, 'HH:mm:ss') : '';
    },
    getSlotsUrl() {
      const durations = this.getAppointment.appointmentProcedures.map(m => Number(m.duration));
      const durationSummed = durations.reduce(function(a, b) {
        return a + b;
      });

      return (
        config.standaloneWebBookingUrl +
        '/init/?type=portal_schedule' +
        '&hash=' +
        config.webBookingOrgHash +
        '&appId=' +
        this.getAppointment.id +
        '&clinicId=' +
        this.getClinic.clinic_id +
        '&procedureId=' +
        this.getAppointment.appointmentProcedures[0].procedure.id +
        '&appointmentDate=' +
        this.getAvailabilityStartDate.format('YYYY-MM-DD') +
        '&appointmentDate_Min=' +
        this.getAvailabilityStartDate.format('YYYY-MM-DD') +
        '&appointmentTime_Min=' +
        this.getAvailabilityStartTime.format('HH:mm:ss') +
        '&slotDuration=' +
        durationSummed +
        '&action=getSlots'
      );
    }
  },
  mounted: function() {
    this.setAvailabilityDates();
    this.getSlots();
  },
  watch: {
    getAvailabilitySlot: function(val) {
    }
  },
  methods: {
    availabilityInHours(hours) {
      const availability = this.availabilityFlattened.filter(s => {
        const slotHour = moment(s.slot_start, 'HH:mm:ss').format('H');
        if (hours.includes(Number(slotHour))) {
          return true;
        } else {
          return false;
        }
      });
      return availability.length;
    },
    onHeaderPrevDate() {
      this.availabilityStartDate = this.availabilityStartDate
        .clone()
        .subtract(this.DAYS_IN_WEEK, 'days');
    },
    onHeaderNextDate() {
      this.availabilityStartDate = this.availabilityStartDate
        .clone()
        .add(this.DAYS_IN_WEEK, 'days');

      if (this.ifAdditionalSlotsRequired()) {
        this.getNextSlots();
      }
    },
    getNextSlots() {
      this.getAvailabilityStartDate = this.getAvailabilityStartDate
        .clone()
        .add(1, 'weeks')
        .startOf('isoWeek');
      this.getAvailabilityStartTime = moment('00:00:00', 'HH:mm:ss');
      this.getSlots();
    },
    ifAdditionalSlotsRequired() {
      const begIndexKey = this.availabilityStartDate.format('YYYY-MM-DD');
      const begIndex = Object.keys(this.getAvailability).indexOf(begIndexKey);
      if (
        begIndex === -1 ||
        begIndex + this.DAYS_IN_WEEK > Object.keys(this.getAvailability).length
      ) {
        return true;
      } else {
        return false;
      }
    },
    onAfterContinueClicked() {
      this.$emit('continue')
    },
    setAvailabilityDates() {
      if (!this.getClinic) {
        return
      }
      this.getAvailabilityStartDate = this.getClinicAvailabilityStartDate;
      this.getAvailabilityStartTime = this.getClinicAvailabilityStartTime;
      if (this.getAvailabilitySlot) {
        if (
          this.getAvailabilitySlot.date.isAfter(
            this.getClinicAvailabilityStartDate,
            'day'
          )
        ) {
          this.getAvailabilityStartDate = this.getAvailabilitySlot.date;
          this.getAvailabilityStartTime = moment('00:00:00', 'HH:mm:ss');
        }
      }

      if (this.MOBILE && this.getAvailabilityStartDate.isoWeekday() < 7) {
        this.availabilityStartDate = this.getAvailabilityStartDate.clone();
      } else if (this.MOBILE) {
        this.availabilityStartDate = this.getAvailabilityStartDate
          .clone()
          .isoWeekday(6);
      } else {
        this.availabilityStartDate = this.getAvailabilityStartDate
          .clone()
          .startOf('isoWeek');
      }
    },
    getNextPageIfNoSlotsVisible(availability) {
      const daysWithSlotsVisible = Object.values(this.availability).flatMap(x=>x).length;
      const daysWithSlots = Object.values(availability).flatMap(x=>x).length;
      
      if (!daysWithSlotsVisible && daysWithSlots) {
        this.onHeaderNextDate();
        this.getNextPageIfNoSlotsVisible(availability);
      }
    },
    getSlots() {
      if (!this.getClinic) {
        this.slotsLoading = false;
        return
      }
      this.slotsLoading = true;
      fetch(this.getSlotsUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        body: ''
      })
        .then(response => response.json())
        .then(response => {
          this.slotsLoading = false;
          const mergedAvailability = Object.assign(
            {},
            this.getAvailability || {},
            response.response.availability
          );
          this.$store.commit('booking/setAvailability', mergedAvailability);
          this.$nextTick(() => {
            this.getNextPageIfNoSlotsVisible(response.response.availability);
          });
        })
        .catch(e => {});
    }
  }
});
</script>
